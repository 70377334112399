// Libraries
import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import ReactCodeInput from "react-verification-code-input";
import classNames from "classnames";

// Components
import Button from "components/Button/Button";
import {Checkbox, Input} from "components/Form";

import routes from "routes";

// Interfaces
import { RegisterFormValues, Error } from "interfaces/index";

// Enums
import { BtnTypes, ModalTypes } from "enums/index";

// Hooks
import {useAppSelector, useAppDispatch} from "hooks/hooks";

// Store
import {RequestStatuses, setErrors} from "store/reducers/authReducer";
import {authTemporaryUserAction, fetchUserAction} from "store/sagas/auth/actions";

// Helpers
import { requireField, checkValidEmail } from "helpers/Validator";

// Styles
import styles from "./RegistrationForm.scss";

interface LoginFormProps {
  readonly setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>;
  readonly setActiveModalWindow: (flag: boolean) => void;
}

interface Errors {
  email?: string;
  password?: string;
  code?: string;
}

const RegistrationForm = ({ setModalType, setActiveModalWindow }: LoginFormProps): JSX.Element => {
  const [isShowPsw, setShowPsw] = useState<boolean>(true);
  const [isShowConfirmPsw, setShowConfirmPsw] = useState<boolean>(true);
  const [isSuccessRegistration, setSuccessRegistration] = useState<boolean | null>(null);
  const dispatch = useAppDispatch();
  const requestRegisterStatus = useAppSelector(state => state.auth.requestRegisterStatus);
  const requestAuthTemporaryStatus = useAppSelector(state => state.auth.requestAuthTemporaryStatus);
  const authFormErrors = useAppSelector(state => state.auth.errors);
  const [second, setSecond] = useState(15);
  const [isCodeError, setIsCodeError] = useState<string | undefined>(undefined);
  const getParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (second > 0 && isSuccessRegistration) {
      setTimeout(() => setSecond(second - 1), 1000);
    }
  }, [second, isSuccessRegistration]);

  useEffect(() => {
    if (requestRegisterStatus === RequestStatuses.Success || requestAuthTemporaryStatus === RequestStatuses.Success) {
      setSuccessRegistration(true);
    }
  }, [requestRegisterStatus, requestAuthTemporaryStatus]);

  const onSubmit = (values: RegisterFormValues): void => {
    if (getParams.get("temp_entity_id")) {
      dispatch(authTemporaryUserAction({
        ...values,
        temp_entity_id: getParams.get("temp_entity_id")
      }));
      if (requestRegisterStatus === RequestStatuses.Success || requestAuthTemporaryStatus === RequestStatuses.Success) {
        setSuccessRegistration(true);
      }
    } else {
      dispatch(fetchUserAction(values));
    }

    setSecond(15);
  };

  const closeModalWindow = (): void => {
    setActiveModalWindow(false);
  };

  const validate = () => {
    const errors: Errors = {};
    const emailError = authFormErrors?.find((error: Error) => error.type === "email");
    const pswError = authFormErrors?.find((error: Error) => error.type === "password");
    const codeError = authFormErrors?.find((error: Error) => error.type === "code");

    if (emailError) {
      errors.email = emailError.text;
    }

    if (pswError) {
      errors.password = pswError.text;
    }

    if (codeError) {
      setIsCodeError(codeError.text);
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, form, values, errors }): JSX.Element => {
        return (
          isSuccessRegistration
            ?
            (
              <form onSubmit={handleSubmit}>
                <div className={styles.SuccessRegistration}>
                  <div className={styles.SuccessRegistrationContainer}>
                    <h4 className={styles.SuccessRegistrationContainerTitle}>Регистрация</h4>
                    {
                      isCodeError ? (
                        <span className={styles.SuccessRegistrationContainerErrorMsg}>{ isCodeError }</span>
                      ) : (
                        <p className={styles.SuccessRegistrationContainerSubMsg}>
                          Мы отправили Вам сообщение <br /> с кодом на почту <span>{values?.email}</span>
                        </p>
                      )
                    }
                  </div>
                  <div className={styles.SuccessRegistrationCodeContainer}>
                    <p>Код</p>
                    <div className={classNames(styles.SuccessRegistrationCodeContainerInputs, isCodeError && styles.SuccessRegistrationCodeContainerInputsErrors)}>
                      <Field name="code" validate={requireField}>
                        {
                          ({ input }: any) => (
                            <ReactCodeInput {...input} onChange={(e) => {
                              if (e) {
                                input.onChange(e);
                              }
                              setIsCodeError(undefined);
                            }} type="number" fields={5} />
                          )
                        }
                      </Field>
                    </div>
                    {
                      second > 0 ? (
                        <span className={styles.SuccessRegistrationCodeContainerCode}>Отправить код можно через {second} сек.</span>
                      ) : (
                        <Button
                          title="Отправить код повторно"
                          classes={{ root: styles.SuccessRegistrationCodeContainerBtn }}
                          type="button"
                          callback={() => onSubmit(values)}
                        />
                      )
                    }
                  </div>
                  <Button
                    title="Подтвердить"
                    classes={{ root: styles.SuccessRegistrationSubmit }}
                    type="submit"
                    disabled={second > 0 || values?.code?.length !== 5}
                  />
                  <i
                    className={styles.Close}
                    onClick={(): void => setActiveModalWindow(false)}
                  />
                </div>
              </form>
            )
            :
            (
              <form className={styles.RegistrationForm} onSubmit={handleSubmit}>
                <h4 className={styles.RegistrationFormTitle}>Войдите в аккаунт<br />
                  или зарегистрируйтесь</h4>
                <div className={styles.RegistrationFormContainer}>
                  <div className={styles.RegistrationFormContainerField}>
                    <label>Email</label>
                    <div className={styles.RegistrationFormInputContainer}>
                      <Input
                        placeholder="example@gmail.com"
                        type="text"
                        name="email"
                        validators={[requireField, checkValidEmail]}
                      />
                    </div>
                  </div>

                  {
                    !getParams.get("temp_entity_id") && (
                      <>
                        <div className={styles.RegistrationFormContainerField}>
                          <label>Пароль</label>
                          <div className={styles.RegistrationFormContainerFieldPsw}>
                            <div className={styles.RegistrationFormInputContainer}>
                              <Input
                                placeholder="Пароль"
                                type={isShowPsw ? BtnTypes.password : BtnTypes.text}
                                name="password"
                                validators={[requireField]}
                              />
                            </div>
                            <i data-show={isShowPsw} onClick={(): void => setShowPsw(!isShowPsw)} />
                          </div>
                        </div>

                        <div className={styles.RegistrationFormContainerField}>
                          <label>Повторите Пароль</label>
                          <div className={styles.RegistrationFormContainerFieldPsw}>
                            <Input
                              placeholder="Пароль повторно"
                              type={isShowConfirmPsw ? BtnTypes.password : BtnTypes.text}
                              name="password_confirmation"
                              validators={[requireField]}
                            />
                            <i data-show={isShowConfirmPsw} onClick={(): void => setShowConfirmPsw(!isShowConfirmPsw)} />
                          </div>
                        </div>
                      </>
                    )
                  }

                  <div className={styles.RegistrationFormContainerField}>
                    <Checkbox
                      classes={{ root: styles.RegistrationFormContainerFieldCheckBox }}
                      label="Согласие на обработку персональных данных"
                      name="data_agree"
                      initialValue={false}
                      validators={[requireField]}
                      hasLink
                      to={routes.pageDataAgreement}
                    />
                  </div>
                </div>

                <div className={styles.RegistrationFormActions}>
                  <Button
                    title="Зарегистрироваться"
                    classes={{ root: "" }}
                    type="submit"
                  />
                  <div
                    className={styles.RegistrationFormActionsHasAccount}
                    onClick={(): void => {
                      setModalType(ModalTypes.Login);
                      dispatch(setErrors([]));
                    }}
                  >
                    Уже есть аккаунт?
                  </div>
                  <div className={styles.RegistrationFormActionsSocials}>
                    <a href="https://api.goodatlas.ru/auth/google/redirect">
                      <i className={styles.RegistrationFormActionsSocialsGoogle} />
                    </a>
                    <a href="https://api.goodatlas.ru/auth/facebook/redirect">
                      <i className={styles.RegistrationFormActionsSocialsFb} />
                    </a>
                    <a href="https://api.goodatlas.ru/auth/vk/redirect">
                      <i className={styles.RegistrationFormActionsSocialsVk} />
                    </a>
                  </div>
                </div>
                <i
                  className={styles.Close}
                  onClick={closeModalWindow}
                />
              </form>
            )
        )
      }}
    />
  )
};

export default RegistrationForm;
