// Libraries
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Field, Form} from "react-final-form";
import {Link, useLocation, useParams, useHistory} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

// Routes
import routes from "routes";

// Interfaces
import {PassportElement, Project} from "interfaces/index";
import {reset as resetHistory} from "store/reducers/notificationsReducer";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Actions
import {
  createProjectAction,
  fetchProjectIconsAction,
  removePassportFromProjectAction
} from "store/sagas/projects/actions";
import {
  copyPassportsAction,
  fetchProjectWithPassportsAction,
  movePassportsAction,
  MovePassportsValue
} from "store/sagas/passports/actions";
import {fetchHistoryProjectAction} from "store/sagas/notifications/actions";

// Store
import {cancelRemovePassportFromProject, RequestStatuses, reset} from "store/reducers/passportsReducer";
import {reset as resetFiltration} from "store/reducers/filtrationReducer";

// Assets
import {ReactComponent as CreateIcon} from "assets/plus.svg";
import {ReactComponent as DocumentIcon} from "assets/document.svg";
import {ReactComponent as SuccessIcon} from "assets/success.svg";

// Enums
import {MyProjectsViewMode, ProjectRoles} from "enums/index";

// Components
import UsuallyModal from "components/ModalWindows/UsuallyModal/UsuallyModal";
import Button from "components/Button/Button";
import Card from "./components/Card/Card";
import ProjectListElement from "./components/ProjectListElement/ProjectListElement";
import SearchPassports, {SearchValues} from "./components/SearchPassports/SearchPassports";

// Styles
import styles from "./MyProjects.scss";
import AdditionalFiltration from "./components/AdditionalFiltration/AdditionalFiltration";

export enum Variant {
  MyProject,
  AvailableProject
}

interface MyProjectsProps {
  readonly variant: Variant;
}

const perPage = 12;
let searchTimer: NodeJS.Timeout;

const screenWidth = window.screen.width;

const projectNameMaxLength = (value: string): boolean => {
  return value?.length > 254;
}

const MyProjects = ({ variant }: MyProjectsProps): JSX.Element => {
  const [viewMode, setViewMode] = useState<MyProjectsViewMode>(MyProjectsViewMode.CommonMode);
  const [isProjectMenuOpen, setProjectMenuOpen] = useState(false);
  const location = useLocation();
  const projects: Project[] = useAppSelector(state =>
    variant === Variant.MyProject ?
      state.projects.projects.filter((project: Project) => project.project_role === ProjectRoles.Owner) :
      state.projects.projects.filter((project: Project) => project.project_role === ProjectRoles.Invited));
  const [isCreateProject, setIsCreateProject] = useState<boolean>(false);
  const icons = useAppSelector(state => state.projects.projectIcons);
  const icon = icons[Math.floor(Math.random() * icons.length)];
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const projectWithPassports: any | undefined = useAppSelector(state => state.passports.projectWithPassports.find((project: any) => project.projectId === id));
  const requestPassportsStatus = useAppSelector(state => state.passports.requestPassportsStatus);
  const requestMovePassports = useAppSelector(state => state.passports.requestMovePassports);
  const requestCopyPassports = useAppSelector(state => state.passports.requestCopyPassports);
  const requestRemovePassportFromProjectStatus = useAppSelector(state => state.projects.requestRemovePassportFromProjectStatus);
  /* фильтр по областям применения */
  const applicationArea = useAppSelector(state => state.filtration.filterApplicationArea);
  /* фильтры по срокам эксплуатации */
  const filterLifeTime = useAppSelector(state => state.filtration.filterLifeTime);
  /* фильт сложности */
  const complexityFilter = useAppSelector(state => state.filtration.filterComplexityValue);
  /* фильтр стоимости */
  const costFilter = useAppSelector(state => state.filtration.filterCostValue);
  /* page - страница пагинации */
  const [page, setPage] = useState<number>(1);
  const [isActiveMultiplyChoice, setIsActiveMultiplyChoice] = useState<boolean>(false);
  const [selectedPassport, setSelectedPassport] = useState<number[]>([]);
  const [isActiveChoiceMenu, setIsActiveChoiceMenu] = useState<boolean>(false);
  const [isActiveMovement, setIsActiveMovement] = useState<boolean>(false);
  const [isActiveCopy, setIsActiveCopy] = useState<boolean>(false);
  const [isActiveRemovePassports, setIsActiveRemovePassports] = useState<boolean>(false);
  const [isShowSuccessMoveModal, setIsShowSuccessMoveModal] = useState<boolean>(false);
  const [movingProject, setMovingProject] = useState<string>("");
  const [movingParams, setMovingParams] = useState<MovePassportsValue | undefined>(undefined);
  const [isRemovePassport, setIsRemovePassport] = useState(false);
  const history = useHistory();
  const requestFetchProjects = useAppSelector(state => state.projects.requestFetchProjects);

  const handlerProjectsTabs = () => {
    const defaultProject: Project | undefined = projects.find((project) => project.is_favorite === true);
    const foundProject: Project | undefined = projects.find((project: Project) => project.id == id);

    if (foundProject) {
      history.push(routes.project(foundProject.id));
    }

    if (defaultProject && !foundProject) {
      history.push(routes.project(defaultProject.id));
    }

    if (!defaultProject && projects.length > 0) {
      history.push(routes.project(projects[0].id));
    } else if (projects.length === 0 && requestFetchProjects === RequestStatuses.Success) {
      history.push(routes.projects);
    }
  };

  useEffect(handlerProjectsTabs, [requestFetchProjects]);

  useEffect(() => {
    return () => {
      dispatch(resetFiltration({}));
    };
  }, [dispatch]);


  const fetchPassports = useCallback(() => {
    dispatch(fetchProjectWithPassportsAction(
      {
        page,
        per_page: perPage,
        project: id,
        price: costFilter,
        application_area: applicationArea.length > 0 ? applicationArea.join(",") : undefined,
        complexity: complexityFilter,
        exploitation_years: { start_at: filterLifeTime?.lifeTimeStart === "" ? "0" : filterLifeTime.lifeTimeStart }
      }));
  }, [id, dispatch, costFilter, applicationArea, complexityFilter, filterLifeTime, page]);

  const fetchHistories = (): void => {
    dispatch(resetHistory({}));
    dispatch(fetchHistoryProjectAction({
      id,
      order_by: "created_at",
      order_type: "desc",
      page,
      per_page: perPage
    }));
  };

  useEffect(fetchHistories, [id]);

  useEffect(() => {
    setIsActiveMovement(false);
    setIsActiveMultiplyChoice(false);
  }, [id]);

  const morePassports = (): void => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (requestMovePassports === RequestStatuses.Success ||
      requestCopyPassports === RequestStatuses.Success ||
      requestRemovePassportFromProjectStatus === RequestStatuses.Success
    ) {
      setIsShowSuccessMoveModal(true);
      setIsActiveChoiceMenu(false);
      setIsActiveMultiplyChoice(false);
      (isActiveMovement || isActiveRemovePassports) && fetchPassports();
    }
  }, [requestMovePassports, fetchPassports, requestCopyPassports, isActiveMovement, requestRemovePassportFromProjectStatus, isActiveRemovePassports]);

  useEffect(() => {
    fetchPassports();
  }, [fetchPassports]);


  useEffect(() => {
    !icons.length && dispatch(fetchProjectIconsAction());
  }, [dispatch, icons]);

  const passportSearch = (values: SearchValues): void => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      const params: any = { page: 1, per_page: perPage, title: values.search_passport};
      if (id) {
        params.project = id;
      }
      dispatch(reset({}));
      dispatch(fetchProjectWithPassportsAction(params));
    }, 1000);
  };

  const createProject = (values: any): void => {
    const params = {
      title: values.project_title,
      icon_id: icon.id
    }
    dispatch(createProjectAction(params));
    setIsCreateProject(false);
  };

  useEffect(() => {
    setViewMode(MyProjectsViewMode.CommonMode);
  }, [location.pathname]);

  const copyPassports = useCallback((projectId: number): void => {
    setIsActiveCopy(true);
    setIsActiveMovement(false);
    setIsActiveRemovePassports(false);

    const params = {
      old_project_id: Number(id),
      new_project_id : projectId,
      passport_id: selectedPassport
    };

    dispatch(copyPassportsAction(params));

    setMovingParams(params);

    const foundProject = projects.find((project): boolean => project.id === projectId);

    foundProject && setMovingProject(foundProject.title);
  }, [id, selectedPassport, dispatch, projects]);

  const cancelCopyPassports = (): void => {
    if (movingParams) {
      const params = {
        project_id: movingParams.new_project_id,
        passport_id: selectedPassport
      }

      setIsActiveCopy(false);
      setIsRemovePassport(false);
      dispatch(removePassportFromProjectAction(params));

      setMovingProject("");
    }
  };

  const removePassport = useCallback((passportId: number[]): void => {
    setIsActiveCopy(false);
    setIsActiveMovement(false);
    setIsActiveRemovePassports(true);
    setIsRemovePassport(true);

    setSelectedPassport(passportId);

    dispatch(removePassportFromProjectAction({
      project_id: id,
      passport_id: passportId
    }));
    const foundProject = projects.find((project): boolean => project.id == id);

    foundProject && setMovingProject(foundProject.title);
  }, [dispatch, id, projects]);

  const movePassports = useCallback((newId: number): void => {
    setIsActiveMovement(true);
    setIsActiveRemovePassports(false);
    setIsActiveCopy(false);

    const params = {
      old_project_id: Number(id),
      new_project_id : newId,
      passport_id: selectedPassport
    };

    dispatch(movePassportsAction(params));

    setMovingParams(params);

    const foundProject = projects.find((project): boolean => project.id === newId);

    foundProject && setMovingProject(foundProject.title);
  }, [dispatch, id, projects, selectedPassport, setMovingParams, setMovingProject]);

  const renderCards = useMemo((): JSX.Element | JSX.Element[] | undefined => {
    if (projects.length === 0) {
      return (
        <div className={styles.PassportsContentEmpty}>
          <div className={styles.PassportsContentEmptyIcon}>
            <DocumentIcon />
          </div>
          <h4>
            { variant === Variant.MyProject && "У Вас еще нет проектов!" }
            { variant === Variant.AvailableProject && "У Вас еще нет проектов, куда Вас пригласили!" }
          </h4>
        </div>
      )
    }

    return (
      projectWithPassports?.passports?.map((passport: PassportElement, i: number): JSX.Element => (
        <Card
          selectedPassport={selectedPassport}
          setSelectedPassport={setSelectedPassport}
          isActiveMultiplyChoice={isActiveMultiplyChoice}
          variant={variant}
          key={`card-pc-${passport.id}`}
          item={passport}
          removePassport={removePassport}
          index={i}
          copyPassports={copyPassports}
          movePassports={movePassports}
        />
      ))
    )
  }, [projectWithPassports, variant, isActiveMultiplyChoice, selectedPassport, removePassport, projects, copyPassports, movePassports]);

  const renderProjectListElements = useMemo((): JSX.Element | JSX.Element[] => {
    return projects?.map((project): JSX.Element => (
      <ProjectListElement
        key={`pr-list-element-${project.id}`}
        variant={variant}
        element={project}
        setViewMode={setViewMode}
        viewMode={viewMode}
      />
    ));
  }, [setViewMode, variant, projects, viewMode]);

  const renderActionMessage = (): string => {
    if (isActiveMovement) {
      return "Паспорт(а) перемещен(ы) в проект"
    }

    if (isActiveCopy) {
      return "Паспорт(а) копирован(ы) в проект"
    }

    if (isActiveRemovePassports) {
      return `Паспорт(а) ${isRemovePassport ? "удален(ы) из проекта" : "восстановлен(ы) в проекте"}`;
    }

    return "Действие отменено!";
  };

  const onDragEnd = (result: any) => {
    const draggableId = JSON.parse(result.draggableId);
    const droppableId = JSON.parse(result.destination.droppableId);

    setIsActiveCopy(false);
    setIsActiveMovement(true);
    setIsActiveRemovePassports(false);
    setIsRemovePassport(false);
    setSelectedPassport([draggableId.passportId]);

    if (droppableId?.projectId && draggableId.passportId) {
      const params = {
        old_project_id: Number(id),
        new_project_id : droppableId?.projectId,
        passport_id: [draggableId.passportId]
      };

      dispatch(movePassportsAction(params));

      setMovingParams(params);

      const foundProject = projects.find((project): boolean => project.id === droppableId?.projectId);

      foundProject && setMovingProject(foundProject.title);
    }
  };

  const cancelMovePassports = (): void => {
    if (movingParams) {
      const params = {
        old_project_id: movingParams.new_project_id,
        new_project_id: movingParams.old_project_id,
        passport_id: movingParams.passport_id
      };

      dispatch(movePassportsAction({
        old_project_id: movingParams.new_project_id,
        new_project_id: movingParams.old_project_id,
        passport_id: movingParams.passport_id
      }));

      setMovingParams(params);

      const foundProject = projects.find((project): boolean => project.id === movingParams.old_project_id);

      foundProject && setMovingProject(foundProject.title);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} enableDefaultSensors={screenWidth > 1024}>
      <div className={styles.Root}>
        <div className={styles.ProjectColumn} data-open={isProjectMenuOpen}>
          <div
            className={styles.ProjectMenuTrigger}
            data-active={isProjectMenuOpen}
            onClick={() => setProjectMenuOpen(!isProjectMenuOpen)}
          />
          <div className={styles.CreateAction}>
            <Button
              title="Создать"
              classes={{ root: styles.Create }}
              type="button"
              startIcon={<CreateIcon />}
              callback={(): void => setIsCreateProject(true)}
            />
          </div>
          <div
            className={styles.ProjectsList}
          >
            {
              isCreateProject && (
                <Form
                  onSubmit={createProject}
                  render={({ handleSubmit, invalid }): JSX.Element => (
                    <form onBlur={handleSubmit}>
                      <div className={styles.CreateProjectInput}>
                        <img className={styles.CreateProjectInputImg} src={icon.url} alt=""/>
                        { invalid && <span className={styles.ProjectNameErrorMsg}>Имя не должно превышать 254 символа</span> }
                        <Field
                          className={invalid ? styles.ProjectNameError : ""}
                          name="project_title"
                          component="textarea"
                          type="text"
                          validate={projectNameMaxLength}
                        />
                      </div>
                    </form>
                  )}
                />
              )
            }
            {renderProjectListElements}
          </div>
        </div>
        <div className={styles.PassportsColumn}>
          <div className={styles.Filtration}>
            <div className={styles.SearchFiltration}>
              {
                viewMode !== MyProjectsViewMode.HistoryMode && (
                  <SearchPassports passportSearch={passportSearch} />
                )
              }
            </div>
            {
              viewMode !== MyProjectsViewMode.HistoryMode && (
                <AdditionalFiltration
                  isActiveMultiplyChoice={isActiveMultiplyChoice}
                  setIsActiveChoiceMenu={setIsActiveChoiceMenu}
                  setIsActiveCopy={setIsActiveCopy}
                  setIsActiveMovement={setIsActiveMovement}
                  isActiveChoiceMenu={isActiveChoiceMenu}
                  isActiveMovement={isActiveMovement}
                  isActiveCopy={isActiveCopy}
                  selectedPassport={selectedPassport}
                  setIsActiveRemovePassports={setIsActiveRemovePassports}
                  setIsActiveMultiplyChoice={setIsActiveMultiplyChoice}
                  setSelectedPassport={setSelectedPassport}
                  copyPassports={copyPassports}
                  setPage={setPage}
                  removePassport={removePassport}
                  movePassports={movePassports}
                />
              )
            }
          </div>
          <Droppable droppableId={JSON.stringify({ droppableId: "passports" })}>
            {
              (provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  id="passport-block"
                  className={styles.PassportsContent}
                >
                  <InfiniteScroll
                    next={morePassports}
                    dataLength={projectWithPassports?.passports?.length || 0}
                    loader=""
                    hasMore
                    scrollableTarget="passport-block"
                  >
                    <>
                      { viewMode === MyProjectsViewMode.CommonMode && renderCards }
                    </>
                    {
                      projects.length !== 0 && projectWithPassports?.passports?.length === 0 && viewMode !== MyProjectsViewMode.HistoryMode && requestPassportsStatus !== RequestStatuses.Pending && (
                        <div className={styles.PassportsContentEmpty}>
                          <div className={styles.PassportsContentEmptyIcon}>
                            <DocumentIcon />
                          </div>
                          <h4>У Вас еще нет никаких паспортов в проекте!</h4>
                          <p>Вы можете добавить паспорта из нашего справочника</p>
                          <Link to={routes.handbook} className={styles.PassportsContentEmptyBtn}>Справочник</Link>
                        </div>
                      )
                    }
                  </InfiniteScroll>
                  { provided.placeholder }
                </div>
              ))
            }
          </Droppable>
        </div>
        <UsuallyModal classes={{ root: styles.SuccessMoveModalRoot }} autoPosition={false} isShowModal={isShowSuccessMoveModal} setShowModal={setIsShowSuccessMoveModal}>
          <div className={styles.SuccessMoveModal}>
            <SuccessIcon />
            <p>
              {renderActionMessage()}
              {
                movingParams && movingProject && (
                  <Link to={routes.project(movingParams.new_project_id)}>
                    “{movingProject}”
                  </Link>
                )
              }
            </p>
            {
              (isRemovePassport || isActiveMovement || isActiveCopy) && (
                <Button
                  title="Отменить"
                  classes={{ root: styles.SuccessMoveModalCancelBtn }}
                  type="submit"
                  callback={(): void => {
                    if (isActiveMovement) {
                      cancelMovePassports();
                      dispatch(cancelRemovePassportFromProject({}))
                    }

                    if (isActiveCopy) {
                      cancelCopyPassports();
                    }

                    if (isActiveRemovePassports) {
                      copyPassports(id);
                      setIsRemovePassport(false);
                      dispatch(cancelRemovePassportFromProject({}))
                    }
                  }}
                />
              )
            }
          </div>
        </UsuallyModal>
      </div>
    </DragDropContext>
  )
};

export default MyProjects;
