// Libraries
import React, {useCallback, useMemo, useState} from "react";
import classNames from "classnames";

// Components
import FilterElement from "../FilterElement/FilterElement";

// Styles
import styles from "./CostStyle.scss";

interface CostProps {
  readonly classes?: {
    readonly root?: string;
    readonly title?: string;
  }
  readonly hasTitle?: boolean;
  readonly cost?: CostEnum;
  readonly isActiveFilter?: boolean;
  readonly setCostFilter?: (cost: CostEnum) => void;
}

export enum CostEnum {
  Chip = 1,
  Middle = 2,
  Expensive = 3
}

const Cost = (
  {
    classes = {},
    hasTitle = true,
    cost,
    isActiveFilter = false,
    setCostFilter
  }: CostProps): JSX.Element => {
  const [elements] = useState<CostEnum[]>([CostEnum.Chip, CostEnum.Middle, CostEnum.Expensive]);

  const getTitle = useCallback((index: number): string => {
    switch (index) {
      case 0:
        return "Бюджетно";
      case 1:
        return "Средне";
      case 2:
        return "Дорого";
      default:
        return "Бюджетно";
    }
  }, []);

  const renderFilterElements = useMemo((): JSX.Element[] => {
    return elements.map((element, i): JSX.Element => (
      <FilterElement
        key={`filter-cost-element-${element}`}
        classes={{
          default: styles.FilterElementCostIcon,
          active: cost && i < cost ? styles.FilterElementCostIconActive : "",
          activeIcon: ""
        }}
        title={getTitle(i)}
        isElementActive={cost && i <= cost}
        isActiveFilter={isActiveFilter}
        setValueFilter={setCostFilter}
        value={i + 1}
      />
    ))
  }, [elements, isActiveFilter, setCostFilter, cost, getTitle]);

  return (
    <div className={classNames(styles.Cost, classes?.root)}>
      { hasTitle && <span className={classes?.title}>Стоимость</span> }
      <div className={styles.FilterElementCostContent}>
        {
          renderFilterElements
        }
      </div>
    </div>
  )
}

export default Cost;
