// Libraries
import React, {useEffect, useMemo, useState} from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import InfiniteScroll from "react-infinite-scroll-component";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Actions
import {fetchInvitedActions} from "store/sagas/invited/actions";
import {reset} from "store/reducers/invitedReducer";

// Interfaces
import {Invited} from "interfaces/index";

// Enums
import {RequestStatuses} from "enums/index";

// Assets
import { ReactComponent as InvitationsIcon } from "assets/invations.svg";

// Components
import InvitationModal from "components/ModalWindows/InvitationModal/InvitationModal";
import Button from "components/Button/Button";
import PrivateCabinet from "../../PrivateCabinet";
import InvitationElement from "./components/InvitationElement";
import { Pages } from "./components/FormSelectElement";

// Styles
import styles from "./Invitations.scss";

export enum VariantModal {
  FormVariant,
  SuccessVariant
}

const perPage = 20;

const Invitations = (): JSX.Element => {
  const [page, setPage] = useState<Pages>(Pages.MyProjects);
  const dispatch = useAppDispatch();
  const invited: Invited[] = useAppSelector(state => state.invited.invitedList);
  const requestFetchInvitedStatus = useAppSelector(state => state.invited.requestFetchInvitedStatus);
  const [pageNumber, setPageNumber] = useState(1);
  const invitedListShowElements = useAppSelector(state => state.invited.invitedListShowElements);

  const fetchInvited = (): void => {
    dispatch(fetchInvitedActions({
      with_role: page === Pages.MyProjects ? "owner" : "invited",
      page: pageNumber,
      per_page: perPage
    }));
  };

  useEffect(fetchInvited, [page]);


  const renderInvited = useMemo(() => {
    return invited.map((item): JSX.Element => (
      <InvitationElement
        page={page}
        key={uuidv4()}
        item={item}
        isShowListElement={invitedListShowElements.find((element: any) => element.id === item.id).isShow}
      />
    ))
  }, [invited, page, invitedListShowElements]);

  const loadMore = (): void => {
    dispatch(fetchInvitedActions({
      with_role: page === Pages.MyProjects ? "owner" : "invited",
      page: pageNumber + 1,
      per_page: perPage
    }));
    setPageNumber(pageNumber + 1);
  };

  return (
    <PrivateCabinet>
      <>
        <div className={styles.Tabs}>
          <Button
            title="Мои проекты"
            classes={{ root: styles.Tab, active: page === Pages.MyProjects ? styles.ActiveTab : "" }}
            type="button"
            callback={(): void => {
              setPage(Pages.MyProjects);
              setPageNumber(1);
              dispatch(reset({}));
            }}
          />
          <Button
            title="Доступные мне"
            classes={{ root: styles.Tab, active: page === Pages.AvailableProjects ? styles.ActiveTab : "" }}
            type="button"
            callback={(): void => {
              setPage(Pages.AvailableProjects);
              setPageNumber(1);
              dispatch(reset({}));
            }}
          />
        </div>

        {
          invited?.length === 0 && requestFetchInvitedStatus === RequestStatuses.Initial ?
            (
              <div className={styles.EmptyContent}>
                <InvitationsIcon />
                <p>Вы еще никого не приглашали в Ваши проекты! </p>
                <Link to="/private-cabinet/projects">Мои проекты</Link>
              </div>
            )
            :
            (
              <section id="notification-block" className={styles.Root}>
                <InfiniteScroll
                  next={loadMore}
                  dataLength={invited.length}
                  loader=""
                  hasMore
                  scrollableTarget="notification-block"
                >
                  { renderInvited }
                </InfiniteScroll>
              </section>
            )
        }
        <InvitationModal />
      </>
    </PrivateCabinet>
  )
}

export default Invitations;
