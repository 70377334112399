// Libraries
import React from "react";
import {useParams} from "react-router-dom";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Interfaces
import {PassportCollectionElement, PassportElement} from "interfaces/index";

// Components
import TemplateOneComponent from "./components/TemplateOne";
import TemplateTwoComponent from "./components/TemplateTwo";

// Styles
import styles from "./KindMaterial.scss";

enum Templates {
  TemplateOne = "template_1",
  TemplateTwo = "template_2"
}

const KindMaterial = (): JSX.Element => {
  const { id } = useParams();
  const passport: PassportCollectionElement | undefined = useAppSelector(state =>
    state.passports.passportsCollection.find((passportElement: PassportElement): boolean =>
      passportElement.id == id));

  return (
    <div className={styles.Root}>
      { passport?.template.slug === Templates.TemplateOne && <TemplateOneComponent materials={passport.materials} /> }
      { passport?.template.slug === Templates.TemplateTwo && <TemplateTwoComponent materials={passport.materials} /> }
    </div>
  )
}

export default KindMaterial;
