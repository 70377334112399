// Libraries
import React from "react";
import {Field} from "react-final-form";
import classNames from "classnames";

// Actions
import {setErrors} from "store/reducers/authReducer";

// Hooks
import {useAppDispatch} from "hooks/hooks";

// Styles
import styles from "./Input.scss";

interface InputProps {
  readonly classes?: {
    readonly root?: string;
    readonly error?: string;
  };
  readonly placeholder: string;
  readonly type: string;
  readonly name: string;
  readonly disabled?: boolean;
  readonly validators?: any[];
  readonly accept?: string;
  readonly id?: string;
  readonly initialValue?: number | string;
}

// @ts-ignore
const composeValidators = (...validators: (() => string | undefined)[]) => value =>
  // @ts-ignore
  validators.reduce((error, validator) => error || validator(value), undefined)

const Input = ({ classes = {}, placeholder, type, name, disabled, validators = [], accept, id, initialValue }: InputProps): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <Field name={name} validate={composeValidators(...validators)} initialValue={initialValue}>
      {
        ({ input, meta }) => (
          <div className={classNames(styles.Container, classes?.root)}>
            <input
              className={classNames(
                [{ [styles.EmptyInput] : placeholder === "" && input.value === "" }],
                [{ [styles.ErrorInput] : meta.error && (meta.touched || meta.submitSucceeded) }]
              )}
              {...input}
              id={id}
              accept={accept || undefined}
              placeholder={placeholder}
              type={type}
              disabled={disabled}
              onChange={(e): void => input.onChange(e)}
              onBlur={(e) => input.onBlur(e)}
              onClick={(): void => meta.error && dispatch(setErrors([]))}
              autoComplete="off"
            />
            {meta.error && (meta.touched || meta.submitSucceeded) && <span className={classNames(styles.Error, classes?.error)}>{meta.error}</span>}
          </div>
        )
      }
    </Field>
  )
}

export default Input;
