// Libraries
import React, {useEffect, useRef, useState } from "react";
import {NavLink, useHistory} from "react-router-dom";
import { Droppable } from "react-beautiful-dnd";
import {AxiosResponse} from "axios";
import { downloadProject as downloadProjectApi } from "Api";

// Interfaces
import {Project} from "interfaces/index";

// Routes
import routes from "routes";

// Hooks
import {useAppDispatch} from "hooks/hooks";

// Actions
import {changeProjectTitleAction, removeProjectAction} from "store/sagas/projects/actions";
import {setActiveInvitation, setCoordinates, setProjectId} from "store/reducers/modalsReducer";

// Assets
import {ReactComponent as FavoriteIcon} from "assets/padlock.svg";
import {ReactComponent as MarkCheck} from "assets/check_mark_2.svg";

// Components
import {MyProjectsViewMode} from "enums/index";
import Loader from "components/Loader/Loader";
import ContextMenu from "../ContextMenu/ContextMenu";

// Styles
import styles from "../../MyProjects.scss";

interface ProjectListElementProps {
  readonly variant: Variant;
  readonly element: Project;
  readonly setViewMode: (viewMode: MyProjectsViewMode) => void;
  readonly viewMode: MyProjectsViewMode;
}

enum Variant {
  MyProject,
  AvailableProject
}

export enum ElementType {
  Favorite,
  Common
}

const ProjectListElement = ({ element, variant, setViewMode, viewMode }: ProjectListElementProps): JSX.Element => {
  const [isOpenContextMenu, setOpenContextMenu] = useState(false);
  const refElement = useRef<HTMLDivElement>(null);
  const refElementBtn = useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isRenameProject, setIsRenameProject] = useState(false);
  const newProjectTitleRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.addEventListener("click", closeModal, false);

    return () => document.removeEventListener("click", closeModal, false);
  }, []);

  useEffect(() => {
    if (!isOpenContextMenu) {
      setIsRenameProject(false);
    }
  }, [isOpenContextMenu]);

  const closeModal = (e: MouseEvent): void => {
    e.preventDefault();
    // @ts-ignore
    if ((refElement.current && !refElement.current?.contains(e.target) && e.target !== refElementBtn.current) && !e.target.getAttribute("data-context-menu")) {
      setOpenContextMenu(false);
    }
  };

  const removeProject = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    dispatch(removeProjectAction(element.id));
  };

  const downloadProject = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setIsLoading(true);
    downloadProjectApi(element.id).then((res: AxiosResponse) => {
      const link = document.createElement("a");
      link.href = res.data.url;
      link.setAttribute("download", `${element.title}.pdf`);
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener noreferrer");
      link.click();
      setIsLoading(false);
    });
  };

  const changeProjectTitle = (e: React.MouseEvent): void => {
    e.preventDefault();
    if (newProjectTitleRef?.current?.value) {
      dispatch(changeProjectTitleAction({
        title: newProjectTitleRef.current.value,
        projectId: element.id
      }));
      setOpenContextMenu(false);
    }
  };

  const goToHistory = (e: React.MouseEvent): void => {
    e.preventDefault();
    history.push(routes.history(element.id));
  }

  return (
    <Droppable
      droppableId={JSON.stringify({
      projectId: element.id,
      type: "project"
    })}
      direction="horizontal"
    >
      {
        ((provided, snapshot) => (
          <>
            <NavLink
              {...provided.droppableProps}
              style={{ height: snapshot.isDraggingOver ? "100px" : "38px", backgroundColor: snapshot.isDraggingOver ? "#F4F4F4" : "#fff" }}
              ref={provided.innerRef}
              to={routes.project(element.id)}
              className={styles.ProjectListFavoriteElement}
              activeClassName={styles.ActiveProjectListElement}
              onClick={(): void => {
                if (viewMode === MyProjectsViewMode.HistoryMode) {
                  setViewMode(MyProjectsViewMode.CommonMode);
                }
              }}
            >
              { element.is_favorite ? <FavoriteIcon /> : <img className={styles.CreateProjectImg} width={24} height={24} src={element.icon_url} alt=""/> }

              <h4 title={element.title} data-title={element.title}>{ element.title }</h4>

              <i data-favorite={element.is_favorite} ref={refElementBtn} onClick={(e): void => {
                e.preventDefault();
                dispatch(setCoordinates({
                  x: window.innerWidth > 1121 ? window.screen.width - 1360 - 110 : window.innerWidth > 480 && 20,
                  y: e.pageY - 125
                }));
                setOpenContextMenu(!isOpenContextMenu);
              }} />
              { provided.placeholder }
            </NavLink>
            { isOpenContextMenu && variant === Variant.MyProject && (
              <ContextMenu ref={refElement} classes={{ root: styles.ContextMenu }}>
                <ul>
                  {
                    isRenameProject ? (
                        <>
                          <input onClick={(e): void => e.preventDefault()} ref={newProjectTitleRef} type="text" className={styles.ContextMenuChangeProjectName} />
                          <MarkCheck className={styles.ContextMenuChangeProjectNameIcon} onClick={changeProjectTitle} />
                        </>
                      )
                      : (
                        <li data-context-menu="true" onClick={(e): void => {
                          e.preventDefault();
                          setIsRenameProject(true);
                        }}>Переименовать</li>
                      )
                  }

                  {/*<li>Копировать</li>*/}
                  <li onClick={downloadProject} data-context-menu="true">
                    Скачать
                    {
                      isLoading && <Loader />
                    }
                  </li>
                  {
                    !element.is_favorite && (
                      <>
                        <li data-context-menu="true" onClick={(): void => {
                          dispatch(setProjectId(element.id));
                          dispatch(setActiveInvitation(true));
                        }}>Пригласить</li>
                        <li
                          data-context-menu="true"
                          onClick={goToHistory}
                        >
                          История
                        </li>
                        <li data-context-menu="true" className={styles.ContextMenuDeleteElement} onClick={removeProject}>Удалить</li>
                      </>
                    )
                  }
                </ul>
              </ContextMenu>
            ) }
            { isOpenContextMenu && variant === Variant.AvailableProject && (
              <ContextMenu ref={refElement} classes={{ root: styles.ContextMenu }}>
                <ul>
                  {
                    element?.owner_user && (
                      <li data-context-menu="true" className={styles.ContextMenuAuthorElement}>
                        { element?.owner_user?.email }<br />
                        <span>{ element?.owner_user?.role?.description }</span>
                      </li>
                    )
                  }
                  <li
                    data-context-menu="true"
                    onClick={downloadProject}>
                    Скачать / Печать
                  </li>
                  <li
                    data-context-menu="true"
                    onClick={goToHistory}
                  >
                    История
                  </li>
                </ul>
              </ContextMenu>
            ) }
          </>
        ))
      }
    </Droppable>
  )
};

export default ProjectListElement;
