import {createSlice, SliceCaseReducers} from "@reduxjs/toolkit";
import {Invited, User} from "interfaces/index";
import {RequestStatuses} from "enums/index";

interface State {
  invitedList: Invited[],
  requestFetchInvitedStatus: RequestStatuses,
  requestInviteUserToProjectStatus: RequestStatuses,
  requestRemoveUserFromProjectStatus: RequestStatuses,
  requestChangeUserRoleOnProjectStatus: RequestStatuses,
  invitedTotal: number,
  invitedListShowElements: {
    id: number,
    isShow: boolean;
  }[]
}

const initialState = {
  invitedList: [],
  requestFetchInvitedStatus: RequestStatuses.Initial,
  requestInviteUserToProjectStatus: RequestStatuses.Initial,
  requestRemoveUserFromProjectStatus: RequestStatuses.Initial,
  requestChangeUserRoleOnProjectStatus: RequestStatuses.Initial,
  invitedTotal: 0,
  invitedListShowElements: []
}

const invited = createSlice<State, SliceCaseReducers<State>>({
  name: "invited",
  initialState,
  reducers: {
    putInvited(state, action): void {
      action.payload.forEach((item: Invited): void => {
        if (!state.invitedList.find((element: Invited) => element.id === item.id)) {
          state.invitedList.push(item);
          state.invitedListShowElements.push({ id: item.id, isShow: false });
        }
      });
    },
    setRequestFetchInvitedStatus(state, action): void {
      state.requestFetchInvitedStatus = action.payload;
    },
    setRequestInviteUserToProjectStatus(state, action): void {
      state.requestInviteUserToProjectStatus = action.payload
    },
    setRequestRemoveUserFromProjectStatus(state, action): void {
      state.requestRemoveUserFromProjectStatus = action.payload;
    },
    removeUsersFromProject(state, action): void {
      const invitedElement = state.invitedList.find((element: Invited) => element.id === action.payload.id);

      if (invitedElement) {
        action.payload.ids.forEach((id: number) => {
          const index = invitedElement.users.findIndex((user: User) => user.id === id);
          if (index >= 0) {
            invitedElement.users = invitedElement.users.slice(0, index).concat(invitedElement.users.slice(index + 1));
          }
        });
      }
    },
    setRequestChangeUserRoleOnProjectStatus(state, action): void {
      state.requestChangeUserRoleOnProjectStatus = action.payload;
    },
    changeUserRoleOnProject(state, action): void {
      const invitedElement = state.invitedList.find((element: Invited) => element.id === action.payload.id);

      if (invitedElement) {
        const user = invitedElement.users.find((item: User) => item.id === action.payload.user_id);

        if (user) {
          user.project_role.id = action.payload.roles.id;
          user.project_role.title = action.payload.roles.label;
          user.project_role.slug = action.payload.roles.value;
        }
      }
    },
    setInvitedTotal(state, action): void {
      state.invitedTotal = action.payload;
    },
    reset: (): State => initialState,
    changeShowInvitedListElement(state, action): void {
      const invitedElement = state.invitedListShowElements.find((element: any) => element.id === action.payload.id);

      if (invitedElement) {
        invitedElement.isShow = !invitedElement.isShow;
      }
    },
    putUser(state, action): void {
      const invitedElement = state.invitedList.find((element: Invited) => element.id === action.payload.id);

      if (invitedElement) {
        invitedElement.users.push(action.payload.user);
      }
    }
  }
});

export default invited.reducer;
export const {
  putInvited,
  setRequestFetchInvitedStatus,
  setRequestInviteUserToProjectStatus,
  setRequestRemoveUserFromProjectStatus,
  removeUsersFromProject,
  setRequestChangeUserRoleOnProjectStatus,
  changeUserRoleOnProject,
  setInvitedTotal,
  reset,
  changeShowInvitedListElement,
  putUser
} = invited.actions;
