import React, {useEffect, useState} from "react";
import {Form} from "react-final-form";
import {Input} from "components/Form";
import {requireField} from "helpers/Validator";
import {BtnTypes, ModalTypes, RequestStatuses} from "enums/index";
import Button from "components/Button/Button";
import {useAppDispatch, useAppSelector} from "hooks/hooks";
import {resetForgotPasswordAction} from "store/sagas/auth/actions";
import {ReactComponent as SuccessIcon} from "assets/success.svg";
import styles from "./RecoveryPsw.scss";

interface Values {
  new_password?: string;
  confirm_password?: string;
}

interface RecoveryPswProps {
  readonly setActiveModalWindow: (value: boolean) => void;
  readonly setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>;
}

const RecoveryPsw = ({ setActiveModalWindow, setModalType }: RecoveryPswProps): JSX.Element => {
  const [isShowNewPsw, setShowNewPsw] = useState<boolean>(false);
  const [isShowConfirmPsw, setShowConfirmPsw] = useState<boolean>(false);
  const email = useAppSelector(state => state.auth.email);
  const code = useAppSelector(state => state.auth.code);
  const dispatch = useAppDispatch();
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const requestResetForgotPasswordStatus = useAppSelector(state => state.auth.requestResetForgotPasswordStatus);
  const authFormErrors = useAppSelector(state => state.auth.errors);

  useEffect(() => {
    const codeError = authFormErrors.find((error: any) => error.type === "code");
    
    if (codeError?.text === "Неверный код!") {
      setModalType(ModalTypes.EnterCode);
    }
  }, [authFormErrors, setModalType]);

  useEffect(() => {
    if (requestResetForgotPasswordStatus === RequestStatuses.Success) {
      setIsSuccess(true);
    }
  }, [requestResetForgotPasswordStatus]);

  const closeModalWindow = (): void => {
    setActiveModalWindow(false);
  };

  const onSubmit = (values: Values) => {
    dispatch(resetForgotPasswordAction({
      email,
      code,
      password: values.new_password,
      password_confirmation: values.confirm_password
    }));
  };

  const validate = (values: Values) => {
    const errors: Values = {};
    if (values.confirm_password !== values.new_password) {
      errors.confirm_password = "Пароли должны совпадать";
    }

    if (values.new_password && values?.new_password?.length < 8) {
      errors.new_password = "Мин.длина 8 символов"
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, form }): JSX.Element => (
        <form className={styles.Root} onSubmit={handleSubmit}>
          { isSuccess ? (
            <div className={styles.RootSuccessContainer}>
              <h2 className={styles.RootSuccessContainerTitle}>Новый пароль</h2>
              <SuccessIcon />
              <p className={styles.RootSuccessContainerDescription}>
                Ваш пароль успешно изменен!
              </p>
              <Button
                title="Войти с новым паролем"
                classes={{ root: styles.RootSuccessContainerEnterWithNewPsw }}
                type="button"
                callback={() => setModalType(ModalTypes.Login)}
              />
              <i
                className={styles.Close}
                onClick={closeModalWindow}
              />
            </div>
          ) : (
            <>
              <h4 className={styles.RootTitle}>Новый пароль</h4>
              <div className={styles.RootContainer}>
                <div className={styles.RootContainerField}>
                  <label>Новый пароль</label>
                  <div className={styles.RootContainerFieldPsw}>
                    <Input
                      placeholder="Новый пароль"
                      type={!isShowNewPsw ? BtnTypes.password : BtnTypes.text}
                      name="new_password"
                      validators={[requireField]}
                    />
                    <i data-show={isShowNewPsw} onClick={(): void => setShowNewPsw(!isShowNewPsw)} />
                  </div>
                </div>
                <div className={styles.RootContainerField}>
                  <label>Новый пароль повторно</label>
                  <div className={styles.RootContainerFieldPsw}>
                    <Input
                      placeholder="Новый пароль повторно"
                      type={!isShowConfirmPsw ? BtnTypes.password : BtnTypes.text}
                      name="confirm_password"
                      validators={[requireField]}
                    />
                    <i data-show={isShowConfirmPsw} onClick={(): void => setShowConfirmPsw(!isShowConfirmPsw)} />
                  </div>
                </div>
              </div>
              <Button
                title="Подтвердить"
                classes={{ root: styles.RootContainerSubmit }}
                type="submit"
              />
            </>
          ) }
          <i
            className={styles.Close}
            onClick={closeModalWindow}
          />
        </form>
      )}
    />
  )
};

export default RecoveryPsw;
