// Libraries
import React from "react";
import SelectComponent from "react-select";

// Styles
import "./Select.scss";

interface SelectProps {
  readonly classes?: {
    readonly root?: string;
  }
  readonly input?: any;
  readonly meta?: any;
  readonly label: string;
  readonly options: {
    readonly id: number | string;
    readonly label: string;
    readonly value: string;
  }[];
  readonly placeholder: string;
  readonly isBorder?: boolean;
  readonly isBackground?: boolean;
  readonly colorInputText?: string;
  readonly isAutoWidth?: boolean;
  readonly defaultValue?: {
    readonly id: number | string;
    readonly label: string;
    readonly value: string;
  }
  readonly isDisabled?: boolean;
}

const Select = (
  {
    classes = {},
    input,
    meta,
    label,
    options,
    placeholder,
    isBorder = true,
    isBackground,
    colorInputText = "#333333",
    isAutoWidth = false,
    defaultValue,
    isDisabled = false
  }: SelectProps): JSX.Element => {
  const customStyles = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    control: (base: any): any => ({
      ...base,
      height: 36,
      minHeight: 36,
      border: isBorder ? "1px solid #EDEDED" : "none",
      fontFamily: "font-family: Fira_Sans_Regular, sans-serif;",
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: "600",
      color: "#333333",
      background: isBackground ? "initial" : "transparent"
    }),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    container: (base: any) => {
      return {
        ...base,
        display: "inline-block"
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    menu: (base: any): any => ({
      ...base,
      fontFamily: "font-family: Fira_Sans_Regular, sans-serif;",
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: "600",
      color: "#333333",
      minWidth: "100%",
      left: 0,
      right: "unset",
      width: "auto"
    }),
    valueContainer: (base: any): any => ({
      ...base,
      paddingRight: "initial"
    }),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    indicatorsContainer: (prevStyle: any, state: any): any => state.isMulti ? ({
      ...prevStyle,
      display: "none",
    }) : null,
    singleValue: (base: any) =>  {
      const styles = {
        ...base,
        maxWidth: "none",
        position: "static",
        margin: "initial",
        top: "auto",
        transform: "none",
        color: colorInputText
      };
      return isAutoWidth ? styles : {
        ...base,
        color: colorInputText,
      };
    },
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: isAutoWidth ? "initial" : "8px"
    })
  };

  return (
    <SelectComponent
      menuPortalTarget={document.body}
      menuPosition="fixed"
      {...input}
      isDisabled={isDisabled}
      className={classes?.root}
      isSearchable
      options={options}
      placeholder={placeholder}
      classNamePrefix="CustomSelect"
      styles={customStyles}
      value={input?.value ? input.value : defaultValue}
      noOptionsMessage={() => "Не найдено!"}
    />
  )
};

export default Select;
