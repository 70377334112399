// Libraries
import React from "react";
import {useParams} from "react-router-dom";
import parse from "html-react-parser";
import Skeleton from "react-loading-skeleton";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Interfaces
import {PassportCollectionElement, PassportElement} from "interfaces/index";

// Components
import Button from "components/Button/Button";
import PageContent from "../PageContent/PageContent";

// Styles
import styles from "./ExploitationPage.scss";

const ExploitationPage = (): JSX.Element => {
  const { id } = useParams();
  const passport: PassportCollectionElement = useAppSelector(state =>
    state.passports.passportsCollection.find((passportElement: PassportElement): boolean =>
      passportElement.id == id));

  return (
    <div>
      <PageContent hasCharacteristics element={passport} />

      <div className={styles.ImplementationTabs}>
        <Button
          title="Основная информация"
          classes={{ root: styles.ImplementationPageTabsTab }}
          type="button"
        />
      </div>

      <div className={styles.MainInfo}>
        <div className={styles.MainInfoContent}>
          <div className={styles.MainInfoDescription}>
            { passport && passport.exploitation?.main_info ? parse(passport.exploitation.main_info) : <Skeleton /> }
          </div>
          <span className={styles.MainInfoFaqInfo}>
            *&nbsp;
            { passport && passport.exploitation?.notes ? parse(passport.exploitation.notes) : <Skeleton /> }
          </span>
        </div>
        {
          passport?.exploitation?.conclusion && (
            <div className={styles.MainInfoExpertOpinion}>
              <div className={styles.MainInfoExpertOpinionText}>
                {
                  passport && passport.exploitation?.expert && (
                    <>
                      <p className={styles.MainInfoExpertOpinionTextTitle}>Мнение эксперта</p>
                      { passport && passport.exploitation?.expert ? parse(passport.exploitation.expert) : <Skeleton /> }
                    </>
                  )
                }
                <div className={styles.MainInfoExpertOpinionConclusion}>
                  {
                    passport && passport.exploitation?.closing && (
                      <>
                        <p className={styles.MainInfoExpertOpinionClosing}>Выводы</p>
                        { parse(passport.exploitation.closing) }
                      </>
                    )
                  }
                  <p className={styles.MainInfoExpertOpinionClosingTwo}>
                    { passport && passport.exploitation?.conclusion && parse(passport.exploitation.conclusion) }
                  </p>
                </div>
              </div>

              {
                passport?.exploitation?.quote && (
                  <div className={styles.MainInfoFaq}>
                    <div className={styles.MainInfoFaqContent}>
                      { passport?.exploitation?.quote }
                    </div>
                  </div>
                )
              }

            </div>
          )
        }
      </div>
    </div>
  )
};

export default ExploitationPage;
