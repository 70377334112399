// Libraries
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {Field, Form} from "react-final-form";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Helpers
import {requireField} from "helpers/Validator";

// Actions
import {setCode, setErrors} from "store/reducers/authReducer";
import {restorePasswordAction} from "store/sagas/auth/actions";

// Enums
import {ModalTypes, VariantActionWithAuth} from "enums/index";

// Components
import ReactCodeInput from "react-verification-code-input";
import Button from "components/Button/Button";

// Styles
import styles from "../RegistrationForm/RegistrationForm.scss";

interface EnterCodeProps {
  readonly setActiveModalWindow: (value: boolean) => void;
  readonly setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>;
}

interface Values {
  readonly code: string;
}

const EnterCode = ({ setActiveModalWindow, setModalType }: EnterCodeProps): JSX.Element => {
  const authFormErrors = useAppSelector(state => state.auth.errors);
  const [isCodeError, setIsCodeError] = useState<string | undefined>(authFormErrors.find((error: any) => error.type === "code")?.text);
  const [second, setSecond] = useState(15);
  const dispatch = useAppDispatch();
  const email = useAppSelector(state => state.auth.email);
  const variantAction = useAppSelector(state => state.auth.variantAction);

  useEffect(() => {
    if (second > 0) {
      setTimeout(() => setSecond(second - 1), 1000);
    }
  }, [second]);

  const onSubmit = (values: Values) => {
    dispatch(setErrors([]));
    dispatch(setCode(values.code));
    setModalType(ModalTypes.RecoveryPsw);
  };

  const sendCodeAgain = (): void => {
    if (variantAction === VariantActionWithAuth.ResetPassword) {
      dispatch(restorePasswordAction({ email }));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, values, errors }): JSX.Element => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={styles.SuccessRegistration}>
              <div className={styles.SuccessRegistrationContainer}>
                <h4 className={styles.SuccessRegistrationContainerTitle}>Регистрация</h4>
                {
                  isCodeError ? (
                    <span className={styles.SuccessRegistrationContainerErrorMsg}>{ isCodeError }</span>
                  ) : (
                    <p className={styles.SuccessRegistrationContainerSubMsg}>
                      Мы отправили Вам сообщение <br /> с кодом на почту <span>{email}</span>
                    </p>
                  )
                }
              </div>
              <div className={styles.SuccessRegistrationCodeContainer}>
                <p>Код</p>
                <div className={classNames(styles.SuccessRegistrationCodeContainerInputs, isCodeError && styles.SuccessRegistrationCodeContainerInputsErrors)}>
                  <Field name="code" validate={requireField}>
                    {
                      ({ input }: any) => (
                        <ReactCodeInput {...input} onChange={(e) => {
                          if (e) {
                            input.onChange(e);
                          }
                          setIsCodeError(undefined);
                        }} type="number" fields={5} />
                      )
                    }
                  </Field>
                </div>
                {
                  second > 0 ? (
                    <span className={styles.SuccessRegistrationCodeContainerCode}>Отправить код можно через {second} сек.</span>
                  ) : (
                    <Button
                      title="Отправить код повторно"
                      classes={{ root: styles.SuccessRegistrationCodeContainerBtn }}
                      type="button"
                      callback={sendCodeAgain}
                    />
                  )
                }
              </div>
              <Button
                title="Подтвердить"
                classes={{ root: styles.SuccessRegistrationSubmit }}
                type="submit"
                disabled={values?.code?.length !== 5}
              />
              <i
                className={styles.Close}
                onClick={(): void => setActiveModalWindow(false)}
              />
            </div>
          </form>
        )
      }}
    />
  )
};

export default EnterCode;
