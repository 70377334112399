// Libraries
import React, {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Enums
import { VariantNotifications } from "enums/index";

// Actions
import {fetchNotificationsAction} from "store/sagas/notifications/actions";

// Interfaces
import {NotificationItem} from "store/reducers/notificationsReducer";


// Components
import History from "components/History/History";
import PrivateCabinet from "../../PrivateCabinet";

// Styles
import styles from "./Notifications.scss";

const perPage = 20;

const Notifications = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const notifications: NotificationItem[] = useAppSelector(state => state.notifications.notifications);
  const [page, setPage] = useState(1);
  const notificationsListTotal = useAppSelector(state => state.notifications.notificationsList.length)
  const fetchNotifications = (): void => {
    dispatch(fetchNotificationsAction({ per_page: perPage, page }));
  };

  const loadMore = (): void => {
    setPage(page + 1);
    dispatch(fetchNotificationsAction({ per_page: perPage, page: page + 1 }));
  };

  useEffect(fetchNotifications, []);

  return (
    <PrivateCabinet>
      <section id="notification-block" className={styles.Root} style={{ alignItems: notifications.length > 0 ? "initial" : "center" }}>
        <InfiniteScroll
          next={loadMore}
          dataLength={notificationsListTotal}
          loader=""
          hasMore
          scrollableTarget="notification-block"
        >
          <History variant={VariantNotifications.Notification} data={notifications} />
        </InfiniteScroll>
      </section>
    </PrivateCabinet>
  )
};

export default Notifications;
