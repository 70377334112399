// Libraries
import React, { useState } from "react";
import {v4 as uuidv4} from "uuid";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import { upperFirst, lowerCase } from "lodash";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Interfaces
import {CategoryElement, LevelElement} from "interfaces/index";

// Routes
import routes from "routes";

// Components
import MenuElement, {Variant} from "./MenuElement";

// Styles
import styles from "../Handbook.scss";

const Navigation = (): JSX.Element => {
  const [ isNavigationOpen, setNavigationOpen ] = useState(false);
  const levels: LevelElement[] = useAppSelector(state => state.levels.levels);
  const { levelId, categoryId } = useParams();

  const renderPassportList = (category: CategoryElement): JSX.Element[] => {
    return category.passports.map((passport): JSX.Element => (
      <li key={uuidv4()}>
        <Link to={routes.passport(passport.id)}>{ upperFirst(lowerCase(passport.title)) }</Link>
      </li>
    ));
  };

  const renderCategoriesList = (level: LevelElement): JSX.Element[] => {
    return level.categories.map((category): JSX.Element => (
      <MenuElement
        url={routes.handbookWithCategoryId(level.id, category.id)}
        key={uuidv4()}
        title={category.title}
        variant={Variant.Sub}
        isActive={categoryId == category.id}
        elementsCount={category.passports.length}
      >
        <ul className={styles.SubSubMenu}>
          {renderPassportList(category)}
        </ul>
      </MenuElement>
    ));
  };

  const renderLevelList = (): JSX.Element[] => {
    return levels.map((level): JSX.Element => (
      <MenuElement
        isActive={levelId == level.id}
        key={uuidv4()}
        title={level.title}
        variant={Variant.Main}
        elementsCount={level.categories.length}
        data-level={level.value}
      >
        <ul className={styles.SubMenu}>
          {renderCategoriesList(level)}
        </ul>
      </MenuElement>
    )).reverse();
  };

  const toogleNavigation = () => setNavigationOpen(!isNavigationOpen);

  return (
    <>
      <div className={styles.MobileTrigger} onClick={toogleNavigation} data-active={isNavigationOpen}>
        Разделы
      </div>
      <nav className={styles.Navigation} data-active={isNavigationOpen}>
        <div className={styles.Container}>
          <h4 className={styles.Title}>Справочник</h4>
          <ul className={styles.MainMenu}>
            { levels.length > 0 ? renderLevelList() : <Skeleton className={styles.SkeletonNavigationItems} count={4}/> }
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navigation;
