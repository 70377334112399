import {call, put, takeEvery} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {putLevels, putRequestLevelsStatus, RequestStatuses} from "store/reducers/navigationLevelsReducer";
import {fetchNavigationLevelsAction} from "./action";
import {fetchNavigationLevels} from "../../../Api";

export function* navigationLevelsWatcher(): Generator {
  yield takeEvery(fetchNavigationLevelsAction, navigationLevelsWorker);
}

function* navigationLevelsWorker(): Generator {
  yield put(putRequestLevelsStatus(RequestStatuses.Pending));
  
  try {
    const res = yield call(fetchNavigationLevels);
    yield put(putLevels((res as AxiosResponse).data));
    yield put(putRequestLevelsStatus(RequestStatuses.Success));
    yield put(putRequestLevelsStatus(RequestStatuses.Initial));
  } catch (error) {
    yield put(putRequestLevelsStatus(RequestStatuses.Error));
  }
}
