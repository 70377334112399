// Libraries
import React, { useEffect } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Cookies from "js-cookie";

// Components
import Main from "views/Main/Main";
import Handbook from "views/Handbook/Handbook";
import Passport from "views/Passport/Passport";
import PrivateCabinet from "views/PrivateCabinet/PrivateCabinet";
import Projects from "views/PrivateCabinet/components/Projects/Projects";
import PersonalData from "views/PrivateCabinet/components/PersonalData/PersonalData";
import Notifications from "views/PrivateCabinet/components/Notifications/Notifications";
import Invitations from "views/PrivateCabinet/components/Invitations/Invitations";
import Settings from "views/PrivateCabinet/components/Settings/Settings";
import Useful from "views/Useful/Useful";
import LickbessPage from "views/Lickbess/LickbessPage";
import Policy from "views/Policies/Policy";
import DataAgreement from "views/Policies/DataAgreement";
import HistoryProject from "views/PrivateCabinet/components/HistoryProject/HistoryProject";
import Direction from "views/Direction/Direction";

// Routes
import routes from "./routes";

// Styles
import styles from "./App.scss";

const ScrollToTop = withRouter(({ history }: any): null => {
  useEffect(() => {
    const unListen = history.listen(() => {
      if (!history.location.hash) {
        window.scrollTo(0, 0);
      }
    });

    return () => {
      unListen();
    }
  }, [history]);

  return null;
});

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }: any): JSX.Element => (
  <Route
    {...rest}
    render={(props: any): JSX.Element => (
      Cookies.getJSON("axp-user") ? <Component {...props} /> : <Redirect to="/" />
    )}
  />
)

const App = (): JSX.Element => (
  <div className={styles.Root}>
    <ScrollToTop />
    <Switch>
      <Route exact path={routes.main} component={Main} />
      <PrivateRoute exact path={routes.handbook} component={Handbook} />
      <PrivateRoute exact path={routes.handbookWithCategoryId()} component={Handbook} />
      <PrivateRoute exact path={routes.passport()} component={Passport} />
      <PrivateRoute exact path={routes.usefuls} component={Useful} />
      <PrivateRoute exact path={routes.usefulsElement()} component={LickbessPage} />
      <PrivateRoute exact path={routes.policy} component={Policy} />
      <PrivateRoute exact path={routes.pageDataAgreement} component={DataAgreement} />
      <PrivateRoute exact path={routes.direction} component={Direction} />
      {/*// TODO до сюда маршуты сделать потом доступными всем*/}
      <PrivateRoute exact path={routes.privateCabinet} component={PrivateCabinet} />
      <PrivateRoute exact path={routes.projects} component={Projects} />
      <PrivateRoute exact path={routes.project()} component={Projects} />
      <PrivateRoute exact path={routes.history()} component={HistoryProject} />
      <PrivateRoute exact path={routes.personalData} component={PersonalData} />
      <PrivateRoute exact path={routes.notifications} component={Notifications} />
      <PrivateRoute exact path={routes.invitations} component={Invitations} />
      <PrivateRoute exact path={routes.settings} component={Settings} />
    </Switch>
  </div>
)

export default App;
