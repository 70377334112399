// Libraries
import {call, put, takeEvery} from "redux-saga/effects";
import {AxiosPromise, AxiosResponse} from "axios";
import Cookies from "js-cookie";

// Reducers
import {putUser, RequestStatuses, setErrors, setLoginStatus} from "store/reducers/authReducer";

// Interfaces
import {RegisterFormValues} from "interfaces/index";

// Actions
import {authUserAction} from "./actions";

// Api
import {authUser} from "../../../Api";

export function* loginSagaWatcher(): Generator {
  yield takeEvery(authUserAction.type, putAuthUser);
}

function* putAuthUser({ payload } : any): Generator {
  yield put(setLoginStatus(RequestStatuses.Initial));

  try {
    const res = yield call(loginUser, payload);
    yield Cookies.set("axp-user",
      { token: (res as AxiosResponse).data.token, bearerToken: (res as AxiosResponse).data.bearer_token },
      { expires: 365 });
    yield put(putUser((res as AxiosResponse).data.user));
    yield put(setErrors([]));
    yield put(setLoginStatus(RequestStatuses.Success));
    yield put(setLoginStatus(RequestStatuses.Initial));
  } catch (error) {
    yield put(setErrors(error.response.data.errors));
    yield put(setLoginStatus(RequestStatuses.Error));
  }
}

function loginUser(values: RegisterFormValues): AxiosPromise {
  return authUser(values);
}
