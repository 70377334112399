// Libraries
import React from "react";
import {useParams, Link} from "react-router-dom";
import parse from "html-react-parser";
import { v4 as uuidv4 } from "uuid";

// Routes
import routes from "routes";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Interfaces
import {PassportCollectionElement, PassportElement} from "interfaces/index";

// Styles
import styles from "./MainInfo.scss";

const MainInfo = (): JSX.Element => {
  const { id } = useParams();
  const passport: PassportCollectionElement = useAppSelector(state =>
    state.passports.passportsCollection.find((passportElement: PassportElement): boolean =>
      passportElement.id == id));

  return (
    <div className={styles.DesignPageMainInfo}>
      <div className={styles.DesignPageMainInfoContent}>
        { passport && parse(passport.design.main_info) }
        { passport && passport.design?.notes && <span className={styles.DesignPageAdditionalContentNotes}>*&nbsp;{parse(passport.design.notes)}</span> }
        <div className={styles.UsefulLinkContainer}>
          {
            passport?.usefuls.map((useful): JSX.Element => (
              <Link className={styles.UsefulLink} key={uuidv4()} to={routes.usefulsElement(useful.id)}>{ useful.title }</Link>
            ))
          }
        </div>
      </div>
      {
        passport?.design.quote && (
          <div className={styles.DesignPageAdditionalContent}>
            <div className={styles.DesignPageAdditionalContentContainer}>
              {passport?.design.quote}
            </div>
          </div>
        )
      }
    </div>
  )
};

export default MainInfo;
