// Libraries
import React from "react";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";

// Routes
import routes from "routes";

// Styles
import styles from "./Footer.scss";

const year = new Date().getFullYear();

const Footer = (): JSX.Element => {
  const user = Cookies.getJSON("axp-user");

  return (
    <footer className={styles.Root}>
      <div className={styles.Container}>
        <nav className={styles.Navigation}>
          <ul>
            <li><NavLink to={routes.handbook}>Справочник</NavLink></li>
            <li><NavLink to={routes.usefuls}>Полезное</NavLink></li>
            {
              user && <li><NavLink to={routes.personalData}>Личный кабинет</NavLink></li>
            }
          </ul>
        </nav>
        <div className={styles.Text}>
          <h6>Email</h6>
          <a href="mailto:info@goodatlas.ru">info@goodatlas.ru</a>
        </div>

        <div className={styles.SocialsNetworks}>
          <div className={styles.Partners}>
            <a href="https://" className={styles.PartnersDKR} >&nbsp;</a>
          </div>

          <div className={styles.Social}>
            <h6>Поделиться в соц. сетях</h6>
            <div className={styles.SocialLinks}>
              <a className={styles.SocialVk} href="https://vk.com/share.php?url=https://goodatlas.ru/" target="_blank" rel="noreferrer" />
              <a className={styles.SocialFb} href="https://www.facebook.com/dialog/share?app_id=480565079861276&display=popup&href=https://goodatlas.ru/&redirect_uri=https://goodatlas.ru/" target="_blank" rel="noreferrer" />
              <a className={styles.SocialOk} href="https://connect.ok.ru/offer?url=https://goodatlas.ru/&title='Атлас хороших решений'&imageUrl=https://goodatlas.ru/logo512.png" target="_blank" rel="noreferrer" />
            </div>
          </div>
        </div>

      </div>
      <div className={styles.ProjectName}>
        <span>{`Все права защищены © ${year}`}</span>
        <div className={styles.SubFooterRightLinks}>
          <NavLink to={routes.direction}>Руководство пользователя</NavLink>
          <NavLink to={routes.policy}>Политика конфиденциальности</NavLink>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
