// Libraries
import {put, takeEvery, call, delay} from "redux-saga/effects";
import { AxiosResponse } from "axios";
import Cookies from "js-cookie";

// Enums
import {
  putCityList,
  putRequestAuthSocialStatus,
  putRequestAuthTemporaryStatus,
  putRequestResetForgotPasswordStatus,
  putRequestRestorePasswordStatus,
  putUser,
  RequestStatuses,
  setErrors,
  setGetUserInfoStatus,
  setRequestChangeUserEmailStatus,
  setRequestChangeUserInfoStatus,
  setRequestFetchCityListStatus,
  setRequestResetPasswordStatus,
  setRequestUploadAvatarStatus,
  setUserEmail
} from "store/reducers/authReducer";

// Actions
import {
  authSocialAction,
  authTemporaryUserAction,
  changeUserEmailAction,
  changeUserInfoAction,
  fetchCityListAction,
  getUserInfoAction, resetForgotPasswordAction,
  resetPasswordAction,
  restorePasswordAction,
  uploadAvatarAction
} from "store/sagas/auth/actions";
import {setError} from "../../reducers/errorsReducer";

// Api
import {
  authSocial,
  changeUserEmail,
  changeUserInfo,
  fetchCityList,
  fetchUserInfo, registerTemporaryUser,
  resetPasswordApi, restorePassword,
  uploadAvatar,
  resetForgotPassword as resetForgotPasswordApi, ChangeUserEmailI
} from "../../../Api";

export function* getUserInfoSagaWatcher(): Generator {
  yield takeEvery(authSocialAction, authSocialWorker);
  yield takeEvery(getUserInfoAction, getUserInfo);
  yield takeEvery(resetPasswordAction, resetPassword);
  yield takeEvery(changeUserInfoAction, changeUserInfoWorker);
  yield takeEvery(fetchCityListAction, fetchCityListWorker);
  yield takeEvery(uploadAvatarAction, uploadAvatarWorker);
  yield takeEvery(changeUserEmailAction, changeUserEmailWorker);
  yield takeEvery(authTemporaryUserAction, authTemporaryUserWorker);
  yield takeEvery(restorePasswordAction, restorePasswordWorker);
  yield takeEvery(resetForgotPasswordAction, resetForgotPassword);
}

function* getUserInfo(): Generator {
  yield put(setGetUserInfoStatus(RequestStatuses.Initial));

  try {
    const res = yield call(fetchUserInfo);
    yield put(putUser((res as AxiosResponse).data));
    yield put(setGetUserInfoStatus(RequestStatuses.Success));
    yield put(setErrors([]));
    yield put(setGetUserInfoStatus(RequestStatuses.Initial));
  } catch (error) {
    yield put(setErrors(error.response.data.errors));
    yield put(setGetUserInfoStatus(RequestStatuses.Error));
  }
}

function* resetPassword({ payload }: any): Generator {
  yield put(setRequestResetPasswordStatus(RequestStatuses.Initial));

  try {
    yield call(resetPasswordApi, payload);
    yield put(setRequestResetPasswordStatus(RequestStatuses.Success));
    yield put(setRequestResetPasswordStatus(RequestStatuses.Initial));
  } catch (error) {
    yield put(setErrors(error.response.data.errors))
    yield put(setRequestResetPasswordStatus(RequestStatuses.Error));
  }
}

function* changeUserInfoWorker({payload}: any): Generator {
  yield put(setRequestChangeUserInfoStatus(RequestStatuses.Pending));

  try {
    const res = yield call(changeUserInfo, payload);
    yield put(putUser((res as AxiosResponse).data));
    yield put(setRequestChangeUserInfoStatus(RequestStatuses.Success));
    yield put(setRequestChangeUserInfoStatus(RequestStatuses.Initial));
  } catch (error) {
    yield put(setRequestChangeUserInfoStatus(RequestStatuses.Error));
  }
}

function* fetchCityListWorker(): Generator {
  yield put(setRequestFetchCityListStatus(RequestStatuses.Pending));

  try {
    const res = yield call(fetchCityList);
    yield put(putCityList((res as AxiosResponse).data));
    yield put(setRequestFetchCityListStatus(RequestStatuses.Success));
    yield put(setRequestFetchCityListStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestFetchCityListStatus(RequestStatuses.Error));
  }
}

function* uploadAvatarWorker({ payload }: any): Generator {
  yield put(setRequestUploadAvatarStatus(RequestStatuses.Pending));

  try {
    const res = yield call(uploadAvatar, payload);
    yield put(putUser((res as AxiosResponse).data));
    yield put(setRequestUploadAvatarStatus(RequestStatuses.Success));
    yield put(setRequestUploadAvatarStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestUploadAvatarStatus(RequestStatuses.Error));
  }
}

interface ChangeUserEmailWorkerI {
  readonly payload: ChangeUserEmailI;
}
function* changeUserEmailWorker({ payload }: ChangeUserEmailWorkerI): Generator {
  yield put(setRequestChangeUserEmailStatus(RequestStatuses.Pending));
  try {
    yield call(changeUserEmail, payload);
    yield put(setUserEmail(payload.email));
    yield put(setRequestChangeUserEmailStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(setRequestChangeUserEmailStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestChangeUserEmailStatus(RequestStatuses.Error));
    yield put(setError({
      data: e.response.data,
      entity: payload
    }));
  }
}

function* authSocialWorker({ payload }: any): Generator {
  yield put(putRequestAuthSocialStatus(RequestStatuses.Pending));

  try {
    const res = yield call(authSocial, payload);
    yield Cookies.set("axp-user",
      { token: (res as AxiosResponse).data.token, bearerToken: (res as AxiosResponse).data.bearer_token },
      { expires: 365 });
    yield put(putUser((res as AxiosResponse).data.user));
    yield put(putRequestAuthSocialStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(putRequestAuthSocialStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(putRequestAuthSocialStatus(RequestStatuses.Error));
  }
}

function* authTemporaryUserWorker({ payload }: any): Generator {
  yield put(putRequestAuthTemporaryStatus(RequestStatuses.Pending));

  try {
    const res = yield call(registerTemporaryUser, payload);
    if ((res as AxiosResponse).data.token) {
      yield Cookies.set("axp-user",
        { token: (res as AxiosResponse).data.token, bearerToken: (res as AxiosResponse).data.bearer_token },
        { expires: 365 });
      window.history.pushState(null, "", window.location.pathname);
    }
    yield put(setErrors([]));
    yield put(putUser((res as AxiosResponse).data.user));
    yield put(putRequestAuthTemporaryStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(putRequestAuthTemporaryStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setErrors(e.response.data.errors));
    yield put(putRequestAuthTemporaryStatus(RequestStatuses.Error));
  }
}

function* restorePasswordWorker({ payload }: any): Generator {
  yield put(putRequestRestorePasswordStatus(RequestStatuses.Pending));
  
  try {
    yield call(restorePassword, payload);
    yield put(putRequestRestorePasswordStatus(RequestStatuses.Success));
    yield put(setErrors([]));
    yield delay(500);
    yield put(putRequestRestorePasswordStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setErrors(e.response.data.errors));
    yield put(putRequestRestorePasswordStatus(RequestStatuses.Error));
  }
}

function* resetForgotPassword({ payload }: any): Generator {
  yield put(putRequestResetForgotPasswordStatus(RequestStatuses.Pending));

  try {
    yield call(resetForgotPasswordApi, payload);
    yield put(setErrors([]));
    yield put(putRequestResetForgotPasswordStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(putRequestResetForgotPasswordStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setErrors(e.response.data.errors));
    yield put(putRequestResetForgotPasswordStatus(RequestStatuses.Error));
  }
}
