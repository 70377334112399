// Libraries
import React from "react";
import {Field, Form} from "react-final-form";

// Hooks
import {useAppDispatch} from "hooks/hooks";

// Actions
import {changeUserRoleOnProjectAction} from "store/sagas/invited/actions";

// Components
import Select from "components/Form/components/Select/Select";

// Styles
import styles from "./InvitationElement.scss";

export enum Pages {
  MyProjects,
  AvailableProjects
}

export enum Roles {
  Invited = "приглашенный",
  Editor = "редактор",
  Observer = "наблюдатель"
}

const options = [
  { id: 3, value: "editor", label: Roles.Editor },
  { id: 2, value: "observer", label: Roles.Observer }
];

const FormSelectElement = ({ project_role, page, userId, projectId }: any): JSX.Element => {
  const dispatch = useAppDispatch();

  const changeUserRole = (values: any): void => {
    dispatch(changeUserRoleOnProjectAction({
      id: projectId,
      user_id: userId,
      roles: values.roles
    }));
  }

  return (
    <Form
      onSubmit={changeUserRole}
      render={({ handleSubmit }): JSX.Element => {
        return (
          <form onBlur={() => handleSubmit()}>
            <Field
              name="roles"
            >
              {props => (
                <Select
                  classes={{ root: styles.InvitedList }}
                  {...props}
                  label="Роль"
                  placeholder="Роль"
                  options={options}
                  defaultValue={{id: project_role.id, value: project_role.title, label: project_role.title}}
                  isBorder={false}
                  isBackground={false}
                  colorInputText="#ACACAC"
                  isAutoWidth
                  isDisabled={page === Pages.AvailableProjects}
                />
              )}
            </Field>
          </form>
        )
      }}
    />
  )
};

export default FormSelectElement;
