// Libraries
import React, {useMemo} from "react";
import { v4 as uuidv4 } from "uuid";

// Interfaces
import {MaterialElement} from "interfaces/index";

// Styles
import styles from "./Templates.scss";

interface TemplateTwoProps {
  readonly materials: MaterialElement[];
}

const TemplateTwo = ({ materials }: TemplateTwoProps): JSX.Element => {
  const renderMaterials = useMemo((): JSX.Element[] => {
    return materials.map((material : MaterialElement): JSX.Element => (
      <div className={styles.ContainerImg} key={uuidv4()}>
        <img src={material.main_image[0].url} alt={material.title} />
        <span>{material.title}</span>
      </div>
    ))
  }, [materials]);

  return (
    <div className={styles.Root}>
      {renderMaterials}
    </div>
  )
};

export default TemplateTwo;
