import { createSlice } from "@reduxjs/toolkit";
import {RequestStatuses} from "enums/index";

const searchReducer = createSlice({
  name: "search",
  initialState: {
    passports: [],
    categories: [],
    requestFetchEntitiesStatus: RequestStatuses.Initial
  },
  reducers: {
    putPassports(state, action) {
      state.passports = action.payload;
    },
    putCategories(state, action) {
      state.categories = action.payload;
    },
    setRequestFetchEntitiesStatus(state, action) {
      state.requestFetchEntitiesStatus = action.payload;
    }
  }
});

export default searchReducer.reducer;
export const {
  putPassports,
  putCategories,
  setRequestFetchEntitiesStatus
} = searchReducer.actions;
