// Libraries
import React, {MouseEventHandler} from "react";
import classNames from "classnames";

// Styles
import styles from "./Button.scss";

interface ButtonProps {
  readonly startIcon?: React.ReactNode | null;
  readonly title: string;
  readonly classes: {
    readonly root?: string;
    readonly active?: string;
  }
  readonly type: "submit" | "reset" | "button";
  readonly callback?: MouseEventHandler;
  readonly disabled?: boolean;
  readonly dataModal?: boolean;
}

const Button = (
  {
    startIcon = null,
    title,
    classes,
    callback = (): void => {},
    type = "button",
    disabled = false,
    dataModal = false,
    ...props
  }: ButtonProps): JSX.Element => (
  <button
    data-modal={dataModal}
    className={classNames(classes?.root, styles.Root, classes?.active)}
    type={type}
    onClick={callback}
    disabled={disabled}
    {...props}
  >
    { startIcon }
    <span>{ title }</span>
  </button>
);

export default Button;
