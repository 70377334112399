// Libraries
import React from "react";
import parse from "html-react-parser";
import { Page, Text, View, Document, Font, StyleSheet, Image } from "@react-pdf/renderer";

// Fonts
// @ts-ignore
import FireLight from "styles/fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Light.ttf";
// @ts-ignore
import Fire from "styles/fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Regular.ttf";

// Types
import {LickBess} from "interfaces/index";

Font.register({ family: "Fira_Sans_Regular", src: Fire });
Font.register({ family: "Fira_Sans_Light", src: FireLight });

interface PdfDocumentProps {
  readonly element: LickBess;
}

const paddingSize = "70px";

const styles = StyleSheet.create({
  document: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: paddingSize,
  },
  title: {
    fontFamily: "Fira_Sans_Regular",
    textAlign: "center",
    fontSize: "24px",
    color: "#333333",
    marginBottom: 20
  },
  strong: {
    fontFamily: "Fira_Sans_Regular",
    fontSize: "14px",
    borderRight: "1px solid #D9DAE6",
    width: "65%",
    paddingTop: 20
  },
  desc: {
    fontFamily: "Fira_Sans_Light",
    fontSize: "14px",
    marginTop: "11px",
    marginBottom: 20
  },
  image: {
    marginTop: 20,
    marginBottom: 20
  },
  block: {
    padding: "20px 32px 20px 0",
    borderRight: "1px solid #D9DAE6",
    width: "65%"
  },
  text: {
    fontSize: "14px",
    fontFamily: "Fira_Sans_Light",
  },
  br: {
    paddingTop: 10,
    paddingBottom: 10
  }
});

const PdfDocumentTemplateOne = ({ element }: PdfDocumentProps): JSX.Element => {
  const renderContent = (): JSX.Element | null => {
    const elements: string | JSX.Element | JSX.Element[] = parse(element.text);
    // @ts-ignore
    return Array.isArray(elements) && elements.map((elem, i) => {
      if (elem.type === "p" && elem.props.children.type === "img") {
        return (
          <View key={i}>
            <Image src={elem.props.children.props.src} style={styles.image} />
          </View>
        )
      }

      if (elem.type === "p" && elem.props.children && typeof elem.props.children === "string") {
        return (
          <View key={i}>
            <Text style={styles.block}>
              <Text style={[styles.text, {...elem.props.style} ]}>
                { elem.props.children }
              </Text>
            </Text>
            <div style={{ borderBottom: "1px solid #D9DAE6" }} />
          </View>
        )
      }

      if (elem.type === "p" && elem.props.children.type === "strong") {
        return (
          <View key={i}>
            <Text style={[styles.strong, {...elem.props.children.props.style}]}>{ elem.props.children.props.children }</Text>
          </View>
        )
      }

      if (elem.type === "ul" && Array.isArray(elem.props.children)) {
        return elem.props.children.map((item: JSX.Element, index: number) => (
          <View key={index}>
            <Text style={styles.block}>
              <Text style={[styles.text, {...item.props.style}]}>
                { item.props.children }
              </Text>
            </Text>
            <div style={{ borderBottom: "1px solid #D9DAE6" }} />
          </View>
        ));
      }

      if (elem.type === "ul" && !Array.isArray(elem.props.children)) {
        return (
          <View key={i}>
            <Text style={styles.block}>
              <Text style={[styles.text, {...elem.props.style}]}>
                { elem.props.children }
              </Text>
            </Text>
            <div style={{ borderBottom: "1px solid #D9DAE6" }} />
          </View>
        );
      }

      return null;
    });
  };

  return (
    <Document>
      <Page size="A4" style={styles.document}>
        <View>
          <Text style={styles.title}>{ element.title }</Text>
          <Text style={styles.desc}>{ element.description }</Text>
        </View>
        { renderContent() }
      </Page>
    </Document>
  )
};

export default PdfDocumentTemplateOne;
