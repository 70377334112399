// Libraries
import { put, takeEvery, call } from "redux-saga/effects";
import {AxiosResponse} from "axios";

// Actions
import { lickbessListAction, lickbessAction } from "store/sagas/useful/actions";

// Api
import {fetchLickbess, fetchLickbessList} from "../../../Api";

// Reducers
import {
  putLickbess,
  RequestStatuses,
  setRequestLickbessListStatus,
  addLickbess,
  setRequestLickbessStatus,
  setLickbessListLenght
} from "../../reducers/usefulReducer";

export function* lickbessListWatcher(): Generator {
  yield takeEvery(lickbessListAction, lickbessListWorker);
}

function* lickbessListWorker({ payload }: any): Generator {
  yield put(setRequestLickbessListStatus(RequestStatuses.Pending));

  try {
    const res = yield call(fetchLickbessList, payload);
    yield put(setLickbessListLenght((res as AxiosResponse).data.total));
    yield put(putLickbess((res as AxiosResponse).data));
    yield put(setRequestLickbessListStatus(RequestStatuses.Success));
    yield put(setRequestLickbessListStatus(RequestStatuses.Initial));
  } catch (error) {
    yield put(setRequestLickbessListStatus(RequestStatuses.Error));
  }
}

export function* lickbessWatcher(): Generator {
  yield takeEvery(lickbessAction, lickbessWorker);
}

function* lickbessWorker({ payload }: any): Generator {
  yield put(setRequestLickbessStatus(RequestStatuses.Pending));

  try {
    const res = yield call(fetchLickbess, payload);
    yield put(addLickbess((res as AxiosResponse).data));
    yield put(setRequestLickbessStatus(RequestStatuses.Success));
    yield put(setRequestLickbessStatus(RequestStatuses.Initial));
  } catch (error) {
    yield put(setRequestLickbessStatus(RequestStatuses.Error));
  }
}
