// Libraries
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Components
import History from "components/History/History";
import InfiniteScroll from "react-infinite-scroll-component";
import PrivateCabinet from "views/PrivateCabinet/PrivateCabinet";
import Loader from "components/Loader/Loader";

// Types
import {NotificationItem, reset as resetHistory} from "store/reducers/notificationsReducer";
import {Project} from "interfaces/index";

// Actions
import {fetchHistoryProjectAction} from "store/sagas/notifications/actions";

// Enums
import {RequestStatuses, VariantNotifications} from "enums/index";

// Styles
import styles from "./styles.scss";

const perPage = 12;

const HistoryProject = (): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const histories: NotificationItem[] = useAppSelector(state => state.notifications.histories);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const requestFetchHistoryProjectStatus = useAppSelector(state => state.notifications.requestFetchHistoryProjectStatus);
  const project: Project = useAppSelector(state => state.projects.projects.find((item: Project) => item.id === Number(id)));

  const fetchHistories = (): void => {
    dispatch(resetHistory({}));
    dispatch(fetchHistoryProjectAction({
      id,
      order_by: "created_at",
      order_type: "desc",
      page,
      per_page: perPage
    }));
  };

  useEffect(fetchHistories, [id]);

  const moreHistories = (): void => {
    setPage(page + 1);
    dispatch(fetchHistoryProjectAction({
      page: page + 1,
      per_page: perPage,
      id,
      order_by: "created_at",
      order_type: "desc",
    }));
  };

  return (
    <PrivateCabinet>
      <>
        <p className={styles.ProjectName}>{ project?.title }</p>
        {
          requestFetchHistoryProjectStatus === RequestStatuses.Pending && histories.length === 0 ? (
            <div className={styles.LoaderContainer}>
              <Loader />
            </div>
          ) : (
            <div id="history-block" className={histories.length === 0 ? styles.EmptyHistory : styles.Content}>
              <InfiniteScroll
                next={moreHistories}
                dataLength={histories.length}
                loader=""
                hasMore
                scrollableTarget="history-block"
              >
                <History variant={VariantNotifications.History} data={histories} />
              </InfiniteScroll>
            </div>
          )
        }
      </>
    </PrivateCabinet>
  )
};

export default HistoryProject;
