// Libraries
import React from "react";

// Components
import Page from "components/Page/Page";

// Styles
import styles from "./styles.scss";

const Direction = (): JSX.Element => {
  return (
    <Page>
      <section className={styles.Root}>
        <h2>Руководство пользователя веб-сайта</h2>
        <h3>1 ОБЩИЕ ПОЛОЖЕНИЯ</h3>
        <h4>1.1 НАЗНАЧЕНИЕ САЙТА</h4>
        <p>Сайт используется пользователями при выполнении следующих операций</p>
        <ul>
          <li>1. Поиск и просмотр информации о материалах и элементах благоустройства;</li>
          <li>2. Регистрации в системе;</li>
          <li>3. Изучение полезных статей по тематике благоустройства общественных пространств;</li>
          <li>4. Создание и управление проектами;</li>
          <li>5. Добавление и Регистрация в системе совместное редактирование проектов с другими пользователями;</li>
          <li>6. Добавления пользователей на проекты;</li>
        </ul>
        <h3>2 ПРАВИЛА РАБОТЫ С САЙТОМ</h3>
        <h4>2.1 НАВИГАЦИЯ ПО САЙТУ</h4>
        <div>
          <img src="/imgs/direction_1.png" alt=""/>
          <span>Рисунок  1 - Панель навигации</span>
        </div>
        <p>С помощью панели навигации возможно осуществить переходы на следующие разделы, работать с поиском и просматривать уведомления:</p>
        <ul>
          <li>1. Главная;</li>
          <li>2. Разделы;</li>
          <li>3. Справочник;</li>
          <li>4. Полезное;</li>
          <li>5. Проекты;</li>
          <li>6. Личный кабинет.</li>
        </ul>
        <h2>2.2 Вход в личный кабинет пользователя</h2>
        <h3>2.2.1 Регистрация в системе</h3>
        <p>
          Для регистрации в системе пользователю необходимо нажать на кнопку <img width={30} src="/imgs/direction_24.png" alt=""/> в верхнем правом углу сайта.
          Далее в появившемся окне (Рисунок 1), необходимо нажать на
          кнопку «Зарегистрироваться». (Внимание! Регистрация возможна через соц. сети, пользователь
          регистрируется нажатием на кнопку выбранной соц. сети)
        </p>
        <div>
          <img src="/imgs/direction_2.png" alt=""/>
          <span>Рисунок  2 - Окно входа</span>
        </div>
        <p>После откроется окно регистрации (Рисунок 3),
          где пользователю необходимо заполнить все обязательные поля и нажать кнопку «Зарегистрироваться».</p>
        <div>
          <img src="/imgs/direction_3.png" alt=""/>
          <span>Рисунок  3 – Регистрация</span>
        </div>
        <p>
          После нажатия на кнопку «Зарегистрироваться» система вышлет на указанную электронную почту код, который
          необходимо будет ввести в окне (Рисунок 4) и нажать кнопку подтвердить. После этого, регисрация будет завершена и пользователь попадет в личный кабинет.
        </p>
        <div>
          <img src="/imgs/direction_4.png" alt=""/>
          <span>Рисунок  4 - Окно ввода кода</span>
        </div>
        <h3>2.2.2 Авторизация</h3>
        <p>
          Для авторизации в системе пользователю необходимо нажать на кнопку <img width={30} src="/imgs/direction_24.png" alt=""/> в верхнем правом углу сайта.
          Далее в появившемся окне (Рисунок 2), заполнить поля ввода «Email» и «Пароль» и нажать на кнопку «Войти».
          Если «Email» и «Пароль» были введены, верно, то пользователь перейдет в свой личный кабинет.
          (Внимание! Авторизация возможна через соц. сети, пользователь авторизуется нажатием на кнопку выбранной соц. сети).
        </p>
        <h3>2.2.3 Восстановление пароля</h3>
        <p>
          Для восстановления пароля пользователю необходимо нажать на кнопку <img width={30} src="/imgs/direction_24.png" alt=""/> в верхнем правом углу сайта.
          Далее в появившемся окне (Рисунок 2),
          нажать на кнопку «Забыли пароль». После нажатия на кнопку, пользователь должен ввести свою
          электронную почту, в появившемся экране,
          на которую ранее была произведена регистрация пользователя и нажать на кнопку «Отправить».
        </p>
        <div>
          <img src="/imgs/direction_5.png" alt=""/>
          <span>Рисунок  5 - Восстановление пароля</span>
        </div>
        <p>
          После нажатия на кнопку, пользователю отправляется код подтверждения на указанную почту.
          Необходимо ввести код подтверждения на экране  (Рисунок 6),
          который открывается поле нажатия на кнопку «Отправить».
        </p>
        <div>
          <img src="/imgs/direction_6.png" alt=""/>
          <span>Рисунок  6 - Код подтверждения для восстановления пароля</span>
        </div>
        <p>
          Если код подтверждения был введен, верно, то открывается окно для изменения пароля (Рисунок 7). Необходимо ввести
          новый пароль и нажать на кнопку
          «Подтвердить». После подтверждения изменения пароля появляется уведомление,
          нажимаем на кнопку «Войти с новым паролем» и переходим в личный кабинет.
        </p>
        <div>
          <img src="/imgs/direction_7.png" alt=""/>
          <span>Рисунок  7 - Изменение пароля</span>
        </div>
        <div>
          <img src="/imgs/direction_8.png" alt=""/>
          <span>Рисунок  8 - Вход с новым паролем</span>
        </div>
        <h3>2.3 Личный кабинет пользователя</h3>
        <div>
          <img src="/imgs/direction_9.png" alt=""/>
          <span>Рисунок  9 – Личный кабинет (Личные данные)</span>
        </div>
        <p>
          В личном кабинете пользователя (Рисунок 9) можно перейти на следующие разделы:
        </p>
        <ul>
          <li>1. Личные данные;</li>
          <li>2. Проекты;</li>
          <li>3. Уведомления;</li>
          <li>4. Приглашения;</li>
          <li>5. Настройки.</li>
        </ul>
        <h3>2.3.1 Личные данные</h3>
        <p>
          Для перехода в личные данные необходимо нажать на кнопку «Личные данные» в панели навигации.
          В личных данных (Рисунок 9) пользователь может изменить собственные данные путем ввода данных в полях.
        </p>
        <h3>2.3.2 Проекты пользователя</h3>
        <p>
          Для перехода в раздел «Проекты» необходимо нажать на кнопку «Проекты» в панели навигации.
          В проектах пользователя (Рисунок 10) доступен просмотр:
        </p>
        <ul>
          <li>1. Проектов пользователя;</li>
          <li>2. Паспортов проекта;</li>
          <li>3. Проектов доступных пользователю.</li>
        </ul>
        <p>
          Пользователь может управлять своими проектами:
        </p>
        <ul>
          <li>1. Скачивать проект;</li>
          <li>2. Приглашать;</li>
          <li>3. Просматривать историю;</li>
          <li>4. Приглашать других пользователей;</li>
          <li>5. Удалять проект.</li>
        </ul>
        <div>
          <img src="/imgs/direction_10.png" alt=""/>
          <span>Рисунок  10 - Проекты пользователя</span>
        </div>
        <h3>2.3.3 Доступные проекты пользователю</h3>
        <p>
          Для перехода в проекты доступные пользователям  (Рисунок 11) необходимо нажать на кнопку «Доступные мне»,
          в нем пользователь может просматривать доступные проекты, которые ему открыли другие пользователи.
          В зависимости от прав доступа пользователь может производить изменения в проекте.
        </p>
        <div>
          <img src="/imgs/direction_11.png" alt=""/>
          <span>Рисунок  11 - Проекты доступные пользователю</span>
        </div>
        <h3>2.3.4 Уведомления</h3>
        <p>
          Для перехода в уведомления  (Рисунок 12) необходимо нажать на кнопку
          «Уведомления» в панели навигации, к уведомлениям можно перейти еще внутри личного кабинета. На вкладке «Уведомления» пользователю доступна возможность просмотра
          уведомлений об изменениях в проектах пользователя и доступных ему.
        </p>
        <div>
          <img src="/imgs/direction_12.png" alt=""/>
          <span>Рисунок  12 – Уведомления</span>
        </div>
        <h3>2.3.5 Приглашения</h3>
        <p>
          Для перехода в приглашения (Рисунок 13) необходимо нажать на кнопку «Приглашения» в личном кабинете пользователя.
          На вкладке
          «Приглашения», (Рисунок 13) пользователю доступна возможность просмотра приглашенных им пользователей и их права.
          Так же пользователь может просмотреть доступные ему права на вкладке «Доступные мне» если у него есть доступ к
          проекту в одной из ролей «Редактор» или «Наблюдатель».
        </p>
        <div>
          <img src="/imgs/direction_13.png" alt=""/>
          <span>Рисунок  13 – Приглашения</span>
        </div>
        <h3>2.3.6 Настройки</h3>
        <p>
          Для перехода в настройки (Рисунок 14) необходимо нажать на кнопку «Настройки» в личном кабинете пользователя.
          Во вкладке «Настройки» пользователь может:
        </p>
        <ul>
          <li>1. Выйти из аккаунта;</li>
          <li>2. Удалить аккаунт.</li>
        </ul>
        <div>
          <img src="/imgs/direction_14.png" alt=""/>
          <span>Рисунок  14 – Настройки</span>
        </div>
        <h3>2.4 Справочник</h3>
        <p>
          Для перехода в раздел справочник, необходимо нажать на кнопку «Справочник» в панели навигации (Рисунок 1).
          После нажатия на кнопку пользователь попадает на страницу справочника (Рисунок 15).
        </p>
        <div>
          <img src="/imgs/direction_15.png" alt=""/>
          <span>Рисунок  15 – Справочник</span>
        </div>
        <p>В разделе справочники доступны следующие виды уровней, на которые сгруппированы материалы и элементы благоустройства:</p>
        <ul>
          <li>1. Уровень 2;</li>
          <li>2. Уровень 1;</li>
          <li>3. Уровень 0;</li>
          <li>4. Уровень -1.</li>
        </ul>
        <p>
          При нажатии на уровень открывается перечень категорий и паспортов, на которые возможно перейти (Рисунок 16).
        </p>
        <div>
          <img src="/imgs/direction_16.png" alt=""/>
          <span>Рисунок  16 – Навигация по уровням справочника</span>
        </div>
        <h3>2.4.1 Добавление паспорта в проект</h3>
        <p>
          Чтобы добавить паспорт в проект, необходимо выбрать паспорт в справочнике и на жать на кнопку «+».
          В отобразившемся окне (Рисунок 17), необходимо выбрать проект из списка или добавить другой проект с помощью кнопки «+ Добавить новый проект».
        </p>
        <div>
          <img src="/imgs/direction_17.png" alt=""/>
          <span>Рисунок  17 – Добавление паспорта в проект</span>
        </div>
        <p>После добавление паспорта в проект, в проекте в который был добавлен паспорт появится значок.
          Так же можно добавить паспорт в проекты на вкладке «Доступные мне» если пользователю предоставлены права для внесения изменений в проект.</p>
        <h3>
          2.4.2 Фильтрация паспортов в справочнике
        </h3>
        <p>
          Чтобы отфильтровать паспорта в справочнике, необходимо использовать фильтры (Рисунок 18). После выбора фильтров,
          система отобразит на сайте все паспорта, соответствующие параметрам фильтрации.
        </p>
        <div>
          <img src="/imgs/direction_18.png" alt=""/>
          <span>Рисунок  18 – Фильтры</span>
        </div>
        <p>
          Для поиска конкретного паспорта или категории можно воспользоваться кнопкой в панели навигации (Рисунок 1).
          После нажатия на кнопку, отобразится окно со всеми разделами и паспортами на сайте (Рисунок 19)<img width={80} src="/imgs/direction_25.png" alt=""/>.
        </p>
        <div>
          <img src="/imgs/direction_19.png" alt=""/>
          <span>Рисунок  19 - Все разделы</span>
        </div>
        <h3>2.4.3 Паспорт</h3>
        <p>
          Для перехода к просмотру паспорта необходимо выбрать паспорт в списке разделов (Рисунок 19)
          или на странице справочника (Рисунок 15). После нажатия на необходимый паспорт, пользователь попадает на
          страницу паспорта (Рисунок 20).
        </p>
        <div>
          <img src="/imgs/direction_20.png" alt=""/>
          <span>Рисунок  20 - Страница паспорта</span>
        </div>
        <p>На странице паспорта доступны следующие возможности:</p>
        <ul>
          <li>1. Просмотра информации об элементе или материале благоустройства:
            <ul>
              <li>a. Вкладка «Проектирование»;</li>
              <li>b. Вкладка «Реализация»;</li>
              <li>c. Вкладка «Эксплуатация».</li>
            </ul>
          </li>
          <li>2. Просмотр 2. видов материала/элемента и цветового ассортимента;</li>
          <li>3. Добавление паспорта в необходимый проект.</li>
        </ul>
        <h3>2.5 Полезное</h3>
        <p>
          Для перехода в раздел полезное, необходимо нажать на кнопку «Полезное» в панели навигации (Рисунок 1).
          После нажатия на кнопку пользователь попадает на страницу полезное (Рисунок 21).
          В разделе «Полезное» пользователю доступны вкладки:
        </p>
        <ul>
          <li>1. Ликбезы;</li>
          <li>2. Чек-листы.</li>
        </ul>
        <div>
          <img src="/imgs/direction_21.png" alt=""/>
          <span>Рисунок  21 – Полезное</span>
        </div>
        <h3>2.5.1 Карточка ликбеза/чек-листа</h3>
        <p>
          Для перехода на карточку ликбеза/чек-листа необходимо нажать на необходимый чек-лист/ликбез на странице
          «Полезное» (Рисунок 21). После нажатия на кнопку, откроется страница с выбранным ликбезом/чек-листом (Рисунок 22).
        </p>
        <div>
          <img src="/imgs/direction_22.png" alt=""/>
          <span>Рисунок  22 - Чек-лист/ликбез</span>
        </div>
        <p>На странице ликбеза/чек-листа доступна возможность скачивания информации в формате PDF.</p>
        <h3>2.6 Футер сайта</h3>
        <p>
          На всех основных страницах сайта отображается футер (Рисунок 23). С помощью него можно перейти на основные разделы
          сайта и ознакомиться со справочной информацией для пользователя. Футер по функционалу дублирует кнопки
          панели навигации.
        </p>
        <div>
          <img src="/imgs/direction_23.png" alt=""/>
          <span>Рисунок  23 - Футер сайта</span>
        </div>
      </section>
    </Page>
  )
};

export default Direction;
