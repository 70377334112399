// Libraries
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import Cookies from "js-cookie";

import {ModalTypes} from "enums/index";

// Styles
import styles from "./Page.scss";

// Components
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import LoginForm from "./components/LoginForm/LoginForm";
import RegistrationForm from "./components/RegistrationForm/RegistrationForm";
import RecoveryForm from "./components/RecoveryForm/RecoveryForm";
import SuccessRegistration from "./components/SuccessRegistration/SuccessRegistration";
import CookiePopup from "./components/CookiePopup/CookiePopup";
import EnterCode from "./components/EnterCode/EnterCode";
import RecoveryPsw from "./components/RecoveryPsw/RecoveryPsw";

interface PageProps {
  readonly classes?: {
    readonly root?: string;
  };
  readonly children: React.ReactNode;
  readonly isFooter?: boolean;
}

function disableScrolling(): void {
  const {scrollX} = window;
  const {scrollY} = window;
  window.onscroll = () => { window.scrollTo(scrollX, scrollY) };
}

const Page = ({ classes, children, isFooter = true }: PageProps): JSX.Element => {
  const user = Cookies.getJSON("axp-user");
  const [modalType, setModalType] = useState<ModalTypes>(ModalTypes.Login);
  const [isActiveModalWindow, setActiveModalWindow] = useState<boolean>(!user);
  const getParams = new URLSearchParams(window.location.search);
  const [isShowCookiePopup, setIsShowCookiePopup] = useState(!Cookies.getJSON("axp-agree-worm"));
  // @ts-ignore
  const params = (new URL(document.location)).searchParams;
  const authSocial = (): void => {
    if (getParams.get("temp_entity_id")) {
      setActiveModalWindow(true);
      setModalType(ModalTypes.Registration);
    }
  };

  useEffect(authSocial, []);

  useEffect(() => {
    if (isActiveModalWindow) {
      disableScrolling();
    } else {
      window.onscroll = () => {};
    }
  }, [isActiveModalWindow]);

  const renderForm = (): JSX.Element => {
    switch (modalType) {
      case ModalTypes.Login:
        return <LoginForm key="login-form" setModalType={setModalType} setActiveModalWindow={setActiveModalWindow} />;
      case ModalTypes.Registration:
        return <RegistrationForm key="reg-form" setModalType={setModalType} setActiveModalWindow={setActiveModalWindow} />;
      case ModalTypes.RecoverySendEmailPsw:
        return <RecoveryForm key="recovery-form" setModalType={setModalType} setActiveModalWindow={setActiveModalWindow} />;
      case ModalTypes.SuccessRegistration:
        return <SuccessRegistration key="success-register-form" setActiveModalWindow={setActiveModalWindow} />;
      case ModalTypes.EnterCode:
        return <EnterCode key="enter-code-form" setActiveModalWindow={setActiveModalWindow} setModalType={setModalType} />;
      case ModalTypes.RecoveryPsw:
        return <RecoveryPsw key="recovery-password" setActiveModalWindow={setActiveModalWindow} setModalType={setModalType} />;
      default:
        return <LoginForm key="login-form" setModalType={setModalType} setActiveModalWindow={setActiveModalWindow} />;
    }
  }

  return (
    <div className={classNames(styles.Root, classes?.root)} data-has-header={params.get("hasHeaderAndFooter")}>
      { isActiveModalWindow && renderForm()}
      {
        params.get("hasHeaderAndFooter") !== "false" && (
          <Header
            setActiveModalWindow={setActiveModalWindow}
            isActiveModalWindow={isActiveModalWindow}
            setModalType={setModalType}
            modalType={modalType}
          />
        )
      }
      { children }
      {
        isShowCookiePopup && <CookiePopup setIsShowCookiePopup={setIsShowCookiePopup} />
      }
      { isFooter && params.get("hasHeaderAndFooter") !== "false" && <Footer /> }
    </div>
  )
};

export default Page;
