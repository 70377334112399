// Libraries
import React from "react";
import { v4 as uuidv4 } from "uuid";
import parse from "html-react-parser";

// Interfaces
import {ApplicationAreas, MaterialElement} from "interfaces/index";

// Components
import ComplexityInstallation from "components/Filtration/components/ComplexityInstallation/ComplexityInstallation";
import Cost from "components/Filtration/components/Cost/Cost";
import ApplicationArea, {ApplicationAreaFilterElement} from "components/Filtration/components/ApplicationArea/ApplicationArea";

// Styles
import styles from "./Templates.scss";

interface TemplateOneProps {
  readonly materials: MaterialElement[];
}

const TemplateOne = ({ materials }: TemplateOneProps): JSX.Element => {
  return (
    <>
      <div className={styles.MaterialsContainer}>
        {
          materials?.filter((material) => material.complexity !== null || material.price !== null || material.application_areas?.length !== 0)?.map((material): JSX.Element => {
            return (
              <div key={uuidv4()} className={styles.Content}>
                <img className={styles.ContentImg} src={material.main_image[0].url} alt={material.main_image[0].url} />
                <div className={styles.Description}>
                  <h4>{ material.title }</h4>
                  <p className={styles.DescriptionSubDesc}>{material?.short_desc}</p>
                  {
                    material?.full_desc && (
                      <div className={styles.FullDescMobile}>
                        {parse(material.full_desc)}
                      </div>
                    )
                  }
                  <div className={styles.Specifications}>
                    <div className={styles.Column}>
                      {
                        material?.complexity && (
                          <div className={styles.FilterComplexityInstallation}>
                            <ComplexityInstallation
                              classes={{ root: styles.FilterComplexityInstallationRoot, title: styles.FilterComplexityInstallationTitle }}
                              complexity={material.complexity}
                            />
                          </div>
                        )
                      }
                      {
                        material?.price && (
                          <div className={styles.FilterCost}>
                            <Cost
                              classes={{ root: styles.FilterCostRoot, title: styles.FilterCostTitle }}
                              cost={material.price}
                            />
                          </div>
                        )
                      }
                      {
                        material.application_areas.length > 0 && (
                          <div className={styles.FilterApplicationArea}>
                            <ApplicationArea classes={
                              {
                                title: styles.FilterApplicationAreaTitle,
                                root: styles.FilterApplicationAreaRoot
                              }
                            }>
                              {
                                material.application_areas.map((area: ApplicationAreas): JSX.Element => (
                                  <ApplicationAreaFilterElement title={area.title} key={uuidv4()} category={area.slug} isActive />
                                ))
                              }
                            </ApplicationArea>
                          </div>
                        )
                      }
                    </div>
                    {
                      material?.full_desc && (
                        <div className={styles.Column}>
                          { parse(material.full_desc) }
                        </div>
                      )
                    }
                  </div>
                </div>
                {
                  material?.material_colors?.length > 0 && (
                    <div className={styles.ExampleImgs}>
                      {
                        material.material_colors.map((color): JSX.Element => (
                          <div key={uuidv4()} className={styles.ExampleImgsContainer}>
                            <img className={styles.ExampleImg} src={color.main_image[0].url} alt=""/>
                            <span>{ color?.title }</span>
                          </div>
                        ))
                      }
                    </div>
                  )
                }
              </div>
            )
          })
        }
      </div>
      <div className={styles.MaterialsColorsContainer}>
        {
          materials?.filter((material) =>
            material.material_colors?.length === 0 && material.price === null && material.complexity === null && material.application_areas?.length === 0)
            ?.map((material) => (
              <div key={uuidv4()} className={styles.ContainerImg}>
                <img src={material.main_image[0].url} alt=""/>
                <span>{ material?.title }</span>
              </div>
            ))
        }
      </div>
    </>
  )
};

export default TemplateOne;
