// Libraries
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {useParams} from "react-router-dom";

// Interfaces
import {PassportCollectionElement} from "interfaces/index";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Components
import Button from "components/Button/Button";

// Styles
import styles from "./Navigation.scss";

export enum PagesEnum {
  Design,
  Implementation,
  Exploitation
}

interface NavigationProps {
  readonly page: PagesEnum;
  readonly setPage: (page: PagesEnum) => void;
}

const Navigation = ({ page, setPage }: NavigationProps): JSX.Element => {
  const { id } = useParams();
  const passport: PassportCollectionElement = useAppSelector(state =>
    state.passports.passportsCollection.find((passportElement: PassportCollectionElement): boolean =>
      passportElement.id == id));
  const [pageIndex, setPageIndex] = useState(0);
  const pages = [
    ...(passport?.design?.main_info ? [PagesEnum.Design] : []),
    ...(passport?.realization?.main_info ? [PagesEnum.Implementation] :[]),
    ...(passport?.exploitation?.main_info ? [PagesEnum.Exploitation] : [])
  ];

  const changePage = (incrase: number) => {
    const toIndex = pageIndex + incrase;
    if(toIndex >= 0 && toIndex < pages.length) {
      setPageIndex(toIndex);
      setPage(pages[toIndex]);
    }
  };

  useEffect(() => {
    setPageIndex(pages.indexOf(page));
  }, [page, pages]);

  return (
    <ul
      className={classNames(
        styles.Navigation,
        [
          { [styles.NavigationBorderBlueBottom]: page === PagesEnum.Design },
          { [styles.NavigationBorderRedBottom]: page === PagesEnum.Implementation },
          { [styles.NavigationBorderGreenBottom]: page === PagesEnum.Exploitation },
        ]
      )}
    >
      {
        passport?.design?.main_info && (
          <li className={classNames(
            styles.Tab,
            [{[styles.ActiveBlueTab]: page === PagesEnum.Design}]
          )}>
            <Button
              title="Проектирование"
              callback={(): void => setPage(PagesEnum.Design)}
              classes={{ root: "" }}
              type="button"
            />
          </li>
        )
      }
      {
        passport?.realization?.main_info && (
          <li className={classNames(
            styles.Tab,
            [{[styles.ActiveRedTab]: page === PagesEnum.Implementation}]
          )}>
            <Button
              title="Реализация"
              callback={(): void => setPage(PagesEnum.Implementation)}
              classes={{ root: "" }}
              type="button"
            />
          </li>
        )
      }
      {
        passport?.exploitation?.main_info && (
          <li className={classNames(
            styles.Tab,
            [{[styles.ActiveGreenTab]: page === PagesEnum.Exploitation}]
          )}>
            <Button
              title="Эксплуатация"
              callback={(): void => setPage(PagesEnum.Exploitation)}
              classes={{ root: "" }}
              type="button"
            />
          </li>
        )
      }
      <li className={styles.Controls}>
        <Button
          title=""
          callback={(): void => changePage(-1)}
          classes={{ root: styles.ControlsPrev }}
          type="button"
          data-show={pageIndex > 0}
        />
        <Button
          title=""
          callback={(): void => changePage(1)}
          classes={{ root: styles.ControlsNext }}
          type="button"
          data-show={pageIndex < pages.length - 1}
        />
      </li>
    </ul>
  )
};

export default Navigation;
