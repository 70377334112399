// Libraries
import React, {useCallback, useEffect, useState} from "react";
import { v4 as uuidv4 } from "uuid";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Actions
import {lickbessListAction} from "store/sagas/useful/actions";

// Reducers
import {RequestStatuses, LickBessListElement, resetUsefulList} from "store/reducers/usefulReducer";

// Components
import Loader from "components/Loader/Loader";
import Card from "./components/Card/Card";

// Styles
import styles from "./LickbessList.scss";

const perPage = 8;

export enum Variant {
  Lickbess,
  CheckList
}

interface UsefulListProps {
  readonly variant: Variant;
}

const LickbessList = ({ variant }: UsefulListProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const lickbessList = useAppSelector(state => state.useful.lickbessList);
  const lickbesses = useAppSelector(state => state.useful.lickbess);
  const requestLickbessListStatus = useAppSelector(state => state.useful.requestLickbessListStatus);
  const commonLickbessListLenght = useAppSelector(state => state.useful.commonLickbessListLenght);
  const [page, setPage] = useState<number>(1);
  const [isActiveAdditionSkeleton, setActiveAdditionalSkeleton] = useState(false);

  useEffect(() => {
    if (requestLickbessListStatus === RequestStatuses.Initial && isActiveAdditionSkeleton) {
      setActiveAdditionalSkeleton(false);
    }
  }, [isActiveAdditionSkeleton, requestLickbessListStatus]);

  const setPageValue = useCallback(() => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const height = document.body.scrollHeight - 320;

    if (scrollPosition >= height &&
      requestLickbessListStatus === RequestStatuses.Initial &&
      (lickbesses.length) < commonLickbessListLenght
    ) {
      setPage(page + 1);
      setActiveAdditionalSkeleton(true);
    }
  }, [page, requestLickbessListStatus, commonLickbessListLenght, lickbesses.length]);

  useEffect(() => {
    dispatch(resetUsefulList([]))
  }, [dispatch, variant]);


  useEffect(() => {
    document.addEventListener("scroll", setPageValue, false);

    return () => document.removeEventListener("scroll", setPageValue, false);
  }, [setPageValue]);

  useEffect(() => {
    dispatch(lickbessListAction({ page, per_page: perPage, useful_type: variant === Variant.Lickbess ? 2 : 1, order_by: "id" }));
  }, [dispatch, page, variant]);

  return (
    <div className={styles.Content}>
      {
        lickbessList.length === 0 && requestLickbessListStatus === RequestStatuses.Pending
          ?
          <div className={styles.ContentLoader}>
            <Loader />
          </div>
          :
            lickbessList.map((items: LickBessListElement): JSX.Element | null => {
              return items?.array?.length > 0 ? (
                <Card
                  key={uuidv4()}
                  items={items}
                />
              ) : null
            })
      }
      {
        isActiveAdditionSkeleton && (
          <div className={styles.ContentLoader}>
            <Loader />
          </div>
        )
      }
    </div>
  )
};

export default LickbessList;
