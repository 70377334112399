// Libraries
import React from "react";

// Styles
import styles from "./styles.scss";

const AlertTemplate = ({ style, options, message, close }: any): JSX.Element => {
  return (
    <div className={styles.Root} style={style}>
      {options.type === "info" && "!"}
      {options.type === "success" && ":)"}
      {options.type === "error" && <i className={styles.DanderAlert} />}
      <p>{message}</p>
      <i onClick={close} className={styles.Close} />
    </div>
  )
};

export default AlertTemplate;
