// Libraries
import React, {useEffect, useState} from "react";

// Hooks
import {useAppDispatch} from "hooks/hooks";

// Actions
import {fetchProjectsAction} from "store/sagas/projects/actions";

// Components
import Button from "components/Button/Button";
import InvitationModal from "components/ModalWindows/InvitationModal/InvitationModal";
import PrivateCabinet from "../../PrivateCabinet";
import MyProjectsComponent, {Variant} from "./components/MyProjects/MyProjects";

// Styles
import styles from "./Projects.scss";

enum Pages {
  MyProjects,
  AvailableProjects
}

const Projects = (): JSX.Element => {
  const [page, setPage] = useState<Pages>(Pages.MyProjects);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProjectsAction({}));
  }, [dispatch]);

  return (
    <PrivateCabinet>
      <section className={styles.Root}>
        <div className={styles.Tabs}>
          <Button
            title="Мои проекты"
            classes={{ root: styles.Tab, active: page === Pages.MyProjects ? styles.ActiveTab : "" }}
            type="button"
            callback={(): void => setPage(Pages.MyProjects)}
          />
          <Button
            title="Доступные мне"
            classes={{ root: styles.Tab, active: page === Pages.AvailableProjects ? styles.ActiveTab : "" }}
            type="button"
            callback={(): void => setPage(Pages.AvailableProjects)}
          />
        </div>
        { page === Pages.MyProjects && <MyProjectsComponent variant={Variant.MyProject} />}
        { page === Pages.AvailableProjects && <MyProjectsComponent variant={Variant.AvailableProject} />}
        <InvitationModal />
      </section>
    </PrivateCabinet>
  )
};

export default Projects;
