import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import {PassportCollectionElement, PassportElement, ProjectWithPassports as ProjectWithPassportsI} from "interfaces/index";

export enum RequestStatuses {
  Initial,
  Pending,
  Success,
  Error
}

interface State {
  passportsList: PassportElement[];
  requestPassportsStatus: RequestStatuses;
  passportsListTotal: null | number;
  passportsCollection: PassportCollectionElement[];
  requestPassportStatus: RequestStatuses;
  requestMovePassports: RequestStatuses;
  requestCopyPassports: RequestStatuses;
  removingPassports: {
    projectId: number | string,
    passport: PassportElement
  }[];
  projectWithPassports: ProjectWithPassportsI[]
}

const initialState: State = {
  passportsList: [],
  requestPassportsStatus: RequestStatuses.Initial,
  passportsListTotal: null,
  passportsCollection: [],
  requestPassportStatus: RequestStatuses.Initial,
  requestMovePassports: RequestStatuses.Initial,
  requestCopyPassports: RequestStatuses.Initial,
  removingPassports: [],
  projectWithPassports: []
};

const passportsSlice = createSlice<State, SliceCaseReducers<State>>({
  name: "passports",
  initialState,
  reducers: {
    putPassports(state, action): void {
      action.payload.passports.data.forEach((item: PassportElement): void => {
        if (!state.passportsList.find((passport: PassportElement): boolean => passport.id === item.id)) {
          state.passportsList.push(item);
        }
      });
    },
    setProjectWithPassports(state, action): void {
      const project = state.projectWithPassports.find((projectItem: ProjectWithPassportsI): boolean => projectItem.projectId === action.payload.projectId)
      if (!project) {
        state.projectWithPassports.push({
          projectId: action.payload.projectId,
          passports: action.payload.passports.data
        });
      } else {
        action.payload.passports.data.forEach((passport: PassportElement) => {
          if (!project.passports.find((element: PassportElement) => element.id === passport.id)) {
            project.passports.push(passport);
          }
        });
      }
    },
    setRequestPassportsStatus(state, action) {
      state.requestPassportsStatus = action.payload;
    },
    putPassportsListTotal(state, action) {
      state.passportsListTotal = action.payload;
    },
    putPassportCollection(state, action): void {
      if (!state.passportsCollection.find((passport: PassportCollectionElement) => passport.id === action.payload.id)) {
        state.passportsCollection.push(action.payload);
      }
    },
    setRequestPassportStatus(state, action): void {
      state.requestPassportStatus = action.payload;
    },
    setRequestMovePassports(state, action): void {
      state.requestMovePassports = action.payload;
    },
    setRequestCopyPassports(state, action): void {
      state.requestCopyPassports = action.payload;
    },
    reset: (): State => initialState,
    removePassport(state, action): void {
      state.removingPassports = [];

      action.payload.passport_id.forEach((passportId: number | string) => {
        const project: ProjectWithPassportsI | undefined = state.projectWithPassports.find((item: ProjectWithPassportsI) => item.projectId === action.payload.project_id);
        if (project) {
          const foundPassport: PassportElement | undefined = project.passports.find((passport: PassportElement) => passport.id === passportId);
          foundPassport && state.removingPassports.push({ projectId: action.payload.project_id, passport: foundPassport });
          project.passports = project.passports.filter((passport: PassportElement) => passport.id !== passportId);
        }
      });
    },
    cancelRemovePassportFromProject(state): void {
      state.removingPassports.forEach((item: any) => {
        const project: ProjectWithPassportsI | undefined = state.projectWithPassports.find((projectItem: ProjectWithPassportsI) => projectItem.projectId === item.projectId);

        if (project) {
          project.passports.push(item.passport);
        }
      });

      state.removingPassports = [];
    }
  }
});

export default passportsSlice.reducer;
export const {
  putPassports,
  setRequestPassportsStatus,
  putPassportsListTotal,
  setRequestPassportStatus,
  putPassportCollection,
  setRequestMovePassports,
  setRequestCopyPassports,
  reset,
  removePassport,
  setProjectWithPassports,
  cancelRemovePassportFromProject
} = passportsSlice.actions;
