import {createSlice, SliceCaseReducers} from "@reduxjs/toolkit";

interface State {
  invitationModal: {
    isActive: boolean;
    projectId: number | undefined;
  },
  coordinates: { x: number, y: number } | null;
}

const initialState: State = {
  invitationModal: {
    isActive: false,
    projectId: undefined
  },
  coordinates: null
}

const modalsSlice = createSlice<State, SliceCaseReducers<State>>({
  name: "modals",
  initialState,
  reducers: {
    setActiveInvitation(state, action): void {
      state.invitationModal.isActive = action.payload;
    },
    setProjectId(state, action): void {
      state.invitationModal.projectId = action.payload;
    },
    setCoordinates(state, action): void {
      state.coordinates = action.payload;
    }
  }
});

export default modalsSlice.reducer;
export const {
  setActiveInvitation,
  setProjectId,
  setCoordinates
} = modalsSlice.actions;
