// Libraries
import React, {useEffect, useState} from "react";
import {Field, Form} from "react-final-form";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Interfaces
import {User} from "interfaces/index";
import {ChangeUserEmailI} from "Api";

// Assets
import {ReactComponent as SuccessIcon} from "assets/success.svg";

// Stores
import {RequestStatuses} from "store/reducers/authReducer";

// Actions
import {changeUserEmailAction} from "store/sagas/auth/actions";

// Components
import Button from "components/Button/Button";
import {Input} from "components/Form";
import ReactCodeInput from "react-verification-code-input";

// Helpers
import { requireField, checkValidEmail } from "helpers/Validator";

// Styles
import styles from "./ChangeEmailForm.scss";

const ChangeEmailForm = (): JSX.Element => {
  const [isSuccessChangeEmail, setSuccessChangeEmail] = useState<boolean>(false);
  const user: User = useAppSelector(state => state.auth.user);
  const requestChangeUserEmailStatus = useAppSelector(state => state.auth.requestChangeUserEmailStatus);
  const dispatch = useAppDispatch();
  const [isEnterCode, setIsEnterCode] = useState(false);
  const [second, setSecond] = useState(16);

  useEffect(() => {
    if (second > 0 && isEnterCode) {
      setTimeout(() => setSecond(second - 1), 1000);
    }
  }, [second, isEnterCode]);

  useEffect(() => {
    if (requestChangeUserEmailStatus === RequestStatuses.Success && second === 0) {
      setSuccessChangeEmail(true);
    }
  }, [requestChangeUserEmailStatus, second]);

  const sendCodeAgain = (values: ChangeUserEmailI): void => {
    dispatch(changeUserEmailAction({ email: values.email, code: values.code }));
  };

  return (
    isSuccessChangeEmail ? (
      <div className={styles.SuccessChangePsw}>
        <SuccessIcon />
        <span>Ваш email успешно изменен!</span>
      </div>
    ) : (
      <Form
        onSubmit={sendCodeAgain}
        render={({ handleSubmit, values }): JSX.Element => (
          <form className={styles.ChangeEmailForm} onSubmit={handleSubmit}>
            {
              isEnterCode ? (
                <div className={styles.ChangeEmailFormContainerCodeContainer}>
                  <div className={styles.ChangeEmailFormContainerCodeContainerInputs}>
                    <Field name="code" validate={requireField}>
                      {
                        ({ input }: any) => (
                          <ReactCodeInput {...input} onChange={(e) => {
                            if (e) {
                              input.onChange(e);
                            }
                          }} type="number" fields={5} />
                        )
                      }
                    </Field>
                  </div>
                  {
                    second > 0 ? (
                      <span className={styles.ChangeEmailFormContainerCodeContainerCode}>Отправить код можно через {second} сек.</span>
                    ) : (
                      <Button
                        title="Подтвердить"
                        classes={{ root: styles.ChangeEmailFormContainerCodeContainerBtn }}
                        type="submit"
                        disabled={values?.code?.length !== 5}
                      />
                    )
                  }
                </div>
              ) : (
                <div className={styles.ChangeEmailFormContainer}>
                  <div className={styles.ChangeEmailFormField}>
                    <label>Новый Email</label>
                    <Input
                      placeholder={user.email || "example@gmail.com"}
                      type="text"
                      name="email"
                      validators={[requireField, checkValidEmail]}
                    />
                  </div>
                  <Button
                    title="Далее"
                    classes={{ root: styles.ChangeEmailFormFieldBtn }}
                    type="button"
                    callback={() => {
                      dispatch(changeUserEmailAction({ email: values.email }));
                      setIsEnterCode(true);
                    }}
                  />
                </div>
              )
            }
          </form>
        )}
      />
    )
  )
};

export default ChangeEmailForm;
