import {call, put, takeLatest, delay} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {RequestStatuses} from "enums/index";
import {searchPassportsAction} from "./actions";
import {putPassports, putCategories, setRequestFetchEntitiesStatus} from "../../reducers/searchReducer";
import {searchEntities} from "../../../Api";

export function* searchEntitiesWatcher(): Generator {
  yield takeLatest(searchPassportsAction, searchEntitiesWorker);
}

function* searchEntitiesWorker({ payload }: any): Generator {
  yield put(setRequestFetchEntitiesStatus(RequestStatuses.Pending));
  
  try {
    const res = yield call(searchEntities, payload);
    yield put(putPassports((res as AxiosResponse).data.passports.data));
    yield put(putCategories((res as AxiosResponse).data.categories.data));
    yield put(setRequestFetchEntitiesStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(setRequestFetchEntitiesStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestFetchEntitiesStatus(RequestStatuses.Error));
  }
}
