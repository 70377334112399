// Libraries
import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";

// Hooks
import {useAppDispatch} from "hooks/hooks";

// Actions
import {fetchPassportAction} from "store/sagas/passports/actions";

// Components
import Page from "components/Page/Page";
import BreadCrumbs from "components/BreadCrumbs/BreadCrumbs";
import Navigation, {PagesEnum} from "./components/Navigation/Navigation";
import DesignPage from "./components/DesignPage/DesignPage";
import ImplementationPage from "./components/ImplementationPage/ImplementationPage";
import ExploitationPage from "./components/ExploitationPage/ExploitationPage";

// Styles
import styles from "./Passport.scss";

const Passport = (): JSX.Element => {
  const [page, setPage] = useState<PagesEnum>(PagesEnum.Design);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchPassportAction(id))
  }, [dispatch, id]);

  return (
    <Page>
      <section className={styles.Root}>
        <div className={styles.Container}>
          <BreadCrumbs classes={{root: styles.BreadCrumbs}} />
          <Navigation page={page} setPage={setPage} />
          {page === PagesEnum.Design && <DesignPage />}
          {page === PagesEnum.Implementation && <ImplementationPage />}
          {page === PagesEnum.Exploitation && <ExploitationPage />}
        </div>
      </section>
    </Page>
  )
};

export default Passport;
