// Libraries
import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";

// Types
import {Project} from "interfaces/index";

export enum RequestStatuses {
  Initial,
  Pending,
  Success,
  Error
}

interface State {
  projects: Project[],
  requestFetchProjects: RequestStatuses,
  projectIcons: any[],
  requestProjectIconsStatus: RequestStatuses,
  requestCreateProjectStatus: RequestStatuses,
  requestAddPassportToProjectStatus: RequestStatuses,
  requestRemovePassportFromProjectStatus: RequestStatuses,
  requestRemoveProjectStatus: RequestStatuses,
  requestChangeProjectTitleStatus: RequestStatuses
}

const projectsReducer = createSlice<State, SliceCaseReducers<State>>({
  name: "projects",
  initialState: {
    projects: [],
    requestFetchProjects: RequestStatuses.Initial,
    projectIcons: [],
    requestProjectIconsStatus: RequestStatuses.Initial,
    requestCreateProjectStatus: RequestStatuses.Initial,
    requestAddPassportToProjectStatus: RequestStatuses.Initial,
    requestRemovePassportFromProjectStatus: RequestStatuses.Initial,
    requestRemoveProjectStatus: RequestStatuses.Initial,
    requestChangeProjectTitleStatus: RequestStatuses.Initial
  },
  reducers: {
    putProjects(state, action) {
      state.projects = action.payload;
    },
    setRequestFetchProjectsStatus(state, action) {
      state.requestFetchProjects = action.payload;
    },
    putProjectIcons(state, action) {
      state.projectIcons = action.payload;
    },
    setRequestProjectIconsStatus(state, action) {
      state.requestProjectIconsStatus = action.payload;
    },
    setRequestCreateProjectStatus(state, action) {
      state.requestCreateProjectStatus = action.payload;
    },
    addProject(state, action) {
      state.projects.push(action.payload);
    },
    setRequestAddPassportToProjectStatus(state, action) {
      state.requestAddPassportToProjectStatus = action.payload;
    },
    setRequestRemovePassportFromProjectStatus(state, action) {
      state.requestRemovePassportFromProjectStatus = action.payload;
    },
    setRequestRemoveProjectStatus(state, action) {
      state.requestRemoveProjectStatus = action.payload;
    },
    removeProject(state, action) {
      state.projects = state.projects.filter((project: Project) => project.id !== action.payload);
    },
    changeProjectTitle(state, action): void {
      const foundProject: Project | undefined = state.projects.find((project: Project) => project.id === Number(action.payload.projectId));
      if (foundProject) {
        foundProject.title = action.payload.title;
      }
    },
    setRequestChangeProjectTitleStatus(state, action): void {
      state.requestChangeProjectTitleStatus = action.payload;
    }
  }
});

export default projectsReducer.reducer;
export const {
  putProjects,
  setRequestFetchProjectsStatus,
  putProjectIcons,
  setRequestProjectIconsStatus,
  setRequestCreateProjectStatus,
  addProject,
  setRequestAddPassportToProjectStatus,
  setRequestRemovePassportFromProjectStatus,
  setRequestRemoveProjectStatus,
  removeProject,
  changeProjectTitle,
  setRequestChangeProjectTitleStatus
} = projectsReducer.actions;
