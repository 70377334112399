// Libraries
import React, {useCallback, useMemo, useState} from "react";
import { v4 as uuidv4 } from "uuid";

// Hooks
import {useAppDispatch} from "hooks/hooks";

// Actions
import {removeUserFromProjectAction} from "store/sagas/invited/actions";
import {changeShowInvitedListElement} from "store/reducers/invitedReducer";

// Interfaces
import {Invited as InvitedI} from "interfaces/index";

// Assets
import { ReactComponent as CheckIcon } from "assets/check_mark.svg";
import { ReactComponent as AddInvitedIcon } from "assets/invations_add.svg";
import { ReactComponent as RemoveInvitedIcon } from "assets/remove_user.svg";

// Components
import Button from "components/Button/Button";
import FormElementCheckbox from "./FormElementCheckbox";

// Styles
import styles from "./InvitationElement.scss";
import FormSelectElement, {Pages} from "./FormSelectElement";
import {setActiveInvitation, setProjectId} from "../../../../../store/reducers/modalsReducer";

interface InvitationElementProps {
  readonly item: InvitedI;
  readonly page: Pages;
  readonly isShowListElement: boolean;
}

const InvitationElement = ({ item, page, isShowListElement }: InvitationElementProps): JSX.Element => {
  const [isShowList, setShowList] = useState<boolean>(isShowListElement);
  const [choiceUsersList, setChoiceUsersList] = useState<number[]>([]);
  const dispatch = useAppDispatch();

  const onSubmit = (values: any): void => {
    // console.log("values", values);
  };

  const choiceUserForRemove = useCallback((userId: number) => {
    const index = choiceUsersList.findIndex((user) => user === userId)
    if (index < 0) {
      setChoiceUsersList([...choiceUsersList, userId]);
    } else {
      setChoiceUsersList(choiceUsersList.slice(0, index).concat(choiceUsersList.slice(index + 1)))
    }
  }, [choiceUsersList]);

  const removeUsers = (): void => {
    if (choiceUsersList.length > 0) {
      dispatch(removeUserFromProjectAction({
        id: item.id,
        ids: choiceUsersList
      }));

      setChoiceUsersList([]);
    }
  };

  const renderUsersList = useMemo((): JSX.Element[] => {
    return item.users?.map(({ id, avatar, name, project_role }): JSX.Element => (
      <div className={styles.Invited} key={uuidv4()}>
        {
          page === Pages.MyProjects && (
            <FormElementCheckbox
              onSubmit={onSubmit}
              choiceUserForRemove={choiceUserForRemove}
              id={id}
              name={name ? null : `name-${id}`}
              choiceUsersList={choiceUsersList}
            />
          )
        }
        <img src={avatar.url} alt=""/>
        <p>{ name }</p>
        <FormSelectElement projectId={item.id} userId={id} project_role={project_role} page={page} />
      </div>
    ))
  }, [item.users, choiceUserForRemove, choiceUsersList, page, item.id]);

  return (
    <div className={styles.Root}>
      <div className={styles.TitleContainer} onClick={(): void => {
        setShowList(!isShowList);
        dispatch(changeShowInvitedListElement({ id: item.id }))
      }}>
        <img className={styles.TitleContainerImg} src={item.icon_url} alt=""/>
        <h4>{ item.title }</h4>
        <CheckIcon className={isShowList ? styles.ActiveCheckIcon : ""} />
      </div>
      {
        isShowList && page === Pages.MyProjects && (
          <div className={styles.RootActions}>
            <Button
              classes={{ root: styles.AddInvited }}
              startIcon={<AddInvitedIcon />}
              title="Пригласить еще"
              type="button"
              callback={(): void => {
                dispatch(setProjectId(item.id));
                dispatch(setActiveInvitation(true));
              }}
            />
            {
              choiceUsersList.length > 0 && (
                <Button
                  classes={{ root: styles.AddInvited }}
                  startIcon={<RemoveInvitedIcon />}
                  title={`Удалить: ${choiceUsersList.length}`}
                  type="button"
                  callback={(): void => removeUsers()}
                />
              )
            }
          </div>
        )
      }
      {
        isShowList && renderUsersList
      }
    </div>
  )
};

export default InvitationElement;
