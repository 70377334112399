// Libraries
import React, {useEffect} from "react";
import {Form} from "react-final-form";
import {useParams} from "react-router-dom";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Store
import {RequestStatuses, reset as resetPassports} from "store/reducers/passportsReducer";
import {reset, setFilterApplicationArea, setFilterComplexityValue, setFilterCostValue, setFilterLifeTime} from "store/reducers/filtrationReducer";

// Components
import ApplicationArea, {
  ApplicationAreaFilterElement,
  Filters
} from "components/Filtration/components/ApplicationArea/ApplicationArea";
import ComplexityInstallation
  from "components/Filtration/components/ComplexityInstallation/ComplexityInstallation";
import Cost from "components/Filtration/components/Cost/Cost";
import {Input} from "components/Form";
import UsuallyModal from "components/ModalWindows/UsuallyModal/UsuallyModal";

// Styles
import styles from "../../MyProjects.scss";

interface LifeTimeValues {
  readonly lifetime_start: string;
  readonly lifetime_end: string;
}

interface FilterPopupProps {
  readonly setPage: (value: number) => void;
  readonly isShowModal: boolean;
  readonly setIsShowModal: (value: boolean) => void;
}

const FilterPopup = (
  {
    setPage,
    isShowModal,
    setIsShowModal
  }: FilterPopupProps): JSX.Element => {
  const requestPassportsStatus = useAppSelector(state => state.passports.requestPassportsStatus);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  /* фильтр по областям применения */
  const filterApplicationArea = useAppSelector(state => state.filtration.filterApplicationArea);
  /* фильтры по срокам эксплуатации */
  const filterLifeTime = useAppSelector(state => state.filtration.filterLifeTime);
  /* фильт сложности */
  const filterComplexityValue = useAppSelector(state => state.filtration.filterComplexityValue);
  /* фильтр стоимости */
  const filterCostValue = useAppSelector(state => state.filtration.filterCostValue);

  const resetFilters = (): void => {
    setPage(1);
    dispatch(reset({}));
  };

  useEffect(resetFilters, [id]);

  const putApplicationAreaValue = (value: number): void => {
    if (requestPassportsStatus !== RequestStatuses.Pending) {
      dispatch(resetPassports({}));

      const index = filterApplicationArea.findIndex((item: number): boolean => item === value);

      if (index < 0) {
        dispatch(setFilterApplicationArea([...filterApplicationArea, value]));
      } else {
        dispatch(setFilterApplicationArea(filterApplicationArea.slice(0, index).concat(filterApplicationArea.slice(Number(index) + 1))));
      }
    }
  };

  const onSubmit = (values: LifeTimeValues): void => {
    dispatch(resetPassports({}));
    dispatch(setFilterLifeTime({
      lifeTimeStart: values.lifetime_start,
      lifeTimeEnd: values.lifetime_end
    }));
  };

  const setCostFilter = (value: number): void => {
    dispatch(resetPassports({}));
    dispatch(setFilterCostValue(value));
  };

  const setComplexityFilter = (value: number): void => {
    dispatch(resetPassports({}));
    dispatch(setFilterComplexityValue(value));
  };

  return (
    <UsuallyModal
      autoPosition={false}
      rightPosition={165}
      topPosition={25}
      maxWidth={196}
      isShowModal={isShowModal}
      setShowModal={setIsShowModal}
      classes={{ root: styles.ModalRoot, content: styles.ModalContent }}
    >
      <>
        <div className={styles.FiltrationApplicationArea}>
          <span>Область применения</span>
          <ApplicationArea hasTitle={false}>
            <div onClick={(): void => putApplicationAreaValue(2)}>
              <ApplicationAreaFilterElement title="Площади" category={Filters.Fountain} isActive={!!filterApplicationArea.find((item: number) => item === 2)} />
            </div>
            <div onClick={(): void => putApplicationAreaValue(1)}>
              <ApplicationAreaFilterElement title="Дворы" category={Filters.Сourtyard} isActive={!!filterApplicationArea.find((item: number) => item === 1)} />
            </div>
            <div onClick={(): void => putApplicationAreaValue(5)}>
              <ApplicationAreaFilterElement title="Набережные" category={Filters.Embankment} isActive={!!filterApplicationArea.find((item: number) => item === 5)} />
            </div>
            <div onClick={(): void => putApplicationAreaValue(3)}>
              <ApplicationAreaFilterElement title="Улицы" category={Filters.Street} isActive={!!filterApplicationArea.find((item: number) => item === 3)} />
            </div>
            <div onClick={(): void => putApplicationAreaValue(6)}>
              <ApplicationAreaFilterElement title="Фасады" category={Filters.Facade} isActive={!!filterApplicationArea.find((item: number) => item === 6)} />
            </div>
            <div onClick={(): void => putApplicationAreaValue(4)}>
              <ApplicationAreaFilterElement title="Парки" category={Filters.Park} isActive={!!filterApplicationArea.find((item: number) => item === 4)} />
            </div>
          </ApplicationArea>
        </div>

        <div className={styles.HandbookFiltrationContainer}>
          <span>Сложность монтажа</span>
          <ComplexityInstallation
            hasTitle={false}
            setComplexityFilter={requestPassportsStatus === RequestStatuses.Pending ? undefined : setComplexityFilter}
            complexity={filterComplexityValue}
          />
        </div>

        <div className={styles.HandbookFiltrationContainer}>
          <span>Стоимость</span>
          <Cost hasTitle={false} setCostFilter={requestPassportsStatus === RequestStatuses.Pending ? undefined : setCostFilter} cost={filterCostValue} />
        </div>

        <div className={styles.FilterElementLifeTime}>
          <span>Срок эксплуатации, год</span>
          <div className={styles.FilterElementInputContainer}>
            <Form
              onSubmit={onSubmit}
              // validate={(values): any => {
              //   const errors: any = {};
              //
              //   if (!values.lifetime_end) {
              //     errors.lifetime_end = "Обязательное поле"
              //   }
              //
              //   if (isNaN(Number(values.lifetime_end)) && values.lifetime_end) {
              //     errors.lifetime_end = "Неверный формат"
              //   }
              //
              //   return errors;
              // }}
              render={({ handleSubmit, form }): JSX.Element => (
                <form onChange={() => handleSubmit()} className={styles.FilterElementLifeTimeForm} onSubmit={handleSubmit}>
                  <Input
                    name="lifetime_start"
                    type="text"
                    classes={{ root: styles.FilterElementInput }}
                    placeholder="от"
                    initialValue={filterLifeTime?.lifeTimeStart === "0" ? "" : filterLifeTime?.lifeTimeStart}
                  />
                  <Input
                    name="lifetime_end"
                    type="text"
                    classes={{ root: styles.FilterElementInput }}
                    placeholder="до"
                    initialValue={filterLifeTime?.lifeTimeEnd}
                  />
                  <p onClick={() => {
                    form.reset();
                    resetFilters();
                  }}
                     className={styles.ResetFilter}>
                    Сбросить
                  </p>
                </form>
              )}
            />
          </div>
        </div>
      </>
    </UsuallyModal>
  )
};

export default FilterPopup;
