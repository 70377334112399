// Libraries
import React from "react";
import Cookies from "js-cookie";

// Components
import Button from "components/Button/Button";

// Assets
import { ReactComponent as WormIcon } from "assets/worm.svg";

// Styles
import styles from "./CookiePopup.scss";

interface CookiePopupProps {
  readonly setIsShowCookiePopup: (value: boolean) => void;
}

const CookiePopup = ({ setIsShowCookiePopup }: CookiePopupProps): JSX.Element => {
  const setAgreeWormCookie = (): void => {
    Cookies.set("axp-agree-worm", "1");
    setIsShowCookiePopup(false);
  };

  return (
    <div className={styles.Root}>
      <p className={styles.RootTitle}>
        Мы используем куки на нашем сайте, чтобы все работало четко и без проблем
      </p>
      <WormIcon className={styles.RootWorm} />
      <Button
        title="Понятно"
        classes={{ root: styles.RootBtn }}
        type="button"
        callback={setAgreeWormCookie}
      />
    </div>
  )
};

export default CookiePopup;
