// Libraries
import React, { useState } from "react";
import {Link} from "react-router-dom";
import {v4 as uuidv4} from "uuid";

// Interfaces
import {CategoryElement, LevelElement, PassportElement} from "interfaces/index";

// Assets
import { ReactComponent as ArrowDownIcon } from "assets/arrow_down.svg";

// Routes
import routes from "routes";

// Styles
import styles from "../Header.scss";

interface MobileCategoryProps {
  readonly category: CategoryElement;
  readonly level: LevelElement;
}

const MobileCategory = ({ level, category }: MobileCategoryProps): JSX.Element => {
  const [isActiveSection, setIsActiveSection] = useState(false);

  return (
    <>
      <div data-active={isActiveSection} className={styles.RootSectionsMobileContainerCategoriesContainer}>
        <Link
          className={styles.RootSectionsMobileContainerCategoriesContainerCategory}
          key={uuidv4()}
          to={routes.handbookWithCategoryId(level.id, category.id)}
          onClick={(): void => setIsActiveSection(false)}
        >
          { category.title }
        </Link>
        <ArrowDownIcon onClick={(): void => setIsActiveSection(!isActiveSection)} />
      </div>
      <div className={styles.RootSectionsMobileContainerCategoriesContainerPassport}>
        {
          isActiveSection && category.passports?.map((passport: PassportElement, i): JSX.Element => (
            <Link
              to={routes.passport(passport.id)}
              key={uuidv4()}
              style={{ marginBottom: i === category.passports.length - 1 ? "34px" : "0px" }}
              // onClick={(): void => setActiveSection(false)}
            >
              { passport.title }
            </Link>
          ))
        }
      </div>
    </>
  )
};

export default MobileCategory;
