// Libraries
import React, { Children, Fragment } from "react";
import classNames from "classnames";

// Components
import FilterElement from "../FilterElement/FilterElement";

// Styles
import styles from "./ApplicationArea.scss";

interface ApplicationAreaProps {
  readonly classes?: {
    readonly root?: string;
    readonly title?: string;
  };
  readonly hasTitle?: boolean;
  readonly children: React.ReactChild[];
}

interface FilterElementProps {
  readonly hasDefaultIcon?: boolean;
  readonly isActive: boolean;
  readonly category: string;
  readonly title?: string;
}

export enum Filters {
  Fountain = "squares",
  Сourtyard = "yard",
  Embankment = "embankments",
  Street = "streets",
  Facade = "facades",
  Park = "parks"
}

export const ApplicationAreaFilterElement = ({ hasDefaultIcon = true, isActive, category, title = "" }: FilterElementProps): JSX.Element => (
  <FilterElement
    classes={{
      default: classNames(styles[`FilterElement${category}`], !hasDefaultIcon &&  styles[`FilterElement${category}Active`]),
      activeIcon: isActive ?  styles[`FilterElement${category}Active`] : "",
      active: isActive ? styles[`FilterElement${category}Active`] : ""
    }}
    isActiveFilter
    title={title}
  />
);

const ApplicationArea = ({ classes = {}, hasTitle = true, children }: ApplicationAreaProps): JSX.Element => {
  return (
    <div className={classNames(styles.FilterElementApplicationArea, classes?.root)}>
      { hasTitle && <span className={classes?.title}>Область применения</span> }
      <div className={styles.FilterElementApplicationAreaContent}>
        {
          Children.map(children, (child, i): JSX.Element =>
            (
              <Fragment key={`child-${i}`}>
                { child }
              </Fragment>
            )
          )
        }
      </div>
    </div>
  )
};

export default ApplicationArea;
