import {call, put, takeLatest, takeEvery, delay} from "redux-saga/effects";
import {
  putInvited,
  removeUsersFromProject, setRequestChangeUserRoleOnProjectStatus,
  setRequestFetchInvitedStatus,
  setRequestInviteUserToProjectStatus,
  setRequestRemoveUserFromProjectStatus,
  changeUserRoleOnProject as changeUserRoleOnProjectReducer,
  setInvitedTotal,
  putUser
} from "store/reducers/invitedReducer";
import {RequestStatuses} from "enums/index";
import {AxiosResponse} from "axios";
import {
  changeUserRoleOnProjectAction,
  fetchInvitedActions,
  inviteUserToProjectActions,
  removeUserFromProjectAction
} from "./actions";
import {changeUserRoleOnProject, fetchInvitedPeople, inviteUserToProject, removeUserFromProject} from "../../../Api";

export function* invitedSagaWatcher(): Generator {
  yield takeLatest(fetchInvitedActions, fetchInvitedWorker);
  yield takeEvery(inviteUserToProjectActions, inviteUserToProjectWorker);
  yield takeLatest(removeUserFromProjectAction, removeUserFromProjectWorker);
  yield takeLatest(changeUserRoleOnProjectAction, changeUserRoleOnProjectWorker);
} 

function* fetchInvitedWorker({ payload }: any): Generator {
  yield put(setRequestFetchInvitedStatus(RequestStatuses.Pending));

  try {
    const res = yield call(fetchInvitedPeople, payload);
    yield put(putInvited((res as AxiosResponse).data.data));
    yield put(setInvitedTotal((res as AxiosResponse).data.total))
    yield put(setRequestFetchInvitedStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(setRequestFetchInvitedStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestFetchInvitedStatus(RequestStatuses.Error));
  }
}

function* inviteUserToProjectWorker({ payload }: any): Generator {
  yield put(setRequestInviteUserToProjectStatus(RequestStatuses.Pending));

  try {
    const res = yield call(inviteUserToProject, payload);
    const user = (res as AxiosResponse).data;

    if (user?.role) {
      user.project_role = {
        id: payload.role.id,
        title: payload.role.label
      };
      yield put(putUser({ id: payload.id, user }));
    }

    yield put(setRequestInviteUserToProjectStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(setRequestInviteUserToProjectStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestInviteUserToProjectStatus(RequestStatuses.Error));
  }
}

function* removeUserFromProjectWorker({ payload }: any): Generator {
  yield put(setRequestRemoveUserFromProjectStatus(RequestStatuses.Pending));

  try {
    yield call(removeUserFromProject, payload);
    yield put(removeUsersFromProject(payload));
    yield put(setRequestRemoveUserFromProjectStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(setRequestRemoveUserFromProjectStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestRemoveUserFromProjectStatus(RequestStatuses.Error));
  }
}

function* changeUserRoleOnProjectWorker({ payload }: any): Generator {
  yield put(setRequestChangeUserRoleOnProjectStatus(RequestStatuses.Pending));

  try {
    yield call(changeUserRoleOnProject, payload);
    yield put(changeUserRoleOnProjectReducer(payload));
    yield put(setRequestChangeUserRoleOnProjectStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(setRequestChangeUserRoleOnProjectStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestChangeUserRoleOnProjectStatus(RequestStatuses.Pending));
  }
}
