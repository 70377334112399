import {createAction} from "@reduxjs/toolkit";

export interface FetchPassportsParams {
  readonly page: number;
  readonly per_page: number;
  readonly category?: number;
  readonly price?: number;
  readonly complexity?: number;
  readonly application_area?: string;
  readonly exploitation_years?: {
    readonly start_at: string | number;
    readonly end_at?: string | number;
  }
  readonly project?: number;
}

interface FetchPassportParams {
  readonly id: string | number;
}

export interface MovePassportsValue {
  readonly old_project_id: number;
  readonly new_project_id: number;
  readonly passport_id: number[];
}

export const fetchPassportsAction = createAction<FetchPassportsParams>("GET/GET_PASSPORTS");
export const fetchProjectWithPassportsAction = createAction<FetchPassportsParams>("GET/GET_PROJECT_WITH_PASSPORTS");
export const fetchPassportAction = createAction<FetchPassportParams>("GET/GET_PASSPORT");
export const movePassportsAction = createAction<MovePassportsValue>("POST/MOVE_PASSPORTS");
export const copyPassportsAction = createAction<MovePassportsValue>("POST/COPY_PASSPORTS");
