// Libraries
import React, {useEffect} from "react";
import {Field, Form} from "react-final-form";
import classNames from "classnames";
import ru from "date-fns/locale/ru";
import DatePicker from "react-datepicker";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Interfaces
import {City, User} from "interfaces/index";

// Actions
import {changeUserInfoAction, ChangeUserInfoParams, fetchCityListAction} from "store/sagas/auth/actions";
// Components
import Select from "components/Form/components/Select/Select";
import {Input} from "components/Form";
import { ReactComponent as CalendarIcon } from "assets/calendar.svg";

// Styles
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import styles from "./AdditionalInfoForm.scss";

const sexOptions = [
  { id: 2, label: "Женский", value: "Женский" },
  { id: 1, label: "Мужской", value: "Мужской" }
];

const AdditionalInfoForm = (): JSX.Element => {
  const user: User = useAppSelector((state) => state.auth.user);
  const cityList: City[] = useAppSelector(state => state.auth.cityList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCityListAction());
  }, [dispatch]);

  const onSubmit = (values: any): void => {
    const params: ChangeUserInfoParams = {
      ...values
    };

    if (values.sex) {
      params.gender_id = values.sex.id;
    }

    if (values.city) {
      params.city_id = values.city.id;
    }

    if (values.date) {
      params.birthday_at = values.date;
    }

    if (Object.keys(params).length > 0) {
      dispatch(changeUserInfoAction(params));
    }
  };

  return (
    <div className={styles.AdditionalInfo}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }): JSX.Element => (
          <form onBlur={() => handleSubmit()} className={styles.Form} onSubmit={handleSubmit}>
            <div className={styles.FormContainer}>
              <div className={styles.FormContainerField}>
                <label>Имя</label>
                <Input
                  name="name"
                  type="text"
                  placeholder={user.name || ""}
                />
              </div>
              <div className={styles.FormContainerField}>
                <label>Фамилия</label>
                <Input
                  name="surname"
                  type="text"
                  placeholder={user.surname || ""}
                />
              </div>
              <div className={styles.FormContainerField}>
                <label>Отчество</label>
                <Input
                  name="patronymic"
                  type="text"
                  placeholder={user.patronymic || ""}
                />
              </div>
            </div>
            <div className={styles.FormContainer}>
              <div className={styles.FormContainerSelectField}>
                <label>Пол</label>
                <Field
                  name="sex"
                >
                  {props => (
                    <Select
                      placeholder="Пол"
                      classes={{ root: styles.SexSelect }}
                      label="Пол"
                      {...props}
                      defaultValue={user && { id: user?.gender?.id, label: user?.gender?.full_name, value: user?.gender?.full_name }}
                      options={sexOptions}
                    />
                  )}
                </Field>
              </div>
              <div className={classNames(
                styles.FormContainerField,
                styles.FormCalendar
              )}>
                <label>День рождения</label>
                <div className={styles.CalendarContainer}>
                  <CalendarIcon />
                  <Field
                    name="date"
                    type="date"
                    dateFormat="dd.MM.yyyy"
                    showYearDropdown
                    locale={ru}
                    showMonthDropdown
                    calendarClassName={styles.Calendar}
                    placeholderText="дд.мм.гггг"
                  >
                    {({ input: { onChange, value }, ...rest }) => {
                      return <DatePicker onCalendarClose={() => handleSubmit()} selected={user?.birthday_at ? new Date(user.birthday_at) : undefined} onChange={(date) => onChange(date)} {...rest} />
                    }}
                  </Field>
                </div>
              </div>
              <div className={styles.FormContainerSelectField}>
                <label>Город</label>
                <Field
                  name="city"
                >
                  {props => (
                    <Select
                      placeholder="Город"
                      classes={{ root: styles.CitySelect }}
                      label="Город"
                      {...props}
                      defaultValue={{ id: user.city.id, label: user.city.title, value: user.city.title }}
                      options={cityList?.map((city) => { return { id: city.id, label: city.title, value: city.title }})}
                    />
                  )}
                </Field>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  )
};

export default AdditionalInfoForm;
