// Libraries
import {createSlice, SliceCaseReducers} from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

// Types
import {ErrorElement} from "interfaces/index";

interface State {
  errors: ErrorElement[]
}

const initialState: State = {
  errors: []
}

const errorSlice = createSlice<State, SliceCaseReducers<State>>({
  name: "errors",
  initialState,
  reducers: {
    setError(state, action): void {
      const errors: ErrorElement[] = action.payload.data.errors;
      const code: number = action.payload.data.code;

      if (Array.isArray(errors) && code && errors?.length > 0) {
        errors.forEach((error) => {
          state.errors.push({
            id: uuidv4(),
            code,
            text: error.text,
            entity: action.payload.entity,
            isShow: false
          });
        });
      }
    },
    removeError(state, action): void {
      state.errors = state.errors.filter((error) => error.id !== action.payload);
    },
    reset: (): State => initialState,
    setIsShow(state, action): void {
      const errorItem: ErrorElement | undefined = state.errors.find((error: ErrorElement) => error.id === action.payload.id);

      if (errorItem) {
        errorItem.isShow = action.payload.isShow;
      }
    }
  }
});

export default errorSlice.reducer;
export const {
  setError,
  reset,
  removeError,
  setIsShow
} = errorSlice.actions;
