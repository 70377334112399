// Libraries
import React, {useEffect, useState} from "react";

// Hooks
import {useAppDispatch} from "hooks/hooks";

// Actions
import {addPassportToProjectAction, removePassportFromProjectAction} from "store/sagas/projects/actions";

// Interfaces
import {Project} from "interfaces/index";

// Assets
import {ReactComponent as PlusIcon} from "assets/plus_red_no_fon.svg";
import {ReactComponent as PadlockIcon} from "assets/padlock.svg";
import {ReactComponent as CheckIcon} from "assets/check_mark_2.svg";

// Styles
import styles from "../ModalWithListProjects.scss";

interface ListElementProps {
  readonly element: Project;
  readonly passportId: number;
}

const ListElement = ({ element, passportId }: ListElementProps): JSX.Element => {
  const [wasAdd, setAdd] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setAdd(element.passport_added as boolean);
  }, [element])

  const addPassportToProject = (): void => {
    const params = {
      id: element.id,
      passport_id: passportId
    };

    dispatch(addPassportToProjectAction(params));
    setAdd(true);
  };

  const removePassportFromProject = (): void => {
    const params = {
      project_id: element.id,
      passport_id: passportId
    };

    dispatch(removePassportFromProjectAction(params));
    setAdd(false);
  };

  return (
    <div className={styles.CardModalWindowElement}>
      <div className={styles.CardModalWindowElementProjectIcon}>
        { element.is_favorite ? <PadlockIcon /> : <img className={styles.CardModalWindowElementImgIcon} src={element.icon_url} alt=""/> }
      </div>
      <span title={element.title}>{ element.title }</span>
      <div className={styles.CardModalWindowElementAddIcon} style={{ display: wasAdd ? "initial" : "none" }}>
        <CheckIcon onClick={(): void => removePassportFromProject()} />
      </div>
      <div className={styles.CardModalWindowElementAddIcon} style={{ display: wasAdd ? "none" : "initial" }}>
        <PlusIcon onClick={(): void => addPassportToProject()} />
      </div>
    </div>
  )
};

export default ListElement;
