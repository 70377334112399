export enum ModalTypes {
  Login,
  Registration,
  RecoverySendEmailPsw,
  RecoveryPsw,
  Confirm,
  SuccessRecoveryPsw,
  SuccessRegistration,
  EnterCode
}

export enum BtnTypes {
  password = "password",
  text = "text",
  submit = "submit"
}

export enum MyProjectsViewMode {
  CommonMode,
  EditMode,
  HistoryMode
}

export enum ProjectRoles {
  Owner = "owner",
  Invited = "invited"
}

export enum RequestStatuses {
  Initial,
  Pending,
  Success,
  Error
}

export enum VariantNotifications {
  History,
  Notification
}

export enum VariantSectionModeMobile {
  Levels,
  Categories
}

export enum VariantActionWithAuth {
  Login,
  Registration,
  ResetPassword,
  Empty
}
