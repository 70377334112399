// Libraries
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {Field, Form} from "react-final-form";
import { useAlert } from "react-alert";

// Routes
import routes from "routes";

// Interfaces
import {ErrorElement, Project, User} from "interfaces/index";

// Components
import Page from "components/Page/Page";

// Assets
import {ReactComponent as PersonalIcon} from "assets/personal_data.svg";
import {ReactComponent as ProjectIcon} from "assets/document_black.svg";
import {ReactComponent as NotificationIcon} from "assets/notification_black.svg";
import {ReactComponent as GuestIcon} from "assets/guests.svg";
import {ReactComponent as SettingIcon} from "assets/settings.svg";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Actions
import {getUserInfoAction, uploadAvatarAction} from "store/sagas/auth/actions";
import {removeError, setIsShow} from "store/reducers/errorsReducer";

// Styles
import breakpoints from "styles/breakpoints.scss";
import styles from "./PrivateCabinet.scss";


interface PrivateCabinetProps {
  readonly children: React.ReactChild;
}

const PrivateCabinet = ({ children }: PrivateCabinetProps): JSX.Element => {
  const { pathname } = useLocation();
  const user: User = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const defaultProject: Project = useAppSelector(state => state.projects.projects.find((project: Project) => project.is_favorite === true));
  const [isUserBlockOpen, setUserBlockOpen] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const alert = useAlert();
  const errors: ErrorElement[] = useAppSelector(state => state.errors.errors.filter((error: ErrorElement) => !error.isShow));

  useEffect(() => {
    if (errors.length > 0) {
      errors.forEach((error) => {
        alert.error(`${error.text} code=${error.code}`, {
          timeout: 60000,
          onOpen() {
            dispatch(setIsShow({
              id: error.id,
              isShow: true
            }));
          },
          onClose() {
            dispatch(removeError(error.id));
          }
        });
      });
    }
  }, [alert, errors, dispatch]);

  useEffect(() => {
    !user && dispatch(getUserInfoAction());
  }, [dispatch, user]);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= parseInt(breakpoints.XS, 10));
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSubmit = (values: any): void => {
    const payload = new FormData();
    payload.append("avatar", values.avatar[0]);
    dispatch(uploadAvatarAction(payload));
  };

  const links = {
    [routes.personalData]: {
      icon: PersonalIcon,
      title: "Личные данные"
    },
    [routes.project(defaultProject?.id)]: {
      icon: ProjectIcon,
      title: "Проекты"
    },
    [routes.notifications]: {
      icon: NotificationIcon,
      title: "Уведомления"
    },
    [routes.invitations]: {
      icon: GuestIcon,
      title: "Приглашения"
    },
    [routes.settings]: {
      icon: SettingIcon,
      title: "Настройки"
    },
  };

  const selected = links[pathname] || links[routes.personalData];

  const renderNavigation = () => (
    <>
      <div className={styles.NavigationSelector} data-active={isUserBlockOpen} onClick={() => setUserBlockOpen(!isUserBlockOpen)}>
        <selected.icon />
        {selected.title}
      </div>
      <div className={styles.NavigationBlocks} data-open={isUserBlockOpen}>
        <div className={styles.NavigationBlock}>
          <nav>
            <NavLink to={routes.personalData} activeClassName={styles.ActiveLink}>
              <PersonalIcon />
              Личные данные
            </NavLink>
            <NavLink to={routes.project(defaultProject?.id)} isActive={(match: any, location: any): boolean => {
              return location.pathname.includes("/private-cabinet/projects");
            }} activeClassName={styles.ActiveLink}>
              <ProjectIcon />
              Проекты
            </NavLink>
            <NavLink to={routes.notifications} activeClassName={styles.ActiveLink}>
              <NotificationIcon />
              Уведомления
            </NavLink>
            <NavLink to={routes.invitations} activeClassName={styles.ActiveLink}>
              <GuestIcon />
              Приглашения
            </NavLink>
          </nav>
        </div>
        <div className={styles.SettingBlock}>
          <nav>
            <NavLink to={routes.settings} activeClassName={styles.ActiveLink}>
              <SettingIcon />
              Настройки
            </NavLink>
          </nav>
        </div>
      </div>
    </>
  );

  return (
    <Page>
      <section className={styles.Root}>
        { isMobile && renderNavigation() }
        <div className={styles.Container}>
          { user && (
            <>
              <div className={styles.UserBlock} data-open={isUserBlockOpen}>
                <div 
                  className={styles.UserBlockTrigger} 
                  data-active={isUserBlockOpen}
                  onClick={() => setUserBlockOpen(!isUserBlockOpen)}
                />
                <div className={styles.AvatarBlock}>
                  <img src={user.avatar.url} alt=""/>
                  <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit }): JSX.Element => (
                      <form onChange={() => handleSubmit()} className={styles.AvatarBlockChangeAvatar} onSubmit={handleSubmit}>
                        <span />
                        <Field name="avatar">
                          {({ input: { value, onChange, ...input } }) => (
                            <input
                              {...input}
                              type="file"
                              onClick={(e) => e.stopPropagation()}
                              onChange={({ target }) => onChange(target.files)}
                            />
                          )}
                        </Field>
                      </form>
                    )}
                  />
                  <span className={styles.UserName}>{ user.name }</span>
                </div>
                { !isMobile && renderNavigation() }
              </div>
              <div className={styles.ContentBlock}>
                {children}
              </div>
            </>
          )}
        </div>
      </section>
    </Page>
  )
}

export default PrivateCabinet;
