// Libraries
import React, { useState } from "react";
import classNames from "classnames";

interface FilterProps {
  readonly title: string;
  readonly classes: {
    readonly active: string;
    readonly default: string;
    readonly activeIcon: string;
  };
  readonly isElementActive?: boolean;
  readonly isActiveFilter?: boolean;
  readonly setValueFilter?: (value: number) => void;
  readonly value?: number;
}

const FilterElement = (
  {
    title,
    classes,
    isElementActive,
    isActiveFilter,
    setValueFilter = (): void => {},
    value = 0
  }: FilterProps): JSX.Element => {
  const [isActive, setActive] = useState(isElementActive);
  return (
    <div onClick={(): void => setValueFilter(value)}>
      <span
        className={classNames(classes.active, classes.default, [
          {[classes.activeIcon]: isActive}
        ])}
        onClick={(): void => {
          isActiveFilter && setActive(!isActive);
        }}
        data-title={title}
      />
    </div>
  );
};

export default FilterElement;
