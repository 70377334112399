import {call, delay, put, takeLatest} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import { removePassport } from "store/reducers/passportsReducer";
import {
  addPassportToProjectAction,
  changeProjectTitleAction,
  createProjectAction,
  fetchProjectIconsAction,
  fetchProjectsAction,
  removePassportFromProjectAction,
  removeProjectAction
} from "./actions";
import {
  addProject,
  putProjectIcons,
  putProjects,
  RequestStatuses,
  setRequestAddPassportToProjectStatus,
  setRequestCreateProjectStatus,
  setRequestFetchProjectsStatus,
  setRequestProjectIconsStatus,
  setRequestRemovePassportFromProjectStatus,
  setRequestRemoveProjectStatus,
  removeProject as removeProjectElement,
  setRequestChangeProjectTitleStatus,
  changeProjectTitle
} from "../../reducers/projectsReducer";
import {
  addPassportToProject,
  changeProjectTitle as changeProjectTitleApi,
  ChangeProjectTitleParams,
  createProject,
  fetchProjectIcons,
  fetchProjects,
  removePassportFromProject,
  removeProject
} from "../../../Api";
import {setError} from "../../reducers/errorsReducer";

export function* projectsSagaWatcher(): Generator {
  yield takeLatest(fetchProjectsAction, fetchProjectsWorker);
  yield takeLatest(fetchProjectIconsAction, fetchProjectIconsWorker);
  yield takeLatest(createProjectAction, createProjectWorker);
  yield takeLatest(addPassportToProjectAction ,addPassportToProjectWorker);
  yield takeLatest(removePassportFromProjectAction, removePassportFromProjectWorker);
  yield takeLatest(removeProjectAction, removeProjectWorker);
  yield takeLatest(changeProjectTitleAction, changeProjectTitleWorker);
} 

function* fetchProjectsWorker({ payload }: any): Generator {
  yield put(setRequestFetchProjectsStatus(RequestStatuses.Pending));
  
  try {
    const res = yield call(fetchProjects, payload);
    yield put(putProjects((res as AxiosResponse).data));
    yield put(setRequestFetchProjectsStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(setRequestFetchProjectsStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestFetchProjectsStatus(RequestStatuses.Error));
  }
}

function* fetchProjectIconsWorker(): Generator {
  yield put(setRequestProjectIconsStatus(RequestStatuses.Pending));

  try {
    const res = yield call(fetchProjectIcons);
    yield put(putProjectIcons((res as AxiosResponse).data));
    yield put(setRequestProjectIconsStatus(RequestStatuses.Success));
    yield put(setRequestProjectIconsStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestProjectIconsStatus(RequestStatuses.Error));
  }
}

function* createProjectWorker({ payload }: any): Generator {
  yield put(setRequestCreateProjectStatus(RequestStatuses.Pending));

  try {
    const res = yield call(createProject, payload);
    yield put(addProject((res as AxiosResponse).data));
    yield put(setRequestCreateProjectStatus(RequestStatuses.Success));
    yield put(setRequestCreateProjectStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestCreateProjectStatus(RequestStatuses.Error));
  }
}

function* addPassportToProjectWorker({ payload }: any): Generator {
  yield put(setRequestAddPassportToProjectStatus(RequestStatuses.Pending));

  try {
    yield call(addPassportToProject, payload);
    yield put(setRequestAddPassportToProjectStatus(RequestStatuses.Success));
    yield put(setRequestAddPassportToProjectStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestAddPassportToProjectStatus(RequestStatuses.Error));
  }
}

function* removePassportFromProjectWorker({ payload }: any): Generator {
  yield put(setRequestRemovePassportFromProjectStatus(RequestStatuses.Pending));

  try {
    yield call(removePassportFromProject, payload);
    yield put(removePassport(payload));
    yield put(setRequestRemovePassportFromProjectStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(setRequestRemovePassportFromProjectStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestRemovePassportFromProjectStatus(RequestStatuses.Error));
    yield put(setError({
      data: e.response.data,
      entity: payload
    }));
  }
}

function* removeProjectWorker({ payload }: any): Generator {
  yield put(setRequestRemoveProjectStatus(RequestStatuses.Pending));

  try {
    yield call(removeProject, payload);
    yield put(removeProjectElement(payload));
    yield put(setRequestRemoveProjectStatus(RequestStatuses.Success));
    yield put(setRequestRemoveProjectStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestRemoveProjectStatus(RequestStatuses.Error));
  }
}

interface ChangeProjectTitlePayload {
  readonly payload: ChangeProjectTitleParams;
}
function* changeProjectTitleWorker({ payload }: ChangeProjectTitlePayload): Generator {
  yield put(setRequestChangeProjectTitleStatus(RequestStatuses.Pending));

  try {
    yield call(changeProjectTitleApi, payload);
    yield put(changeProjectTitle(payload));
    yield put(setRequestChangeProjectTitleStatus(RequestStatuses.Success));
    yield put(setRequestChangeProjectTitleStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestChangeProjectTitleStatus(RequestStatuses.Error));
  }
}
