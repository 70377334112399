import axios, { AxiosPromise } from "axios";
import Cookies from "js-cookie";
import {FetchPassportsParams, MovePassportsValue} from "store/sagas/passports/actions";
import {RegisterFormValues, SearchEntitiesParams} from "./interfaces";

const host = process?.env?.NODE_ENV === "development" ? "https://api.goodatlas.ru/api" : "https://api.goodatlas.ru/api";

export function registerUser(values: RegisterFormValues): AxiosPromise {
  return axios.post(`${host}/register`, values);
}

export function authUser(values: RegisterFormValues): AxiosPromise {
  return axios.post(`${host}/login`, values);
}

export function logoutUser(): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/logout`, {}, {
    headers: {
      "Authorization": `Bearer ${user.token}`,
      "Content-Type": "application/json",
      "Accept": "*/*"
    }
  });
}

export function fetchUserInfo(): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.get(`${host}/auth-user`, { headers: {
      "Authorization": `Bearer ${user.token}`,
      "Content-Type": "application/json",
      "Accept": "*/*"
  }});
}

export function resetPasswordApi(values: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/auth-user/change-password`, values, {
    headers: {
      "Authorization": `Bearer ${user.token}`,
      "Content-Type": "application/json",
      "Accept": "*/*"
    }
  })
}

export function fetchLickbessList(params: any): AxiosPromise {
  return axios.get(`${host}/useful`, {
    params,
    headers: {
      "Content-Type": "application/json",
      "Accept": "*/*"
    }
  });
}

export function fetchLickbess(id: number): AxiosPromise {
  return axios.get(`${host}/useful/${id}`, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "*/*"
    }
  });
}

export function fetchPassports(params: FetchPassportsParams): AxiosPromise {
  return axios.get(`${host}/passport`, {
    params,
    headers: {
      "Content-Type": "application/json",
      "Accept": "*/*"
    }
  });
}

export function fetchNavigationLevels(): AxiosPromise {
  return axios.get(`${host}/navigation/level?with_passports=true`, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "*/*"
    }
  });
}

export function fetchNavigationCategories(): AxiosPromise {
  return axios.get(`${host}/dictionary/category`, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "*/*"
    }
  });
}

export function fetchPassport(id: number | string): AxiosPromise {
  return axios.get(`${host}/passport/${id}`, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "*/*"
    }
  });
}

export function changeSettings(setting: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/auth-user/change-settings`, { settings: {...setting} }, {
    headers: {
      "Authorization": `Bearer ${user.token}`,
      "Content-Type": "application/json",
    }
  })
}

export function removeUserAccount(): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.delete(`${host}/auth-user/force-delete`, {
    headers: {
      "Authorization": `Bearer ${user.token}`,
      "Content-Type": "application/json",
    }
  });
}

export function changeUserInfo(params: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.put(`${host}/auth-user/update`, params, {
    headers: {
      "Authorization": `Bearer ${user.token}`,
      "Content-Type": "application/json",
    }
  });
}

export function fetchCityList(): AxiosPromise {
  return axios.get(`${host}/dictionary/city`, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "*/*"
    }
  });
}

export function uploadAvatar(avatar: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/auth-user/upload-avatar`, avatar, {
    headers: {
      "Authorization": `Bearer ${user.token}`,
      "Content-Type": "multipart/form-data",
    }
  });
}

export interface ChangeUserEmailI {
  readonly email: string;
  readonly code?: string;
}
export function changeUserEmail({ email, code }: ChangeUserEmailI): AxiosPromise {
  const user = Cookies.getJSON("axp-user");
  console.log("payload", email, code);
  return axios.post(`${host}/auth-user/change-email`, {email, code},{
    headers: {
      "Authorization": `Bearer ${user.token}`,
      "Content-Type": "application/json",
    }
  });
}

export function fetchProjects(params: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.get(`${host}/project`, {
    params,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function createProject(params: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/project`, params, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function fetchProjectIcons(): AxiosPromise {
  return axios.get(`${host}/dictionary/project-icons`, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "*/*"
    }
  });
}

export function addPassportToProject(params: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/project/${params.id}/add-passport`, params,{
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function removePassportFromProject(params: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/project/passport-remove`, params, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function removeProject(id: number): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.delete(`${host}/project/${id}`, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function movePassports(data: MovePassportsValue): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/project/passport-move`, data, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function copyPassports(data: MovePassportsValue): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/project/passport-copy`, data, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function fetchNotifications(params: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.get(`${host}/notification`, {
    params,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function readNotification(ids: string[]): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/notification/make-read`, {ids}, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function fetchInvitedPeople(params: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.get(`${host}/project-invite`, {
    params,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function inviteUserToProject(data: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/project/${data.id}/invite-user`, {
    project_role_id: data.role.id,
    user_email: data.user_email
  }, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function removeUserFromProject(data: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/project/${data.id}/remove-user`, { user_id: data.ids }, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function changeUserRoleOnProject(data: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.post(`${host}/project/${data.id}/change-role`,
    { project_role_id: data.roles.id, user_id: data.user_id }, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function fetchHistoryProject(data: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.get(`${host}/project/${data.id}/history`, {
    params: {
      page: data.page,
      per_page: data.per_page,
      order_by: data.order_by,
      order_type: data.order_type
    },
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function fetchBanner(): AxiosPromise {
  return axios.get(`${host}/content`);
}

export function authSocial(data: any): AxiosPromise {
  return axios.post(`${host}/login/social-hash`, {
    hash: data.hash
  });
}

export function registerTemporaryUser(data: any): AxiosPromise {
  return axios.post(`${host}/temp-user/${data.temp_entity_id}/create-user`, data);
}

export function restorePassword(data: any): AxiosPromise {
  return axios.post(`${host}/password/forgot`, data);
}

export function resetForgotPassword(data: any): AxiosPromise {
  return axios.post(`${host}/password/reset`, data);
}

export function downloadPassports(data: any): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.get(`${host}/passport/download`, {
    headers: {
      "Authorization": `Bearer ${user.token}`,
    },
    params: {
      passport_id: data.passport_id
    },
    responseType: "blob"
  });
}

export function downloadProject(projectId: number): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.get(`${host}/project/${projectId}/download`, {
    headers: {
      "Authorization": `Bearer ${user.token}`
    }
  });
}

export function searchEntities(params: SearchEntitiesParams): AxiosPromise {
  return axios.get(`${host}/navigation/search`, { params });
}

export interface ChangeProjectTitleParams {
  readonly title?: string;
  readonly projectId: number;
  readonly added_passport_id?: number;
}
export function changeProjectTitle(params: ChangeProjectTitleParams): AxiosPromise {
  const user = Cookies.getJSON("axp-user");

  return axios.put(`${host}/project/${params.projectId}`, {...params}, {
    headers: {
      "Authorization": `Bearer ${user.token}`
    }
  });
}
