import {createSlice, SliceCaseReducers} from "@reduxjs/toolkit";
import {CategoryElement, LevelElement} from "interfaces/index";

export enum RequestStatuses {
  Initial,
  Pending,
  Success,
  Error
}

interface State {
  levels: LevelElement[];
  requestLevelsStatus: RequestStatuses;
  categories: CategoryElement[];
}

const navigationLevelsSlice = createSlice<State, SliceCaseReducers<State>>({
  name: "navigationLevels",
  initialState: {
    levels: [],
    requestLevelsStatus: RequestStatuses.Initial,
    categories: []
  },
  reducers: {
    putLevels(state, action): void {
      state.levels = [];
      state.categories = [];

      action.payload.forEach((item: LevelElement): void => {
        state.categories.push(...item.categories);
        state.levels.push(item);
      });
    },
    putRequestLevelsStatus(state, action): void {
      state.requestLevelsStatus = action.payload;
    }
  }
});

export default navigationLevelsSlice.reducer;
export const {
  putLevels,
  putRequestLevelsStatus
} = navigationLevelsSlice.actions;
