// Libraries
import React, {useMemo, useState} from "react";
import {Link} from "react-router-dom";
import Skeleton from "react-loading-skeleton";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Interfaces
import {CategoryElement, LevelElement, PassportElement} from "interfaces/index";

// Routes
import routes from "routes";

// Components
import SectionsMenuMobile from "./SectionsMenuMobile";

// Styles
import styles from "../Header.scss";

interface SectionsMenuProps {
  readonly setActiveSection: (flag: boolean) => void;
  readonly isActiveSection: boolean;
}

const SectionsMenu = ({ setActiveSection, isActiveSection }: SectionsMenuProps): JSX.Element => {
  const levels = useAppSelector(state => state.levels.levels);
  const [activeLevel, setActiveLevel] = useState(2);
  const level = levels.find((levelElement: LevelElement): boolean => levelElement.value === activeLevel);

  const renderLevels = useMemo((): JSX.Element[] => {
    return levels?.map((levelElement: LevelElement) => (
      <li
        onClick={(): void => setActiveLevel(levelElement.value)}
        key={`level-${levelElement.id}`}
        className={activeLevel === levelElement.value ? styles.ActiveLevel : ""}
      >
        { levelElement.title }
      </li>
    )).reverse();
  }, [levels, activeLevel]);

  const renderCategories = useMemo((): JSX.Element[] => {
    return level?.categories?.map((category: CategoryElement): JSX.Element => (
      <>
        <Link
          className={styles.RootSectionsRightMenuLink}
          key={`category-title-${category.id}`}
          to={routes.handbookWithCategoryId(level.id, category.id)}
          onClick={(): void => setActiveSection(false)}
        >
          { category.title }
        </Link>
        {
          category.passports?.map((passport: PassportElement, i): JSX.Element => (
            <Link
              className={styles.RootSectionsRightMenuSubLink}
              to={routes.passport(passport.id)}
              key={`passport-title-${passport.id}`}
              style={{ marginBottom: i === category.passports.length - 1 ? "34px" : "0px" }}
              onClick={(): void => setActiveSection(false)}
            >
              { passport.title }
            </Link>
          ))
        }
      </>
    ));
  }, [level, setActiveSection]);

  return (
    <>
      <div className={styles.RootSections}>
        <div className={styles.RootSectionsContainer}>
          <nav className={styles.RootSectionsLeftMenu}>
            <ul>
              { levels.length > 0 ? renderLevels : <Skeleton className={styles.SkeletonLevelItems} count={4} /> }
            </ul>
          </nav>
          <nav className={styles.RootSectionsRightMenu}>
            <ul data-level={activeLevel}>
              { levels.length > 0 ?  renderCategories : <Skeleton className={styles.SkeletonCategoryItems} count={60} /> }
            </ul>
          </nav>
        </div>
      </div>
      <SectionsMenuMobile isActiveSection={isActiveSection} />
    </>
  )
};

export default SectionsMenu;
