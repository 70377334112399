// Libraries
import {call, put, takeEvery, delay} from "redux-saga/effects";
import {AxiosPromise, AxiosResponse} from "axios";
import Cookies from "js-cookie";

// Interfaces
import {RegisterFormValues} from "interfaces/index";

// Reducers
import {RequestStatuses, setErrors, setRegisterStatus} from "store/reducers/authReducer";

// Actions
import {fetchUserAction} from "./actions";

// Api
import {registerUser} from "../../../Api";

export function* registerSagaWatcher(): Generator {
  yield takeEvery(fetchUserAction.type, putRegisterUser);
}

function* putRegisterUser({ payload }: any): Generator {
  yield put(setRegisterStatus(RequestStatuses.Pending));

  try {
    const res = yield call(createUser, payload);

    if ((res as AxiosResponse).data.token) {
      yield Cookies.set("axp-user",
        { token: (res as AxiosResponse).data.token, bearerToken: (res as AxiosResponse).data.bearer_token },
        { expires: 365 });
    }

    yield put(setRegisterStatus(RequestStatuses.Success));
    yield put(setErrors([]));
    yield delay(500);
    yield put(setRegisterStatus(RequestStatuses.Initial));
  } catch (error) {
    yield put(setErrors(error.response.data.errors));
    yield put(setRegisterStatus(RequestStatuses.Error));
  }
}

function createUser(values: RegisterFormValues): AxiosPromise {
  return registerUser(values);
}
