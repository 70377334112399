// Libraries
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";

// Enums
import {
  BtnTypes,
  ModalTypes,
  // VariantActionWithAuth
} from "enums/index";

// Components
import Button from "components/Button/Button";
import { Input } from "components/Form";

// Store
import { authUserAction } from "store/sagas/auth/actions";
import {
  RequestStatuses,
  // setErrors,
  // setVariantAction
} from "store/reducers/authReducer";

// Interfaces
import { Error } from "interfaces/index";

// Helpers
import { checkValidEmail, requireField } from "helpers/Validator";

// Hooks
import { useAppDispatch, useAppSelector } from "hooks/hooks";

// Styles
import styles from "./LoginForm.scss";

interface LoginFormProps {
  readonly setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>;
  readonly setActiveModalWindow: (flag: boolean) => void;
}

interface Errors {
  email?: string;
  password?: string;
}

const LoginForm = ({ setModalType, setActiveModalWindow }: LoginFormProps): JSX.Element => {
  const [isBtnTypePsw, setBtnTypePsw] = useState(true);
  const dispatch = useAppDispatch();
  const requestLoginStatus = useAppSelector(state => state.auth.requestLoginStatus);
  const authFormErrors = useAppSelector(state => state.auth.errors);

  // const closeModalWindow = (): void => {
  //   setActiveModalWindow(false);
  // };
  
  useEffect(() => {
    if (requestLoginStatus === RequestStatuses.Success) {
      setActiveModalWindow(false);
    }
  }, [requestLoginStatus, setActiveModalWindow]);

  
  const onSubmit = (values: any) => {
    dispatch(authUserAction(values));
  };

  const validate = () => {
    const errors: Errors = {};
    const emailError = authFormErrors?.find((error: Error) => error.type === "email");
      const pswError = authFormErrors?.find((error: Error) => error.type === "password");

    if (emailError) {
      errors.email = emailError.text;
    }

    if (pswError) {
      errors.password = pswError.text;
    }

    return errors;
  };
  
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, form }): JSX.Element => (
        <form className={styles.LoginForm} onSubmit={handleSubmit}>
          <h4 className={styles.LoginFormTitle}>Войдите в аккаунт
            {/*<br />*/}
            {/*или зарегистрируйтесь*/}
          </h4>
          <div className={styles.LoginFormContainer}>
            <div className={styles.LoginFormContainerField}>
              <label>Email</label>
              <Input
                placeholder="example@gmail.com"
                type="text"
                name="email"
                validators={[requireField, checkValidEmail]}
              />
            </div>
            <div className={styles.LoginFormContainerField}>
              <label>Пароль</label>
              <div className={styles.LoginFormContainerFieldPsw}>
                <Input
                  placeholder="Пароль"
                  type={isBtnTypePsw ? BtnTypes.password : BtnTypes.text}
                  name="password"
                  validators={[requireField]}
                />
                <i data-show={isBtnTypePsw} onClick={(): void => setBtnTypePsw(!isBtnTypePsw)} />
              </div>
            </div>
          </div>
          <div className={styles.LoginFormActions}>
            <Button
              title="Войти"
              classes={{ root: "" }}
              type="submit"
            />
            {/*<div className={styles.LoginFormActionsSocials}>*/}
            {/*  <a href="https://api.goodatlas.ru/auth/google/redirect">*/}
            {/*    <i className={styles.LoginFormActionsSocialsGoogle} />*/}
            {/*  </a>*/}
            {/*  <a href="https://api.goodatlas.ru/auth/facebook/redirect">*/}
            {/*    <i className={styles.LoginFormActionsSocialsFB} />*/}
            {/*  </a>*/}
            {/*  <a href="https://api.goodatlas.ru/auth/vk/redirect">*/}
            {/*    <i className={styles.LoginFormActionsSocialsVK} />*/}
            {/*  </a>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  className={styles.LoginFormActionsRegistration}*/}
            {/*  onClick={(): void => {*/}
            {/*    setModalType(ModalTypes.Registration);*/}
            {/*    dispatch(setErrors([]));*/}
            {/*    dispatch(setVariantAction(VariantActionWithAuth.Registration));*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Зарегистрироваться*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  className={styles.LoginFormContainerFieldPswSignature}*/}
            {/*  onClick={(): void => {*/}
            {/*    setModalType(ModalTypes.RecoverySendEmailPsw);*/}
            {/*    dispatch(setErrors([]));*/}
            {/*    dispatch(setVariantAction(VariantActionWithAuth.ResetPassword));*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Забыли пароль?*/}
            {/*</div>*/}
          </div>
          {/*<i*/}
          {/*  className={styles.Close}*/}
          {/*  onClick={closeModalWindow}*/}
          {/*/>*/}
        </form>
      )}
    />
  )
}

export default LoginForm;
