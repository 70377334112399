// Libraries
import React, {useEffect} from "react";
import {Link} from "react-router-dom";

// Interfaces
import {Project} from "interfaces/index";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Actions
import {fetchProjectsAction} from "store/sagas/projects/actions";
import {reset as resetFilters} from "store/reducers/filtrationReducer";

// Routes
import routes from "routes";

// Styles
import styles from "../Header.scss";

const ProjectsLink = (): JSX.Element => {
  const defaultProject: Project = useAppSelector(state => state.projects.projects.find((project: Project) => project.is_favorite === true));
  const dispatch = useAppDispatch();

  useEffect(() => {
    !defaultProject && dispatch(fetchProjectsAction({}));
  }, [dispatch, defaultProject]);

  return (
    <Link onClick={() => dispatch(resetFilters({}))} className={styles.Projects} to={routes.project(defaultProject?.id)}>Проекты</Link>
  )
};

export default ProjectsLink;
