import React from "react";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import { Link } from "react-router-dom";

// Routes
import routes from "routes";

// Interfaces
import { LickBessListElement } from "store/reducers/usefulReducer";
import { LickBess } from "interfaces/index";

// Styles
import styles from "./Card.scss";

interface CardProps {
  readonly items: LickBessListElement;
}

const Card = ({ items }: CardProps): JSX.Element => {
  return (
    <div className={styles.Root}>
      {
        items.array.map((item: LickBess): JSX.Element => {
          const [img] = item.card_image;
          return (
            <Link
              to={routes.usefulsElement(item.id)}
              key={uuidv4()}
              className={
                classNames(
                  [{ [styles.VariantFirst]: items.variant === 1 }],
                  [{ [styles.VariantSecond]: items.variant === 2 }],
                  [{ [styles.VariantThird]: items.variant === 3 }]
                )
              }
            >
              <img src={img.url} alt=""/>
              <p>{ item.title }</p>
            </Link>
          )
        })
      }
    </div>
  );
};

export default Card;
