import { all } from "redux-saga/effects";
import { passportsSagaWatcher } from "store/sagas/passports/passportsSaga";
import { navigationLevelsWatcher } from "store/sagas/navigationLevels/navigationLevelsSaga";
import { projectsSagaWatcher } from "store/sagas/projects/projectsSaga";
import { getUserInfoSagaWatcher } from "./auth/userSaga";
import { registerSagaWatcher } from "./auth/registerUserSaga";
import { loginSagaWatcher } from "./auth/loginUserSaga";
import { logoutUserSagaWatcher } from "./auth/logoutUserSaga";
import { lickbessListWatcher, lickbessWatcher } from "./useful/lickbessSaga";
import {removeUserAccountWatcher, settingsUserWatcher} from "./auth/settingsUserSaga";
import {searchEntitiesWatcher} from "./search/searchSaga";
import {notificationsWatcher} from "./notifications/notificationsSaga";
import {invitedSagaWatcher} from "./invited/invitedSaga";
import {contentSagaWatcher} from "./content/contentSaga";

export default function* rootSaga(): Generator {
 yield all([
   registerSagaWatcher(),
   loginSagaWatcher(),
   logoutUserSagaWatcher(),
   getUserInfoSagaWatcher(),
   lickbessListWatcher(),
   lickbessWatcher(),
   passportsSagaWatcher(),
   navigationLevelsWatcher(),
   settingsUserWatcher(),
   removeUserAccountWatcher(),
   projectsSagaWatcher(),
   searchEntitiesWatcher(),
   notificationsWatcher(),
   invitedSagaWatcher(),
   contentSagaWatcher()
 ]);
}
