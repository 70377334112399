// Libraries
import React, {useState} from "react";
import {Form} from "react-final-form";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Interfaces
import {User} from "interfaces/index";

// Components
import Button from "components/Button/Button";
import UsuallyModal from "components/ModalWindows/UsuallyModal/UsuallyModal";
import {Input} from "components/Form";
import ChangeEmailForm from "../ChangeEmailForm/ChangeEmailForm";
import ChangePasswordForm from "../ChangePasswordForm/ChangePasswordForm";

// Styles
import styles from "./MainInfoForm.scss";

enum VariantsForm {
  ChangeEmail,
  ChangePassword
}

const MainInfoForm = (): JSX.Element => {
  const [isShowModal, setShowModal] = useState<boolean>(false);
  const [variantModal, setVariantModal] = useState<VariantsForm>(VariantsForm.ChangeEmail);
  const user: User = useAppSelector((state) => state.auth.user);

  const onOpenChangeEmailModal = (): void => {
    setVariantModal(VariantsForm.ChangeEmail);
    setShowModal(true)
  };

  const onOpenChangePasswordModal = (): void => {
    setVariantModal(VariantsForm.ChangePassword);
    setShowModal(true)
  };

  const onSubmit = (values: any): void => {
    console.log("values123", values);
  };
  
  return (
    <div className={styles.MainInfo}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }): JSX.Element => (
          <form className={styles.Form} onSubmit={handleSubmit}>
            <h4 className={styles.FormTitle}>
              Основная информация
            </h4>
            <div className={styles.FormContainer}>
              <div className={styles.FormContainerField}>
                <label>Email</label>
                <div className={styles.FormInputContainer}>
                  <Input
                    placeholder={user.email}
                    type="text"
                    name="email"
                    disabled
                    classes={{ root: styles.InputContent }}
                  />
                  <Button
                    title="Изменить"
                    classes={{ root: styles.FormInputContainerButton }}
                    type="button"
                    callback={onOpenChangeEmailModal}
                    dataModal
                  />
                </div>
              </div>
              <div className={styles.FormContainerField}>
                <label>Пароль</label>
                <div className={styles.FormInputContainer}>
                  <Input
                    placeholder="**********"
                    type="password"
                    name="password"
                    classes={{ root: styles.InputContent }}
                    disabled
                  />
                  <Button
                    title="Изменить"
                    classes={{ root: styles.FormInputContainerButton }}
                    type="button"
                    callback={onOpenChangePasswordModal}
                    dataModal
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      />

      <UsuallyModal
        maxWidth="387"
        title={variantModal === VariantsForm.ChangeEmail ? "Изменение Email" : "Изменения пароля"}
        isShowModal={isShowModal}
        setShowModal={setShowModal}
        isCloseOnClickOutside={false}
      >
        <>
          { variantModal === VariantsForm.ChangeEmail && <ChangeEmailForm /> }
          { variantModal === VariantsForm.ChangePassword && <ChangePasswordForm /> }
        </>
      </UsuallyModal>
    </div>
  )
};

export default MainInfoForm;
