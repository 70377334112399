import {createAction} from "@reduxjs/toolkit";

interface LickBessListAction {
  readonly page: number;
  readonly per_page: number;
  readonly useful_type: number;
  readonly order_by?: string
}

export const lickbessListAction = createAction<LickBessListAction>("GET/FETCH_LICKBESS_LIST");
export const lickbessAction = createAction<number>("GET/GETCH_LICKBESS");
