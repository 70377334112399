import React, {useEffect} from "react";
import {Form} from "react-final-form";
import {useAppDispatch, useAppSelector} from "hooks/hooks";
import {ModalTypes, RequestStatuses} from "enums/index";
import {restorePasswordAction} from "store/sagas/auth/actions";
import Button from "components/Button/Button";
import {Input} from "components/Form";
import {checkValidEmail} from "helpers/Validator";
import {setEmail} from "store/reducers/authReducer";
import styles from "./RecoveryForm.scss";

interface LoginFormProps {
  readonly setActiveModalWindow: (flag: boolean) => void;
  readonly setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>;
}

interface Values {
  readonly email: string;
}

const RecoveryForm = ({ setActiveModalWindow, setModalType }: LoginFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const requestRestorePasswordStatus = useAppSelector(state => state.auth.requestRestorePasswordStatus);
  
  useEffect(() => {
    if (requestRestorePasswordStatus === RequestStatuses.Success) {
      setModalType(ModalTypes.EnterCode);
    }
  }, [requestRestorePasswordStatus, setModalType]);

  const onSubmit = (values: Values) => {
    dispatch(restorePasswordAction(values));
    dispatch(setEmail(values.email));
  };

  const closeModalWindow = (): void => {
    setActiveModalWindow(false);
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form }): JSX.Element => (
        <form className={styles.RecoveryForm} onSubmit={handleSubmit}>
          <h4 className={styles.RecoveryFormTitle}>
            Восстановление пароля
          </h4>
          <p className={styles.RecoveryFormSubTitle}>
            Мы отправим Вам сообщение с кодом на почту
          </p>
          <div className={styles.RecoveryFormContainer}>
            <div className={styles.RecoveryFormContainerField}>
              <label>Email</label>
              <Input placeholder="example@gmail.com" type="text" name="email" validators={[checkValidEmail]} />
            </div>
          </div>
          <div className={styles.RecoveryFormActions}>
            <Button
              title="Отправить"
              classes={{ root: "" }}
              type="submit"
              callback={() => form.reset}
            />
          </div>
          <i
            className={styles.Close}
            onClick={closeModalWindow}
          />
        </form>
      )}
    />
  )
};

export default RecoveryForm;
