// Libraries
import React from "react";
import {Field, Form} from "react-final-form";

// Assets
import {ReactComponent as SearchIcon} from "assets/search.svg";

// Styles
import styles from "../../MyProjects.scss";

interface SearchPassportsProps {
  readonly passportSearch: (values: SearchValues) => any;
}

export interface SearchValues {
  readonly search_passport: string;
}

const SearchPassports = ({ passportSearch }: SearchPassportsProps): JSX.Element => {
  return (
    <Form
      onSubmit={passportSearch}
      render={({ handleSubmit }): JSX.Element => (
        <form onChange={handleSubmit}>
          <div className={styles.SearchField}>
            <SearchIcon />
            <Field
              name="search_passport"
              component="input"
              type="text"
              placeholder="Поиск"
            />
          </div>
        </form>
      )}
    />
  )
};

export default SearchPassports;
