// Libraries
import React from "react";

// Styles
import styles from "../LickbessPage.scss";

interface CheckListProps {
  readonly content: any;
}

const CheckList = ({ content }: CheckListProps): JSX.Element => {
  const renderContent = (element: JSX.Element): JSX.Element => {
    if (element.type === "p" && element.props.children.type === "br") {
      return <span />;
    }

    if (element.type === "p" && element.props.children.type === "strong") {
      return <span style={{ paddingTop: "15px", display: "block", borderRight: "1px solid #D9DAE6", width: "65%" }}>{ element.props.children }</span>
    }

    if (element.type === "p") {
      return <p><span>{ element }</span></p>
    }

    return <p><span>{ element }</span></p>
  };
  return (
    <div className={styles.CheckListContent}>
      { content?.length ?
        content?.map((element: JSX.Element): JSX.Element => renderContent(element)) :
        renderContent(content as JSX.Element)
      }
    </div>
  )
};

export default CheckList;
