// Libraries
import { put, takeEvery, call, delay } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {changeSettingsUserAction, removeUserAccountAction} from "./actions";
import {
  putUser,
  RequestStatuses,
  setRequestChangeSettingsStatus,
  setRequestRemoveUserAccountStatus,
} from "../../reducers/authReducer";
import {changeSettings, removeUserAccount} from "../../../Api";

export function* settingsUserWatcher(): Generator {
  yield takeEvery(changeSettingsUserAction, settingsUserWorker);
}

function* settingsUserWorker({ payload }: any): Generator {
  yield put(setRequestChangeSettingsStatus(RequestStatuses.Pending));

  try {
    const res = yield call(changeSettings, payload);
    yield put(putUser((res as AxiosResponse).data));
    yield put(setRequestChangeSettingsStatus(RequestStatuses.Success));
    yield put(setRequestChangeSettingsStatus(RequestStatuses.Initial));
  } catch (error) {
    yield put(setRequestChangeSettingsStatus(RequestStatuses.Error));
  }
}

export function* removeUserAccountWatcher(): Generator {
  yield takeEvery(removeUserAccountAction, removeUserAccountWorker);
}

function* removeUserAccountWorker(): Generator {
  yield put(setRequestRemoveUserAccountStatus(RequestStatuses.Pending));

  try {
    yield call(removeUserAccount);
    yield put(setRequestRemoveUserAccountStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(putUser(undefined));
    yield put(setRequestRemoveUserAccountStatus(RequestStatuses.Initial));
  } catch (error) {
    yield put(setRequestRemoveUserAccountStatus(RequestStatuses.Error));
  }
}
