import {createSlice, SliceCaseReducers} from "@reduxjs/toolkit";
import {Banner} from "interfaces/index";
import {RequestStatuses} from "enums/index";

interface State {
  banner?: Banner;
  requestFetchBannerStatus: RequestStatuses;
}

const initialState: State = {
  banner: undefined,
  requestFetchBannerStatus: RequestStatuses.Initial
}

const contentSlice = createSlice<State, SliceCaseReducers<State>>({
  name: "content",
  initialState,
  reducers: {
    putBanner(state, action): void {
      state.banner = action.payload;
    },
    putRequestFetchBannerStatus(state, action): void {
      state.requestFetchBannerStatus = action.payload;
    }
  }
});

export default contentSlice.reducer;
export const {
  putBanner,
  putRequestFetchBannerStatus
} = contentSlice.actions;
