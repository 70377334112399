import {createSlice, SliceCaseReducers} from "@reduxjs/toolkit";
import {Notification} from "interfaces/index";
import {RequestStatuses} from "enums/index";
import dayjs from "dayjs";
import * as locale from "dayjs/locale/ru";

export interface NotificationItem {
  date: string;
  list: Notification[];
}

interface State {
  notifications: NotificationItem[];
  notificationsTotal: number | null;
  requestFetchNotificationsStatus: RequestStatuses;
  notificationsList: Notification[];
  requestReadNotificationStatus: RequestStatuses;
  requestFetchHistoryProjectStatus: RequestStatuses;
  histories: NotificationItem[],
  historyList: Notification[];
}

const initialState: State = {
  notifications: [],
  requestFetchNotificationsStatus: RequestStatuses.Initial,
  notificationsTotal: null,
  notificationsList: [],
  requestReadNotificationStatus: RequestStatuses.Initial,
  requestFetchHistoryProjectStatus: RequestStatuses.Initial,
  histories: [],
  historyList: []
};

const notifications = createSlice<State, SliceCaseReducers<State>>({
  name: "notifications",
  initialState,
  reducers: {
    putNotifications(state, action): void {
      const elements = action.payload.map((notification: Notification) => {
        return {
          date: dayjs(notification.created_at).locale(locale).format("DD MMMM"),
          ...notification
        }
      });

      action.payload.forEach((notification: Notification): void => {
        const date = dayjs(notification.created_at).locale(locale).format("DD MMMM");
        const foundElement = state.notifications.find((notificationItem: NotificationItem): boolean => notificationItem.date === date);

        if (!foundElement) {
          state.notifications.push({
            date,
            list: elements.filter((element: NotificationItem): boolean => element.date === date)
          });
        } else {
          const element = foundElement.list.find((item: Notification) => item.id === notification.id);
          if (!element) {
            foundElement.list.push(notification);
          }
        }
      });

      action.payload.forEach((item: Notification) => {
        const foundElement = state.notificationsList.find((notification: Notification) => notification.id === item.id);

        if (!foundElement) {
          state.notificationsList.push(item);
        }
      });
    },
    putHistories(state, action): void {
      const elements = action.payload.map((notification: Notification) => {
        return {
          date: dayjs(notification.created_at).locale(locale).format("DD MMMM"),
          ...notification
        }
      });

      action.payload.forEach((notification: Notification): void => {
        const date = dayjs(notification.created_at).locale(locale).format("DD MMMM");
        const foundElement = state.histories.find((notificationItem: NotificationItem): boolean => notificationItem.date === date);

        if (!foundElement) {
          state.histories.push({
            date,
            list: elements.filter((element: NotificationItem): boolean => element.date === date)
          });
        } else {
          const element = foundElement.list.find((item: Notification) => item.id === notification.id);
          if (!element) {
            foundElement.list.push(notification);
          }
        }
      });

      action.payload.forEach((item: Notification) => {
        const foundElement = state.historyList.find((notification: Notification) => notification.id === item.id);

        if (!foundElement) {
          state.historyList.push(item);
        }
      });
    },
    setRequestFetchNotificationsStatus(state, action): void {
      state.requestFetchNotificationsStatus = action.payload;
    },
    setNotificationsTotal(state, action): void {
      state.notificationsTotal = action.payload;
    },
    setRequestReadNotificationStatus(state, action): void {
      state.requestReadNotificationStatus = action.payload;
    },
    setReadNotifications(state, action): void {
      const foundElement = state.notificationsList.find((notification: Notification) => notification.id === action.payload[0]);
      if (foundElement) {
        foundElement.read_at = true;
      } else {
        throw new Error(`element with ${action.payload[0]} not found`);
      }
    },
    setRequestFetchHistoryProjectStatus(state, action): void {
      state.requestFetchHistoryProjectStatus = action.payload;
    },
    reset: (): State => initialState
  }
});

export default notifications.reducer;
export const {
  putNotifications,
  setRequestFetchNotificationsStatus,
  setNotificationsTotal,
  setRequestReadNotificationStatus,
  setReadNotifications,
  setRequestFetchHistoryProjectStatus,
  putHistories,
  reset
} = notifications.actions;
