// Libraries
import React from "react";
import {Link, useParams} from "react-router-dom";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Assets
import {ReactComponent as MarkIcon} from "assets/check_mark.svg";

// Routes
import routes from "routes";

// Interfaces
import {PassportElement} from "interfaces/index";

// Styles
import styles from "./BreadCrumbs.scss";

interface BreadCrumbsProps {
  readonly classes?: {
    readonly root?: string;
  }
}

const BreadCrumbs = (
  {
    classes
  }: BreadCrumbsProps): JSX.Element => {
  const { id } = useParams();
  const passport = useAppSelector(state =>
    state.passports.passportsCollection.find((passportElement: PassportElement): boolean =>
      passportElement.id == id));

  return (
    <div className={classNames(classes?.root, styles.Root)}>
      <Link className={styles.BreadCrumb} to={routes.handbook}>{ passport?.category.level.title || <Skeleton width={80} /> }</Link>
      <MarkIcon />
      <Link className={classNames(styles.BreadCrumb, styles.BreadCrumbActive)} to={routes.handbookWithCategoryId(passport?.category.level.id, passport?.category.id )}>
        { passport?.category.title || <Skeleton width={80} /> }
      </Link>
    </div>
  )
}

export default BreadCrumbs;
