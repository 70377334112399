// Libraries
import React from "react";
import {Form} from "react-final-form";

// Components
import {Checkbox} from "components/Form";

// Styles
import styles from "./InvitationElement.scss";

const FormElementCheckbox = ({ onSubmit, choiceUserForRemove, id, name, choiceUsersList }: any): JSX.Element => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }): JSX.Element => (
        <form onSubmit={handleSubmit}>
          <Checkbox
            callback={(): void => choiceUserForRemove(id)}
            classes={{ root: styles.InvitedCheckbox }}
            label=""
            name={name || `name-${id}`}
            initialValue={!!choiceUsersList.find((user: number) => user === id)}
          />
        </form>
      )}
    />
  )
};

export default FormElementCheckbox;
