import {createSlice, SliceCaseReducers} from "@reduxjs/toolkit";
import {Complexity} from "components/Filtration/components/ComplexityInstallation/ComplexityInstallation";
import {CostEnum} from "components/Filtration/components/Cost/Cost";

interface State {
  filterCostValue: CostEnum | undefined;
  filterComplexityValue: Complexity | undefined;
  filterApplicationArea: number[];
  filterLifeTime: {
    lifeTimeStart: string | undefined,
    lifeTimeEnd: string | undefined
  } | undefined;
}

const initialState: State = {
  filterApplicationArea: [],
  filterComplexityValue: undefined,
  filterCostValue: undefined,
  filterLifeTime: {
    lifeTimeStart: "0",
    lifeTimeEnd: undefined
  }
};

const filtration = createSlice<State, SliceCaseReducers<State>>({
  name: "filtration",
  initialState,
  reducers: {
    setFilterApplicationArea(state, action): void {
      state.filterApplicationArea = action.payload;
    },
    setFilterComplexityValue(state, action): void {
      state.filterComplexityValue = action.payload;
    },
    setFilterCostValue(state, action): void {
      state.filterCostValue = action.payload;
    },
    setFilterLifeTime(state, action): void {
      state.filterLifeTime = action.payload;
    },
    reset: (): State => initialState,
  }
});

export default filtration.reducer;
export const {
  setFilterApplicationArea,
  setFilterComplexityValue,
  setFilterCostValue,
  setFilterLifeTime,
  reset
} = filtration.actions;
