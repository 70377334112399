// Libraries
import React, {useEffect} from "react";
import { useParams, Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import parse from "html-react-parser";
import { PDFDownloadLink } from "@react-pdf/renderer";

// Routes
import routes from "routes";

// Interfaces
import {LickBess} from "interfaces/index";

// Assets
import { ReactComponent as PdfDownload } from "assets/dwn_pdf.svg";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Actions
import {lickbessAction} from "store/sagas/useful/actions";

// Components
import Loader from "components/Loader/Loader";
import Page from "components/Page/Page";
import CheckList from "./components/CheckList";
import Lickbess from "./components/Lickbess";
import PdfDocumentTemplateOne from "./components/PdfDocument/PdfDocumentTemplateOne";
import PdfDocumentTemplateTwo from "./components/PdfDocument/PdfDocumentTemplateTwo";

// Styles
import styles from "./LickbessPage.scss";

const LickbessPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const element: LickBess = useAppSelector(state => state.useful.lickbess.find((item: LickBess): boolean => item.id == id));

  useEffect(() => {
    if (!element) {
      dispatch(lickbessAction(id));
    }
  }, [dispatch, element, id]);

  return (
    <Page>
      <section className={styles.Root}>
        <div className={styles.Content}>
          <div className={styles.Actions}>
            <Link className={styles.ActionsNavigation} to={routes.usefuls}>Полезное</Link>
            {
              element && (
                <div className={styles.PDF}>
                  <PDFDownloadLink
                    document={element.useful_type_id === 1
                      ? <PdfDocumentTemplateTwo element={element} />
                      : <PdfDocumentTemplateOne element={element} />
                    }
                    fileName={`${element.title}.pdf`}
                  >
                    {({ loading }): JSX.Element =>
                      loading ? <Loader /> : <PdfDownload />
                    }
                  </PDFDownloadLink>
                </div>
              )
            }
          </div>
          <div id="myMm" style={{height: "1mm"}} />
          <div id="lickbess-page-pdf">
            <>
              { element ? <h3 className={styles.Title}>{ element.title }</h3> : <Skeleton height={23} /> }
              { element ? <p className={styles.Description}>{ element.description }</p> : <Skeleton /> }
              { element && element.useful_type_id === 1 && <CheckList content={(parse(element.text) as JSX.Element[])} /> }
              { element && element.useful_type_id === 2 && <Lickbess content={parse(element.text)} /> }
            </>
          </div>
        </div>
      </section>
    </Page>
  )
};

export default LickbessPage;
