import {call, put, takeLatest, delay} from "redux-saga/effects";
import {RequestStatuses} from "enums/index";
import {AxiosResponse} from "axios";
import {fetchHistoryProjectAction, fetchNotificationsAction, readNotificationAction} from "./actions";
import {
  putNotifications,
  setRequestFetchNotificationsStatus,
  setNotificationsTotal,
  setRequestReadNotificationStatus,
  setReadNotifications,
  setRequestFetchHistoryProjectStatus, putHistories
} from "../../reducers/notificationsReducer";
import {fetchHistoryProject, fetchNotifications, readNotification} from "../../../Api";

export function* notificationsWatcher(): Generator {
  yield takeLatest(fetchNotificationsAction, fetchNotificationsWorker);
  yield takeLatest(readNotificationAction, readNotificationWorker);
  yield takeLatest(fetchHistoryProjectAction, fetchHistoryProjectWorker);
}

function* fetchNotificationsWorker({ payload }: any): Generator {
  yield put(setRequestFetchNotificationsStatus(RequestStatuses.Pending));
  
  try {
    const res = yield call(fetchNotifications, payload);
    yield put(setNotificationsTotal((res as AxiosResponse).data.total));
    yield put(putNotifications((res as AxiosResponse).data.data));
    yield put(setRequestFetchNotificationsStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(setRequestFetchNotificationsStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestFetchNotificationsStatus(RequestStatuses.Error));
  }
}

function* readNotificationWorker({payload}: any): Generator {
  yield put(setRequestReadNotificationStatus(RequestStatuses.Pending));

  try {
    yield call(readNotification, payload);
    yield put(setReadNotifications(payload));
    yield put(setRequestReadNotificationStatus(RequestStatuses.Success));
    yield put(setRequestReadNotificationStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestReadNotificationStatus(RequestStatuses.Error));
  }
}

function* fetchHistoryProjectWorker({ payload }: any): Generator {
  yield put(setRequestFetchHistoryProjectStatus(RequestStatuses.Pending));

  try {
    const res = yield call(fetchHistoryProject, payload);
    yield put(putHistories((res as AxiosResponse).data.data));
    yield put(setRequestFetchHistoryProjectStatus(RequestStatuses.Success));
    yield delay(500);
    yield put(setRequestFetchHistoryProjectStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestFetchHistoryProjectStatus(RequestStatuses.Error));
  }
}
