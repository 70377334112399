import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { LickBess } from "interfaces/index";

export interface LickBessListElement {
  readonly variant: number;
  readonly array: LickBess[];
}

export enum RequestStatuses {
  Initial,
  Pending,
  Success,
  Error
}

interface State {
  lickbess: LickBess[];
  lickbessList: LickBessListElement[];
  requestLickbessListStatus: RequestStatuses;
  requestLickbessStatus: RequestStatuses;
  commonLickbessListLenght: number;
}

const usefulSlice = createSlice<State, SliceCaseReducers<State>>({
  name: "useful",
  initialState: {
    lickbess: [],
    lickbessList: [],
    requestLickbessListStatus: RequestStatuses.Initial,
    requestLickbessStatus: RequestStatuses.Initial,
    commonLickbessListLenght: 0
  },
  reducers: {
    addLickbess(state, action) {
      state.lickbess.push(action.payload);
    },
    resetUsefulList(state, action): void {
      state.lickbessList = action.payload;
      state.lickbess = action.payload;
      state.commonLickbessListLenght = 0
    },
    putLickbess(state, action) {
      let j = 0;
      let array: LickBess[] = [];

      action.payload.data.length > 0 && action.payload.data.forEach((item: LickBess, i: number) => {
        const fountElement = state.lickbess.find(((lickbess: LickBess): boolean => lickbess.id === item.id))

        if (!fountElement) {
          j += 1;
          array.push(item);

          if (j === 3) {
            state.lickbessList.push({ variant: 1, array});
            array = [];
          }

          if (j === 4 || j === 5) {
            state.lickbessList.push({ variant: 2, array});
            array = [];
          }

          if (j === 8) {
            state.lickbessList.push({ variant: 3, array});
            array = [];
            j = 0;
          }

          if (i === action.payload.data.length - 1 && action.payload.data.length < 8) {
            if (j <= 3) {
              state.lickbessList.push({ variant: 1, array});
              array = [];
            }

            if (j > 3 && j <= 5) {
              state.lickbessList.push({ variant: 2, array});
              array = [];
            }

            if (j > 5) {
              state.lickbessList.push({ variant: 3, array});
              array = [];
            }

            j = 0;
          }

          state.lickbess.push(item);
        }
      });
    },
    setRequestLickbessListStatus(state, action) {
      state.requestLickbessListStatus = action.payload;
    },
    setRequestLickbessStatus(state, action) {
      state.requestLickbessStatus = action.payload;
    },
    setLickbessListLenght(state, action) {
      state.commonLickbessListLenght = action.payload;
    }
  }
});

export default usefulSlice.reducer;
export const {
  putLickbess,
  setRequestLickbessListStatus,
  addLickbess,
  setRequestLickbessStatus,
  setLickbessListLenght,
  resetUsefulList
} = usefulSlice.actions;
