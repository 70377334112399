// Libraries
import React from "react";

// Components
import PrivateCabinet from "../../PrivateCabinet";
import NotificationsForm from "./components/NotificationsForm/NotificationsForm";

// Styles
import styles from "./Settings.scss";

const Settings = (): JSX.Element => (
  <PrivateCabinet>
    <section className={styles.Root}>
      <NotificationsForm />
    </section>
  </PrivateCabinet>
);

export default Settings;
