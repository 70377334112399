// Libraries
import React, {useCallback, useMemo, useState} from "react";
import classNames from "classnames";

// Components
import FilterElement from "../FilterElement/FilterElement";

// Styles
import styles from "./ComplexityInstallation.scss";

interface ComplexityInstallationProps {
  readonly classes?: {
    readonly root?: string;
    readonly title?: string;
  }
  readonly hasTitle?: boolean;
  readonly isActiveFilter?: boolean;
  readonly complexity?: Complexity;
  readonly setComplexityFilter?: (complexity: Complexity) => void;
}

export enum Complexity {
  Easy = 1,
  Middle = 2,
  Hardly = 3
}

const ComplexityInstallation = (
  {
    classes = {},
    hasTitle = true,
    isActiveFilter = false,
    complexity,
    setComplexityFilter
  }: ComplexityInstallationProps): JSX.Element => {
  const [elements] = useState<Complexity[]>([Complexity.Easy, Complexity.Middle, Complexity.Hardly]);

  const getTitle = useCallback((index: number): string => {
    switch (index) {
      case 0:
        return "Легко";
      case 1:
        return "Средне";
      case 2:
        return "Сложно";
      default:
        return "Легко";
    }
  }, []);

  const renderFilterElements = useMemo((): JSX.Element[] => (
    elements.map((element, i): JSX.Element => (
      <FilterElement
        key={`filter-install-element-${element}`}
        classes={{
          default: styles.FilterElementMountingIcon,
          active: complexity && i < complexity ? styles.FilterElementMountingIconActive : "",
          activeIcon: ""
        }}
        title={getTitle(i)}
        isElementActive={complexity && i <= complexity}
        isActiveFilter={isActiveFilter}
        setValueFilter={setComplexityFilter}
        value={i + 1}
      />
    ))
  ), [elements, complexity, isActiveFilter, setComplexityFilter, getTitle]);

  return (
    <div className={classNames(styles.FilterElementComplexityInstallation, classes?.root)}>
      { hasTitle && <span className={classes?.title}>Сложность монтажа</span> }
      <div className={styles.FilterElementComplexityInstallationContent}>
        {
          renderFilterElements
        }
      </div>
    </div>
  )
}

export default ComplexityInstallation;
