// Libraries
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Form} from "react-final-form";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
import {useParams, useHistory} from "react-router-dom";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Actions
import {fetchPassportsAction} from "store/sagas/passports/actions";
import {setFilterApplicationArea, setFilterComplexityValue, setFilterCostValue, setFilterLifeTime, reset as resetFiltersValues } from "store/reducers/filtrationReducer";

// Store
import {RequestStatuses, reset} from "store/reducers/passportsReducer";

// Interfaces
import {CategoryElement, LevelElement, PassportElement} from "interfaces/index";

// Assets
import { ReactComponent as EmptyPassports } from "assets/no_passport.svg";

// Components
import Page from "components/Page/Page";
import Card from "components/Card/Card";
import {Input} from "components/Form";
import Loader from "components/Loader/Loader";
import ApplicationArea, {ApplicationAreaFilterElement, Filters} from "components/Filtration/components/ApplicationArea/ApplicationArea";
import ComplexityInstallation from "components/Filtration/components/ComplexityInstallation/ComplexityInstallation";
import Cost from "components/Filtration/components/Cost/Cost";
import Navigation from "./components/Navigation";

// Styles
import styles from "./Handbook.scss";

const perPage = 12;

interface LifeTimeValues {
  readonly lifetime_start: string;
  readonly lifetime_end: string;
}

const Handbook = (): JSX.Element => {
  const { levelId, categoryId } = useParams();
  const complexityValue = useAppSelector(state => state.filtration.filterComplexityValue);
  const filterCostValue = useAppSelector(state => state.filtration.filterCostValue);
  const filterApplicationArea = useAppSelector(state => state.filtration.filterApplicationArea);
  const filterLifeTime = useAppSelector(state => state.filtration.filterLifeTime);
  const dispatch = useAppDispatch();
  const passportsList = useAppSelector(state => state.passports.passportsList);
  const passportListTotal = useAppSelector(state => state.passports.passportsListTotal);
  const requestPassportsStatus = useAppSelector(state => state.passports.requestPassportsStatus);
  /* page - страница пагинации */
  const [page, setPage] = useState<number>(1);
  const level = useAppSelector(state => state.levels.levels.find((levelElement: LevelElement): boolean =>
    levelElement.id == levelId));
  const category = useAppSelector(state => state.levels.categories.find((categoryElement: CategoryElement): boolean =>
    categoryElement.id == categoryId));
  const [isActiveSkeleton, setActiveSkeleton] = useState(true);
  const history = useHistory();
  
  const [isFiltersActive, setFiltersActive] = useState(false);
  const [mode, setMode] = useState(1);

  useEffect(() => {
    dispatch(reset({}));
  }, [categoryId, dispatch]);
  console.log("requestPassportsStatus", requestPassportsStatus);
  /* выключаем лоадер если запрос успешно завершен */
  useEffect(() => {
    if (requestPassportsStatus === RequestStatuses.Success) {
      setActiveSkeleton(false);
    }
  }, [requestPassportsStatus]);

  /* если путь меняется, то включаем лоадер */
  useEffect(() => {
    setActiveSkeleton(true);
  }, [history.location.pathname, filterCostValue, complexityValue, filterApplicationArea, filterLifeTime]);

  /* устанавливаем новое значение page если доскролились до конца страницы */
  const setPageValue = useCallback(() => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const height = document.body.scrollHeight;

    if (scrollPosition >= height &&
      requestPassportsStatus === RequestStatuses.Initial &&
      (passportsList.length) < passportListTotal
    ) {
      setPage(page + 1);
    }
  }, [page, requestPassportsStatus, passportListTotal, passportsList.length]);

  /* если категория изменилась, то сбрасываем значение page */
  useEffect(() => {
    setPage(1);
  }, [categoryId, filterCostValue, filterLifeTime, filterApplicationArea, complexityValue]);

  /* получение списка паспортов */
  useEffect(() => {
    dispatch(fetchPassportsAction(
      {
        page,
        per_page: perPage,
        category: categoryId,
        price: filterCostValue,
        complexity: complexityValue,
        application_area: filterApplicationArea.length > 0 ? filterApplicationArea.join(",") : undefined,
        exploitation_years: { start_at: filterLifeTime?.lifeTimeStart === "" ? "0" : filterLifeTime.lifeTimeStart }
      }
    ));
  }, [dispatch, page, categoryId, filterCostValue, complexityValue, filterApplicationArea, filterLifeTime]);

  /* слушаем событие скролла */
  useEffect(() => {
    document.addEventListener("scroll", setPageValue, false);
    return () => document.removeEventListener("scroll", setPageValue, false);
  }, [setPageValue]);

  const onSubmit = (values: LifeTimeValues): void => {
    dispatch(reset({}));
    dispatch(setFilterLifeTime({
      lifeTimeStart: values.lifetime_start,
      lifeTimeEnd: values.lifetime_end
    }));
  };

  const setCostFilter = (value: number): void => {
    dispatch(reset({}));
    dispatch(setFilterCostValue(value));
  };

  const renderPassportsList = useMemo((): JSX.Element[] => {
    return passportsList.map((element: PassportElement): JSX.Element => (
      <Card
        key={uuidv4()}
        element={element}
        classes={{ root: styles.Card }}
      />
    ))
  }, [passportsList]);

  const putApplicationAreaValue = (value: number): void => {
    if (requestPassportsStatus !== RequestStatuses.Pending) {
      dispatch(reset({}));

      const index = filterApplicationArea.findIndex((item: number): boolean => item === value);

      if (index < 0) {
        dispatch(setFilterApplicationArea([...filterApplicationArea, value]));
      } else {
        dispatch(setFilterApplicationArea(filterApplicationArea.slice(0, index).concat(filterApplicationArea.slice(Number(index) + 1))));
      }
    }
  };

  const setComplexityFilter = (value: number): void => {
    dispatch(reset({}));
    dispatch(setFilterComplexityValue(value));
  };

  const resetFilters = (): void => {
    setPage(1);
    dispatch(resetFiltersValues({}));
  };

  const toggleFilters = () => setFiltersActive(!isFiltersActive);

  return (
    <Page isFooter={false} classes={{ root: styles.Root }}>
      <section className={styles.Handbook}>
        <Navigation />
        <section className={styles.HandbookPage}>
          {
            (levelId || categoryId) && (
              <>
                <p className={styles.SectionTitle}>
                  { level ? level.title : <Skeleton /> }
                </p>
                <h3 className={styles.SectionSubTitle}>{ category ? category.title : <Skeleton height={31} /> }</h3>
              </>
            )
          }
          <div className={styles.HandbookPageContainer}>
            <div className={styles.HandbookControls}>
              <div className={styles.FiltersButton} onClick={toggleFilters} data-active={isFiltersActive}>Фильтр</div>
              <div className={styles.Modes}>
                <div className={styles.ModesX1} data-active={mode === 1} onClick={() => setMode(1)} />
                <div className={styles.ModesX2} data-active={mode === 2} onClick={() => setMode(2)} />
              </div>
            </div>
            <div className={styles.HandbookFiltration} data-active={isFiltersActive}>

              <div className={styles.HandbookFiltrationContainer}>
                <ApplicationArea>
                  <div onClick={(): void => putApplicationAreaValue(2)}>
                    <ApplicationAreaFilterElement title="Площади" category={Filters.Fountain} isActive={!!filterApplicationArea.find((item: number) => item === 2)} />
                  </div>
                  <div onClick={(): void => putApplicationAreaValue(1)}>
                    <ApplicationAreaFilterElement title="Дворы" category={Filters.Сourtyard} isActive={!!filterApplicationArea.find((item: number) => item === 1)} />
                  </div>
                  <div onClick={(): void => putApplicationAreaValue(5)}>
                    <ApplicationAreaFilterElement title="Набережные" category={Filters.Embankment} isActive={!!filterApplicationArea.find((item: number) => item === 5)} />
                  </div>
                  <div onClick={(): void => putApplicationAreaValue(3)}>
                    <ApplicationAreaFilterElement title="Улицы" category={Filters.Street} isActive={!!filterApplicationArea.find((item: number) => item === 3)} />
                  </div>
                  <div onClick={(): void => putApplicationAreaValue(6)}>
                    <ApplicationAreaFilterElement title="Фасады" category={Filters.Facade} isActive={!!filterApplicationArea.find((item: number) => item === 6)} />
                  </div>
                  <div onClick={(): void => putApplicationAreaValue(4)}>
                    <ApplicationAreaFilterElement title="Парки" category={Filters.Park} isActive={!!filterApplicationArea.find((item: number) => item === 4)} />
                  </div>
                </ApplicationArea>
              </div>

              <div className={styles.HandbookFiltrationContainer}>
                <ComplexityInstallation
                  setComplexityFilter={requestPassportsStatus === RequestStatuses.Pending ? undefined : setComplexityFilter}
                  complexity={complexityValue}
                />
              </div>

              <div className={styles.HandbookFiltrationContainer}>
                <Cost setCostFilter={requestPassportsStatus === RequestStatuses.Pending ? undefined : setCostFilter} cost={filterCostValue} />
              </div>

              <div className={styles.FilterElementLifeTime}>
                <span>Срок эксплуатации, год</span>
                <div className={styles.FilterElementInputContainer}>
                  <Form
                    onSubmit={onSubmit}
                    // validate={(values): any => {
                    //   const errors: any = {};
                    //
                    //   if (!values.lifetime_end) {
                    //     errors.lifetime_end = "Обязательное поле"
                    //   }
                    //
                    //   if (isNaN(Number(values.lifetime_end)) && values.lifetime_end) {
                    //     errors.lifetime_end = "Неверный формат"
                    //   }
                    //
                    //   return errors;
                    // }}
                    render={({ handleSubmit, form }): JSX.Element => (
                      <form onChange={() => handleSubmit()} className={styles.FilterElementLifeTimeForm} onSubmit={handleSubmit}>
                        <Input
                          name="lifetime_start"
                          type="text"
                          classes={{ root: styles.FilterElementInput }}
                          placeholder="от"
                          initialValue={filterLifeTime?.lifeTimeStart === "0" ? "" : filterLifeTime?.lifeTimeStart}
                        />
                        {/*<Input*/}
                        {/*  name="lifetime_end"*/}
                        {/*  type="text"*/}
                        {/*  classes={{ root: styles.FilterElementInput }}*/}
                        {/*  placeholder="до"*/}
                        {/*  initialValue={filterLifeTime?.lifeTimeEnd}*/}
                        {/*/>*/}
                        <span onClick={() => {
                          form.reset();
                          resetFilters();
                        }}
                        className={styles.ResetFilter}>
                          Сбросить
                        </span>
                      </form>
                    )}
                  />
                </div>
              </div>
            </div>

            {
              passportsList.length === 0 && (filterCostValue || complexityValue || filterApplicationArea.length > 0 || (filterLifeTime?.lifeTimeStart && filterLifeTime?.lifeTimeEnd)) &&
              requestPassportsStatus === RequestStatuses.Initial && !isActiveSkeleton ?
              (
                <div className={styles.HandbookEmptyContainer}>
                  <EmptyPassports />
                  <p>К сожалению, таких паспортов нет</p>
                  <span>Попробуйте скорректировать данные для поиска</span>
                </div>
              ) :
                (
                  <div className={styles.PageElements} data-mode={mode}>
                    {
                      isActiveSkeleton ? (
                        <Skeleton className={styles.SkeletonItems} count={perPage} />
                      ) : renderPassportsList
                    }
                  </div>
                )
            }
          </div>
          {
            requestPassportsStatus === RequestStatuses.Pending && (
              <div className={styles.Loader}>
                <Loader />
              </div>
            )
          }
        </section>
      </section>
    </Page>
  )
}

export default Handbook;
