// Libraries
import { configureStore, EnhancedStore, getDefaultMiddleware  } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";

// Reducers
import authReducer from "store/reducers/authReducer";
import usefulReducer from "store/reducers/usefulReducer";
import passportsReducer from "store/reducers/passportsReducer";
import navigationLevelsReducer from "store/reducers/navigationLevelsReducer";
import projectsReducer from "store/reducers/projectsReducer";
import searchReducer from "store/reducers/searchReducer";
import notificationsReducer from "store/reducers/notificationsReducer";
import invited from "store/reducers/invitedReducer";
import filtration from "store/reducers/filtrationReducer";
import content from "store/reducers/contentReducer";
import modals from "store/reducers/modalsReducer";
import errors from "store/reducers/errorsReducer";

// Sagas
import rootSaga from "./sagas";

const configureAppStore = (): EnhancedStore  => {
  const sagaMiddleware  = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const middleware = [
    ...getDefaultMiddleware({ thunk: false }),
    ...middlewares
  ];
  const rootReducer = combineReducers({
    auth: authReducer,
    useful: usefulReducer,
    passports: passportsReducer,
    levels: navigationLevelsReducer,
    projects: projectsReducer,
    search: searchReducer,
    notifications: notificationsReducer,
    invited,
    filtration,
    content,
    modals,
    errors
  });

  const store = configureStore({
    reducer: rootReducer,
    middleware
  });

  sagaMiddleware.run(rootSaga);

  return store;
}

const store = configureAppStore();

export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
