// Libraries
import React, {MouseEvent, useEffect, useMemo, useRef, useState} from "react";
import { Link } from "react-router-dom";
import Dotdotdot from "react-dotdotdot";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import classNames from "classnames";

// Components
import ModalWithListProjects from "components/ModalWindows/ModalWithListProjects/ModalWithListProjects";
import ApplicationArea, {ApplicationAreaFilterElement} from "components/Filtration/components/ApplicationArea/ApplicationArea";

// Interfaces
import {ApplicationAreas, PassportElement, User} from "interfaces/index";

// Styles
import styles from "./Card.scss";

export interface Element {
  readonly id: number;
  readonly img: string;
  readonly title: string;
  readonly description: string;
  readonly application_areas: ApplicationAreas;
}

interface CardProps {
  readonly element: PassportElement;
  readonly classes: {
    readonly root?: string;
  }
}

const Card = ({ element: { id, title, description, main_image, application_areas }, classes }: CardProps): JSX.Element => {
  const [isActiveModalWindow, setActiveModalWindow] = useState<boolean>(false);
  const refElement = useRef(null);
  const refElementBtn = useRef(null);
  const user: User = Cookies.getJSON("axp-user");

  useEffect(() => {
    document.addEventListener("click", closeModal, false);

    return () => document.removeEventListener("click", closeModal, false);
  }, []);

  const closeModal = (e: Event): void => {
    e.preventDefault();
    // @ts-ignore
    if ((refElement.current && !refElement.current?.contains(e.target) && e.target !== refElementBtn.current)) {
      setActiveModalWindow(false);
    }
  };

  const openModal = (e: MouseEvent<HTMLDivElement>): void => {
    setActiveModalWindow(true);
    e.preventDefault();
  };

  const renderApplicationAreas = useMemo((): JSX.Element[] => {
    return application_areas.map((filter): JSX.Element => (
      <ApplicationAreaFilterElement
        key={uuidv4()}
        category={filter.slug}
        isActive
        title={filter.title}
      />
    ))
  }, [application_areas]);

  return (
    <div className={classNames(classes?.root, styles.CardContainer)}>
      { isActiveModalWindow && <ModalWithListProjects passportId={id} ref={refElement} /> }
      <Link
        className={styles.Card}
        to={`/passport/${id}`}
        onClick={(e: MouseEvent<HTMLLinkElement>): void => { isActiveModalWindow && e.preventDefault() }}>
        {
          user && (
            <>
              {
                !isActiveModalWindow ? (
                  <div
                    ref={refElementBtn}
                    className={styles.CardPlus}
                    onClick={(e): void => openModal(e)}
                  />
                ) : (
                  <div
                    ref={refElementBtn}
                    className={styles.CardClose}
                    onClick={(e): void => {
                      e.preventDefault();
                      setActiveModalWindow(false);
                    }}
                  />
                )
              }
            </>
          )
        }
        <img src={main_image?.length ? main_image[0].url : "/imgs/card_3.jpg"} alt={title} />
        <Dotdotdot clamp={4}>
          <h5 title={title}>{title}</h5>
        </Dotdotdot>
        <Dotdotdot clamp={3}>
          <p title={description}>{description}</p>
        </Dotdotdot>
        <div className={styles.CardIcons}>
          <ApplicationArea hasTitle={false}>
            { renderApplicationAreas }
          </ApplicationArea>
        </div>
      </Link>
    </div>
  )
}

export default Card;
