import { RegisterFormValues } from "interfaces/index";
import {createAction} from "@reduxjs/toolkit";
import {ChangeUserEmailI} from "../../../Api";

export interface ChangeUserInfoParams {
  name?: string | null;
  surname?: string | null;
  patronymic?: string | null;
  birthday_at?: Date;
  gender_id?: number | null;
  city_id?: number | null;
}

export const authUserAction = createAction<RegisterFormValues>("POST/LOGIN_USER");
export const fetchUserAction = createAction<RegisterFormValues>("POST/REGISTER_USER");
export const logoutUserAction = createAction("POST/LOGOUT_USER");
export const getUserInfoAction = createAction("GET/GET_USER_INFO");
export const resetPasswordAction = createAction<any>("POST/RESET_PASSWORD");
export const changeSettingsUserAction = createAction<any>("POST/CHANGE_SETTINGS");
export const removeUserAccountAction = createAction("DELETE/REMOVE_USER_ACCOUNT");
export const changeUserInfoAction = createAction<ChangeUserInfoParams>("PUT/CHANGE_USER_INFO");
export const fetchCityListAction = createAction("GET/FETCH_CITY_LIST");
export const uploadAvatarAction = createAction<any>("POST/UPLOAD_AVATAR");
export const changeUserEmailAction = createAction<ChangeUserEmailI>("POST/CHANGE_USER_EMAIL");
export const authSocialAction = createAction<any>("POST/AUTH_SOCIAL");
export const authTemporaryUserAction = createAction<any>("POST/AUTH_TEMPORARY_ACTION");
export const restorePasswordAction = createAction<any>("POST/RESTORE_PASSWORD");
export const resetForgotPasswordAction = createAction<any>("POST/RESET_FORGOT_PASSWORD");
