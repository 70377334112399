// Libraries
import React, {useState} from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { upperFirst } from "lodash";

// Styles
import styles from "../Handbook.scss";

// Assets
import {ReactComponent as CheckMark} from "../../../assets/check_mark_white.svg";

interface MainMenuElementProps {
  readonly url?: string;
  readonly title: string;
  readonly children?: React.ReactChild;
  readonly variant: Variant;
  readonly isActive: boolean;
  readonly elementsCount: number;
}

export enum Variant {
  Main,
  Sub
}

const MenuElement = ({ url, title, children, variant, isActive, elementsCount, ...props }: MainMenuElementProps): JSX.Element => {
  const [isOpen, setOpen] = useState<boolean>(isActive);
  const history = useHistory();

  return (
    <li
      className={classNames([
        {[styles.MainMenuList]: variant === Variant.Main},
        {[styles.SubMenuList]: variant === Variant.Sub},
        {[styles.MenuElementActive]: isOpen}
      ])}
      onClick={(): void => setOpen(!isOpen)}
      {...props}
    >
      <div
        className={classNames([
          {[styles.MainMenuTitle]: variant === Variant.Main},
          {[styles.SubMenuTitle]: variant === Variant.Sub}
        ])}
        onClick={(): void => url && history.push(url)}
      >
        {upperFirst(title)}
        { elementsCount > 0 && (
          <div className={styles.IconContainer} >
            <CheckMark className={isOpen ? styles.IconContainerActive : ""} />
          </div>
        )}
      </div>
      {isOpen && children}
    </li>
  );
}

export default MenuElement;
