// Libraries
import React, {useCallback, useEffect, useRef, useState} from "react";
import classNames from "classnames";

// Assets
import { ReactComponent as CloseIcon } from "assets/close.svg";

// Styles
import styles from "./UsuallyModal.scss";

interface UsuallyModalProps {
  readonly classes?: {
    readonly root?: string;
    readonly content?: string;
  }
  readonly maxWidth?: number | string;
  readonly children: React.ReactChild;
  readonly title?: string;
  readonly isShowModal: boolean;
  readonly setShowModal: (flag: boolean) => void;
  readonly rightPosition?: number;
  readonly topPosition?: number;
  readonly autoPosition?: boolean;
  readonly isCloseOnClickOutside?: boolean;
}

interface ElCoordinatesI {
  readonly x: number;
  readonly y: number;
}

const UsuallyModal = (
  {
    classes = {},
    children,
    maxWidth = 387,
    title,
    isShowModal,
    setShowModal,
    rightPosition = undefined,
    topPosition = undefined,
    autoPosition = true,
    isCloseOnClickOutside = true
  }: UsuallyModalProps): JSX.Element => {
  const refModal = useRef(null);
  const [elCoordinates, setElCoordinates] = useState<ElCoordinatesI | null>(null);

  const onClose = useCallback((): void => {
    setElCoordinates(null);
    setShowModal(false);
  }, [setShowModal]);

  const handlerClick = useCallback((e: any) => {
    const element = e.target;
    const dataAttribute = element?.parentNode?.getAttribute("data-modal");
    // @ts-ignore
    if (isCloseOnClickOutside && !refModal.current?.contains(e.target) && (dataAttribute !== "true" && !element.hasAttribute("data-modal"))) {
      onClose();
    }

    if (element && autoPosition && dataAttribute && dataAttribute === "true") {
      const clickCoordinates = element.getBoundingClientRect();
      setElCoordinates({ x: clickCoordinates.x, y: (clickCoordinates.y as number) - 200 });
    }

    if (element && autoPosition && element.getAttribute("data-modal") === "true") {
      const clickCoordinates = element.getBoundingClientRect();
      setElCoordinates({ x: clickCoordinates.x, y: (clickCoordinates.y as number) + 20 });
    }
  }, [onClose, autoPosition]);

  useEffect(() => {
    document.addEventListener("click", handlerClick, false);

    return () => document.removeEventListener("click", handlerClick, false);
  }, [handlerClick]);

  return (
    <div
      ref={refModal}
      style={{
        maxWidth: `${maxWidth}px`,
        display: isShowModal ? "initial" : "none",
        top: topPosition || elCoordinates?.y,
        left: !rightPosition ? "initial" : elCoordinates?.x,
        right: rightPosition
      }}
      className={classNames(styles.Root, classes?.root)}
    >
      <CloseIcon className={styles.CloseIcon} onClick={onClose} />
      {title && <h4>{title}</h4>}
      <div className={classNames(styles.ModalContent, classes?.content)}>
        {children}
      </div>
    </div>
  )
}

export default UsuallyModal;
