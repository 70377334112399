// Libraries
import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import * as locale from "dayjs/locale/ru";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Actions
import {readNotificationAction} from "store/sagas/notifications/actions";

// Routes
import routes from "routes";

// Enums
import {VariantNotifications} from "enums/index";

// Interfaces
import {NotificationItem} from "store/reducers/notificationsReducer";
import {Notification} from "interfaces/index";

// Styles
import styles from "./ActionsDay.scss";

export interface ActionsDayProps {
  readonly classes?: {
    readonly person?: string;
    readonly personName?: string;
    readonly elementData?: string;
  };
  readonly isShowAllBtn?: boolean;
  readonly item: NotificationItem;
  readonly variant: VariantNotifications;
}

const today = dayjs(new Date()).locale(locale).format("DD MMMM");
const yesterday  = dayjs(new Date()).subtract(1, "day").locale(locale).format("DD MMMM");

const ActionsDay = ({ item, classes = {}, isShowAllBtn, variant }: ActionsDayProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const notifications: Notification[] = useAppSelector(state => state.notifications.notificationsList);

  const readNotification = (id: string): void => {
    dispatch(readNotificationAction([id]));
  };

  const renderPeople = (element: NotificationItem): JSX.Element => {
    return (
      <div className={classNames(styles.Person, classes?.person)}>
        {
          element.list.slice(0, isShowAllBtn ? 4 : element.list.length).map((elementList: Notification): JSX.Element => (
            <div className={styles.PersonContainer} key={`history-element-${elementList.id}`}>
              <img src={elementList.data.avatar.url} alt=""/>
              <div className={styles.PersonContainerContent}>
                <p>
                  <span className={classNames(styles.PersonName, classes?.personName)}>{elementList.data.user_name}</span>
                  <span className={styles.Action}>
                  <span>{elementList.data.text}</span>
                  {variant === VariantNotifications.Notification && !notifications.find((notification) => notification.id === elementList.id)?.read_at && <i />}
                </span>
                </p>
                {
                  variant === VariantNotifications.Notification &&
                  <Link to={routes.project(elementList.data.project_id)} onClick={(): void => readNotification(elementList.id)}>Посмотреть</Link>
                }

              </div>
            </div>
          ))
        }
      </div>
    )
  };

  const renderDate = () => {
    if (today === item.date) {
      return "Сегодня";
    }

    if (yesterday === item.date) {
      return `Вчера, ${item.date}`;
    }

    return item.date;
  };

  return (
    <>
      <div
        className={classNames(
          styles.ElementDate,
          classes?.elementData,
      )}
      >
        {renderDate()}
      </div>
      { renderPeople(item) }
    </>
  )
}

export default ActionsDay;
