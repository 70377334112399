// Libraries
import React, {useMemo} from "react";
import { Link } from "react-router-dom";
import {v4 as uuidv4} from "uuid";

// Interfaces
import {NotificationItem} from "store/reducers/notificationsReducer";

// Enums
import { VariantNotifications } from "enums/index";

// Components
import ActionsDay from "./ActionsDay/ActionsDay";

// Styles
import styles from "../../History.scss"

export interface HistoryElementProps {
  readonly data: NotificationItem[];
  readonly setShowNotificationModal?: (flag: boolean) => void;
  readonly classes?: {
    readonly person?: string;
    readonly personName?: string;
    readonly elementData?: string;
  };
  readonly isShowAllBtn?: boolean;
  readonly variant: VariantNotifications;
}

const HistoryElement = ({ data, classes = {}, isShowAllBtn, setShowNotificationModal, variant }: HistoryElementProps): JSX.Element => {
  const renderActionsDay = useMemo((): JSX.Element[] => data.slice(0, isShowAllBtn ? 1 : data.length).map((item): JSX.Element => (
    <ActionsDay isShowAllBtn={isShowAllBtn} classes={classes} key={uuidv4()} item={item} variant={variant} />
  )), [classes, data, isShowAllBtn, variant]);

  return (
    <>
      { renderActionsDay }
      {
        isShowAllBtn && (
          <Link
            className={styles.ShowAllBtn}
            to="/private-cabinet/notifications"
            onClick={(): void => setShowNotificationModal && setShowNotificationModal(false)}
          >
            Показать всё
          </Link>
        )
      }
    </>
  )
}

export default HistoryElement;
