import {call, put, takeLatest} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {RequestStatuses} from "enums/index";
import {fetchBanner} from "../../../Api";
import {fetchBannerAction} from "./actions";
import {putBanner, putRequestFetchBannerStatus} from "../../reducers/contentReducer";

export function* contentSagaWatcher(): Generator {
  yield takeLatest(fetchBannerAction, fetchBannerWorker);
} 

function* fetchBannerWorker(): Generator {
  yield put(putRequestFetchBannerStatus(RequestStatuses.Pending));
  
  try {
    const res = yield call(fetchBanner);
    yield put(putBanner((res as AxiosResponse).data));
    yield put(putRequestFetchBannerStatus(RequestStatuses.Success));
    yield put(putRequestFetchBannerStatus(RequestStatuses.Initial));
  } catch (e) {
    yield put(putRequestFetchBannerStatus(RequestStatuses.Error));
  }
}
