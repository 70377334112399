// Libraries
import React, {forwardRef, Ref} from "react";
import classNames from "classnames";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Styles
import styles from "./ContextMenu.scss";

interface ContextMenuProps {
  readonly children: React.ReactChild;
  readonly classes?: {
    readonly root?: string;
  };
}

const ContextMenu = ({ children, classes = {} }: ContextMenuProps, ref: Ref<HTMLDivElement> ): JSX.Element => {
  const coordinates = useAppSelector(state => state.modals.coordinates);
  return (
    <div
      data-context-menu="true"
      className={classNames(styles.Root, classes?.root)}
      ref={ref}
      style={{
        top: coordinates?.y || 0,
        left: coordinates?.x || 0
      }}
    >
      {
        children
      }
    </div>
  )
}

export default forwardRef<HTMLDivElement, ContextMenuProps>(ContextMenu);
