// Libraries
import {createAction} from "@reduxjs/toolkit";

// Types
import {ChangeProjectTitleParams} from "Api";

export const fetchProjectsAction = createAction<any>("GET/FETCH_PROJECTS");
export const fetchProjectIconsAction = createAction("GET/FETCH_PROJECT_ICONS");
export const createProjectAction = createAction<any>("POST/CREATE_PROJECT");
export const addPassportToProjectAction = createAction<any>("POST/ADD_PASSPORT_TO_PROJECT");
export const removePassportFromProjectAction = createAction<any>("POST/REMOVE_PASSPORT_FROM_PROJECT");
export const removeProjectAction = createAction<number>("DELETE/REMOVE_PROJECT");
export const changeProjectTitleAction = createAction<ChangeProjectTitleParams>("PUT/CHANGE_PROJECT_TITLE");
