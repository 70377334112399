// Libraries
import React, {useEffect, useState} from "react";
import {Form} from "react-final-form";

// Components
import Button from "components/Button/Button";
import {Input} from "components/Form";
import {ReactComponent as HidePswIcon} from "assets/show_psw.svg";
import {ReactComponent as SuccessIcon} from "assets/success.svg";
import {ReactComponent as ShowPasswordIcon} from "assets/show_password.svg";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Helpers
import { requireField } from "helpers/Validator";

// Actions
import {resetPasswordAction} from "store/sagas/auth/actions";

// Reducers
import {RequestStatuses} from "store/reducers/authReducer";

// Styles
import styles from "./ChangePasswordForm.scss";

interface ResetPasswordValues {
  readonly confirm_password: string;
  readonly new_password: string;
  readonly old_password: string;
}

const ChangePasswordForm = (): JSX.Element => {
  const [isShowOldPsw, setShowOldPsw] = useState<boolean>(false);
  const [isShowNewPsw, setShowNewPsw] = useState<boolean>(false);
  const [isShowConfirmPsw, setShowConfirmPsw] = useState<boolean>(false);
  const [isSuccessChangePsw, setSuccessChangePsw] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const requestResetPasswordStatus = useAppSelector(state => state.auth.requestResetPasswordStatus);

  useEffect(() => {
    if (requestResetPasswordStatus === RequestStatuses.Success) {
      setSuccessChangePsw(true);
    }
  }, [requestResetPasswordStatus]);

  const onSubmit = (values: ResetPasswordValues): void => {
    const payload = {
      old_password: values.old_password,
      password: values.new_password,
      password_confirmation: values.confirm_password
    }
    dispatch(resetPasswordAction(payload));
  };

  return (
    <>
      { !isSuccessChangePsw ? (
        <Form
          onSubmit={onSubmit}
          validate={(values: ResetPasswordValues) => {
            const errors = {};

            if (values.confirm_password !== values.new_password) {
              // @ts-ignore
              errors.confirm_password = "Пароли должны совпадать";
            }

            return errors;
          }}
          render={({ handleSubmit, form }): JSX.Element => (
            <form className={styles.ChangePasswordForm} onSubmit={handleSubmit}>
              <div className={styles.ChangePasswordFormContainer}>
                <div className={styles.ChangePasswordFormField}>
                  <label>Старый пароль</label>
                  <div className={styles.ChangePasswordFormFieldContainer}>
                    <Input
                      placeholder=""
                      type={isShowOldPsw ? "text" : "password"}
                      name="old_password"
                      validators={[requireField]}
                    />
                    {
                      isShowOldPsw ?
                        <ShowPasswordIcon style={{ top: "6px", right: "9px" }} onClick={(): void => setShowOldPsw(false)} /> :
                        <HidePswIcon onClick={(): void => setShowOldPsw(true)} />
                    }
                  </div>
                  <span>Забыли пароль?</span>
                </div>
                <div className={styles.ChangePasswordFormField}>
                  <label>Новый пароль</label>
                  <div className={styles.ChangePasswordFormFieldContainer}>
                    <Input
                      placeholder=""
                      type={isShowNewPsw ? "text" : "password"}
                      name="new_password"
                      validators={[requireField]}
                    />
                    {
                      isShowNewPsw ?
                        <ShowPasswordIcon style={{ top: "6px", right: "9px" }} onClick={(): void => setShowNewPsw(false)} /> :
                        <HidePswIcon onClick={(): void => setShowNewPsw(true)} />
                    }
                  </div>
                </div>
                <div className={styles.ChangePasswordFormField}>
                  <label>Новый пароль повторно</label>
                  <div className={styles.ChangePasswordFormFieldContainer}>
                    <Input
                      placeholder=""
                      type={isShowConfirmPsw ? "text" : "password"}
                      name="confirm_password"
                      validators={[requireField]}
                    />
                    {
                      isShowConfirmPsw ?
                        <ShowPasswordIcon style={{ top: "6px", right: "9px" }} onClick={(): void => setShowConfirmPsw(false)} /> :
                        <HidePswIcon onClick={(): void => setShowConfirmPsw(true)} />
                    }
                  </div>
                </div>
                <Button
                  title="Сохранить"
                  classes={{ root: styles.ChangePasswordFormFieldBtn }}
                  type="submit"
                />
              </div>
            </form>
          )}
        />
      ) : (
        <div className={styles.SuccessChangePsw}>
          <SuccessIcon />
          <span>Ваш пароль успешно изменен!</span>
        </div>
      )}
    </>
  )
};

export default ChangePasswordForm;
