// Libraries
import React from "react";
import {Field} from "react-final-form";
import classNames from "classnames";
import { Link } from "react-router-dom";

// Styles
import styles from "./Checkbox.scss";

interface CheckboxProps {
  readonly label: string;
  readonly name: string;
  readonly initialValue: boolean;
  readonly classes?: {
    readonly root?: string;
  }
  readonly callback?: (value: any) => any;
  readonly validators?: any[];
  readonly hasLink?: boolean;
  readonly to?: string;
}

// @ts-ignore
const composeValidators = (...validators: (() => string | undefined)[]) => value =>
  // @ts-ignore
  validators.reduce((error, validator) => error || validator(value), undefined)

const Checkbox = ({ classes = {}, label, name, initialValue, callback = () => {}, validators = [], hasLink, to }: CheckboxProps): JSX.Element => (
  <label className={classNames(styles.CustomCheckbox, classes?.root)}>
    <Field
      name={name}
      component="input"
      type="checkbox"
      initialValue={initialValue}
      validate={composeValidators(...validators)}
    >
      {({ input, meta }) => (
        <>
          {/*// @ts-ignore*/}
          <input type="checkbox" {...input} />
          <span
            className={meta.error && (meta.touched || meta.submitSucceeded) ? styles.CustomCheckboxError : ""}
            onClick={callback}>
            {
              hasLink ? <Link target="_blank" rel="noreferrer" to={to}>{ label }</Link> : <>{label}</>
            }
          </span>
        </>
      )}
    </Field>
  </label>
);

export default Checkbox;
