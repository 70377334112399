// Libraries
import React, {useState} from "react";
import Cookies from "js-cookie";

// Components
import Page from "components/Page/Page";
import Button from "components/Button/Button";
import LickbessComponent, {Variant} from "./components/LickbessList/LickbessList";

// Styles
import styles from "./Usefull.scss";

const Useful = (): JSX.Element => {
  const [variant, setVariant] = useState<Variant>(Cookies.getJSON("axp-useful-variant") || Variant.Lickbess);

  const setVariantPage = (variantPage: Variant): void => {
    Cookies.set("axp-useful-variant", String(variantPage));
    setVariant(variantPage);
  };

  return (
    <Page>
      <section className={styles.Root}>
        <div className={styles.Container}>
          <div className={styles.Tabs}>
            <Button
              title="Ликбезы"
              classes={{ root: styles.Tab, active: variant === Variant.Lickbess ? styles.ActiveTab : "" }}
              type="button"
              callback={(): void => setVariantPage(Variant.Lickbess)}
            />
            <Button
              title="Чек-листы"
              classes={{ root: styles.Tab, active: variant === Variant.CheckList ? styles.ActiveTab : "" }}
              type="button"
              callback={(): void => setVariantPage(Variant.CheckList)}
            />
          </div>

          {
            variant === Variant.Lickbess && <LickbessComponent variant={Variant.Lickbess} />
          }

          {
            variant === Variant.CheckList && <LickbessComponent variant={Variant.CheckList} />
          }
        </div>
      </section>
    </Page>
  )
};

export default Useful;
