import React from "react";
import styles from "./Loader.scss";

const Loader = (): JSX.Element => {
  return (
    <div className={styles.Root}>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  )
};

export default Loader;
