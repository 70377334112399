import { createSlice } from "@reduxjs/toolkit";
import {VariantActionWithAuth} from "../../enums";

export enum RequestStatuses {
  Initial,
  Pending,
  Success,
  Error
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    requestRegisterStatus: RequestStatuses.Initial,
    errors: [],
    user: undefined,
    requestLoginStatus: RequestStatuses.Initial,
    requestLogoutStatus: RequestStatuses.Initial,
    requestGetUserInfoStatus: RequestStatuses.Initial,
    requestResetPasswordStatus: RequestStatuses.Initial,
    requestChangeSettingsStatus: RequestStatuses.Initial,
    requestRemoveUserAccountStatus: RequestStatuses.Initial,
    requestChangeUserInfoStatus: RequestStatuses.Initial,
    cityList: [],
    requestFetchCityListStatus: RequestStatuses.Initial,
    requestUploadAvatarStatus: RequestStatuses.Initial,
    requestChangeUserEmailStatus: RequestStatuses.Initial,
    requestAuthSocialStatus: RequestStatuses.Initial,
    requestAuthTemporaryStatus: RequestStatuses.Initial,
    requestRestorePasswordStatus: RequestStatuses.Initial,
    requestResetForgotPasswordStatus: RequestStatuses.Initial,
    email: undefined,
    code: undefined,
    variantAction: VariantActionWithAuth.Empty
  },
  reducers: {
    setVariantAction(state, action): void {
      state.variantAction = action.payload;
    },
    setEmail(state, action): void {
      state.email = action.payload;
    },
    setCode(state, action): void {
      state.code = action.payload;
    },
    putUser(state, action) {
      state.user = action.payload;
    },
    setLoginStatus(state, action) {
      state.requestLoginStatus = action.payload;
    },
    setRegisterStatus(state, action) {
      state.requestRegisterStatus = action.payload;
    },
    setLogoutStatus(state, action) {
      state.requestLogoutStatus = action.payload;
    },
    setRequestResetPasswordStatus(state, action) {
      state.requestResetPasswordStatus = action.payload;
    },
    setErrors(state, action) {
      state.errors = action.payload
    },
    setGetUserInfoStatus(state, action) {
      state.requestGetUserInfoStatus = action.payload;
    },
    setRequestChangeSettingsStatus(state, action) {
      state.requestChangeSettingsStatus = action.payload;
    },
    setRequestRemoveUserAccountStatus(state, action) {
      state.requestRemoveUserAccountStatus = action.payload;
    },
    setRequestChangeUserInfoStatus(state, action) {
      state.requestChangeUserInfoStatus = action.payload;
    },
    putCityList(state, action) {
      state.cityList = action.payload;
    },
    setRequestFetchCityListStatus(state, action) {
      state.requestFetchCityListStatus = action.payload;
    },
    setRequestUploadAvatarStatus(state, action) {
      state.requestUploadAvatarStatus = action.payload;
    },
    setRequestChangeUserEmailStatus(state, action) {
      state.requestChangeUserEmailStatus = action.payload;
    },
    setUserEmail(state, action) {
      // @ts-ignore
      state.user.email = action.payload;
    },
    putRequestAuthSocialStatus(state, action): void {
      state.requestAuthSocialStatus = action.payload;
    },
    putRequestAuthTemporaryStatus(state, action): void {
      state.requestAuthTemporaryStatus = action.payload;
    },
    putRequestRestorePasswordStatus(state, action): void {
      state.requestRestorePasswordStatus = action.payload;
    },
    putRequestResetForgotPasswordStatus(state,action): void {
      state.requestResetForgotPasswordStatus = action.payload;
    }
  }
});

export default authSlice.reducer;
export const {
  putUser,
  setErrors,
  setRegisterStatus,
  setLoginStatus,
  setLogoutStatus,
  setGetUserInfoStatus,
  setRequestResetPasswordStatus,
  setRequestChangeSettingsStatus,
  setRequestRemoveUserAccountStatus,
  setRequestChangeUserInfoStatus,
  setRequestFetchCityListStatus,
  putCityList,
  setRequestUploadAvatarStatus,
  setRequestChangeUserEmailStatus,
  setUserEmail,
  putRequestAuthSocialStatus,
  putRequestAuthTemporaryStatus,
  putRequestRestorePasswordStatus,
  putRequestResetForgotPasswordStatus,
  setEmail,
  setCode,
  setVariantAction
} = authSlice.actions;
