// Libraries
import React, {forwardRef, Ref} from "react";
import classNames from "classnames";

// Components
import ComplexityInstallation, {Complexity} from "components/Filtration/components/ComplexityInstallation/ComplexityInstallation";
import Cost, {CostEnum} from "components/Filtration/components/Cost/Cost";
import ApplicationArea, {ApplicationAreaFilterElement, Filters} from "components/Filtration/components/ApplicationArea/ApplicationArea";
import {ReactComponent as CloseIcon} from "assets/close.svg";

// Styles
import styles from "./TableEqualsModal.scss";

interface TableEqualsModalProps {
  readonly setOpenModal: (flag: boolean) => void;
}

const TableEqualsModal = ({ setOpenModal }: TableEqualsModalProps, ref: Ref<HTMLDivElement>): JSX.Element => (
  <div ref={ref} className={styles.Root}>
    <CloseIcon onClick={(): void => setOpenModal(false)} />
    <div className={styles.TableContainer}>
      <header>
        <div className={styles.TableColumn} />
        <div className={styles.TableColumn}>Сложность монтажа</div>
        <div className={styles.TableColumn}>Стоимость</div>
        <div className={styles.TableColumn}>Срок эксплуатации</div>
        <div className={styles.TableColumn}>Пространство</div>
      </header>
      <div className={styles.TableRow}>
        <div className={classNames(styles.TableColumn, styles.TableColumnTitle)}>Гранитная крошка</div>
        <div className={styles.TableColumn}>
          <ComplexityInstallation hasTitle={false} />
        </div>
        <div className={styles.TableColumn}>
          <Cost cost={CostEnum.Middle} hasTitle={false} />
        </div>
        <div className={styles.TableColumn}>5 лет</div>
        <div className={styles.TableColumn}>
          <ApplicationArea
            hasTitle={false}
          >
            <ApplicationAreaFilterElement category={Filters.Fountain} isActive />
            <ApplicationAreaFilterElement category={Filters.Park} isActive />
            <ApplicationAreaFilterElement category={Filters.Street} isActive />
          </ApplicationArea>
        </div>
      </div>
      <div className={styles.TableRow}>
        <div className={classNames(styles.TableColumn, styles.TableColumnTitle)}>Гранитная крошка</div>
        <div className={styles.TableColumn}>
          <ComplexityInstallation complexity={Complexity.Hardly} hasTitle={false} />
        </div>
        <div className={styles.TableColumn}>
          <Cost cost={1} hasTitle={false} />
        </div>
        <div className={styles.TableColumn}>5 лет</div>
        <div className={styles.TableColumn}>
          <ApplicationArea
            hasTitle={false}
          >
            <ApplicationAreaFilterElement category={Filters.Fountain} isActive />
            <ApplicationAreaFilterElement category={Filters.Park} isActive />
            <ApplicationAreaFilterElement category={Filters.Street} isActive />
          </ApplicationArea>
        </div>
      </div>
      <div className={styles.TableLastRow}>
        <div className={classNames(styles.TableColumn, styles.TableColumnTitle)}>Гранитная крошка</div>
        <div className={styles.TableColumn}>
          <ComplexityInstallation complexity={Complexity.Easy} hasTitle={false} />
        </div>
        <div className={styles.TableColumn}>
          <Cost cost={CostEnum.Expensive} hasTitle={false} />
        </div>
        <div className={styles.TableColumn}>5 лет</div>
        <div className={styles.TableColumn}>
          <ApplicationArea
            hasTitle={false}
          >
            <ApplicationAreaFilterElement category={Filters.Fountain} isActive />
            <ApplicationAreaFilterElement category={Filters.Park} isActive />
            <ApplicationAreaFilterElement category={Filters.Street} isActive />
          </ApplicationArea>
        </div>
      </div>
    </div>
  </div>
);

export default forwardRef<HTMLDivElement, TableEqualsModalProps>(TableEqualsModal);
