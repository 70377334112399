import {call, put, takeLatest, takeEvery, delay} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
  copyPassportsAction,
  fetchPassportAction,
  fetchPassportsAction,
  fetchProjectWithPassportsAction,
  movePassportsAction
} from "./actions";
import {
  putPassports,
  RequestStatuses,
  setRequestPassportsStatus,
  putPassportsListTotal,
  setRequestPassportStatus,
  putPassportCollection, setRequestMovePassports, setRequestCopyPassports, removePassport, setProjectWithPassports
} from "../../reducers/passportsReducer";
import {copyPassports, fetchPassport, fetchPassports, movePassports} from "../../../Api";
import {setError} from "../../reducers/errorsReducer";

export function* passportsSagaWatcher(): Generator {
  yield takeEvery(fetchPassportsAction, passportsListWorker);
  yield takeLatest(fetchPassportAction, passportCollectionWorker);
  yield takeLatest(movePassportsAction, movePassportsWorker);
  yield takeLatest(copyPassportsAction, copyPassportsWorker);
  yield takeLatest(fetchProjectWithPassportsAction, fetchProjectWithPassportsWorker);
}

function* passportsListWorker({ payload }: any): Generator {
  yield put(setRequestPassportsStatus(RequestStatuses.Pending));

  try {
    const res = yield call(fetchPassports, payload);
    yield put(putPassportsListTotal((res as AxiosResponse).data.total));
    yield put(putPassports({ passports: (res as AxiosResponse).data, project: payload.project }));
    yield put(setRequestPassportsStatus(RequestStatuses.Success));
    yield put(setRequestPassportsStatus(RequestStatuses.Initial));
  } catch (error) {
    yield put(setRequestPassportsStatus(RequestStatuses.Error));
    throw error;
  }
}

function* passportCollectionWorker({ payload }: any): Generator {
  yield put(setRequestPassportStatus(RequestStatuses.Pending));

  try {
    const res = yield call(fetchPassport, payload);
    yield put(putPassportCollection((res as AxiosResponse).data));
    yield put(setRequestPassportStatus(RequestStatuses.Success));
    yield put(setRequestPassportStatus(RequestStatuses.Initial));
  } catch (error) {
    yield put(setRequestPassportStatus(RequestStatuses.Error));
    throw error;
  }
}

function* movePassportsWorker({ payload }: any): Generator {
  yield put(setRequestMovePassports(RequestStatuses.Pending));

  try {
    yield call(movePassports, payload);
    yield put(removePassport({
      project_id: String(payload.old_project_id),
      passport_id: payload.passport_id
    }));
    yield put(setRequestMovePassports(RequestStatuses.Success));
    yield delay(500);
    yield put(setRequestMovePassports(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestMovePassports(RequestStatuses.Error));
    yield put(setError({
      data: e.response.data,
      entity: payload
    }));
  }
}

function* copyPassportsWorker({ payload }: any): Generator {
  yield put(setRequestCopyPassports(RequestStatuses.Pending));

  try {
    yield call(copyPassports, payload);
    yield put(setRequestCopyPassports(RequestStatuses.Success));
    yield delay(500);
    yield put(setRequestCopyPassports(RequestStatuses.Initial));
  } catch (e) {
    yield put(setRequestCopyPassports(RequestStatuses.Error));
    yield put(setError({
      data: e.response.data,
      entity: payload
    }));
  }
}

function* fetchProjectWithPassportsWorker({ payload }: any): Generator {
  try {
    const res = yield call(fetchPassports, payload);
    yield put(setProjectWithPassports({
      projectId: payload.project,
      passports: (res as AxiosResponse).data
    }));
  } catch (e) {
    console.error(e);
  }
}
