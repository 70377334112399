// Libraries
import React, {useEffect, useMemo, useState} from "react";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Interfaces
import {Project} from "interfaces/index";

// Actions
import {setCoordinates} from "store/reducers/modalsReducer";

// Store
import {setFilterApplicationArea, setFilterComplexityValue, setFilterCostValue} from "store/reducers/filtrationReducer";

// Assets
import {ReactComponent as FilterIcon} from "assets/filter.svg";
import {ReactComponent as MarkIcon} from "assets/check_mark_2.svg";
import {ReactComponent as PadlockIcon} from "assets/padlock.svg";

// Components
import Button from "components/Button/Button";
import ContextMenu from "../ContextMenu/ContextMenu";
import FilterPopup from "../FilterPopup/FilterPopup";

// Styles
import styles from "../../MyProjects.scss";

interface AdditionalFiltrationProps {
  readonly isActiveMultiplyChoice: boolean;
  readonly setIsActiveChoiceMenu: (value: boolean) => void;
  readonly setIsActiveCopy: (value: boolean) => void;
  readonly setIsActiveMovement: (value: boolean) => void;
  readonly isActiveChoiceMenu: boolean;
  readonly isActiveMovement: boolean;
  readonly isActiveCopy: boolean;
  readonly selectedPassport: number[];
  readonly setIsActiveRemovePassports: (value: boolean) => void;
  readonly setIsActiveMultiplyChoice: (value: boolean) => void;
  readonly setSelectedPassport: (value: number[]) => void;
  readonly copyPassports: (value: number) => void;
  readonly setPage: (value: number) => void;
  readonly removePassport: (passportId: number[]) => any;
  readonly movePassports: any;
}

const AdditionalFiltration = (
  {
    isActiveMultiplyChoice,
    setIsActiveChoiceMenu,
    setIsActiveCopy,
    setIsActiveMovement,
    isActiveChoiceMenu,
    isActiveMovement,
    isActiveCopy,
    selectedPassport,
    setIsActiveRemovePassports,
    setIsActiveMultiplyChoice,
    setSelectedPassport,
    copyPassports,
    setPage,
    removePassport,
    movePassports
  }: AdditionalFiltrationProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const projects: Project[] = useAppSelector(state => state.projects.projects);
  /* фильтр по областям применения */
  const filterApplicationArea = useAppSelector(state => state.filtration.filterApplicationArea);
  /* фильтры по срокам эксплуатации */
  const filterLifeTime = useAppSelector(state => state.filtration.filterLifeTime);
  /* фильт сложности */
  const filterComplexityValue = useAppSelector(state => state.filtration.filterComplexityValue);
  /* фильтр стоимости */
  const filterCostValue = useAppSelector(state => state.filtration.filterCostValue);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  
  const renderProjectsForContextMenu = useMemo((): JSX.Element[] => {
    return projects?.map((project): JSX.Element => (
      <div key={`project-${project.id}`} onClick={(): void => {
        if (isActiveMovement) {
          movePassports(project.id);
        }

        if (isActiveCopy) {
          copyPassports(project.id);
        }
      }}>
        {
          project.is_favorite ? <PadlockIcon /> : <img width={20} height={20} src={project.icon_url} alt=""/>
        }
        <span title={project.title}>{project.title}</span>
      </div>
    ))
  }, [projects, movePassports, isActiveMovement, isActiveCopy, copyPassports]);

  useEffect(() => {
    setPage(1);
    dispatch(setFilterComplexityValue(filterComplexityValue));
    dispatch(setFilterCostValue(filterCostValue));
    dispatch(setFilterApplicationArea(filterApplicationArea));
  }, [setPage, filterComplexityValue, dispatch, filterCostValue, filterApplicationArea, filterLifeTime]);

  return (
    <div className={styles.AdditionalFilter}>
      <Button
        title="Фильтр"
        classes={{ root: styles.Button, active: isShowModal ? styles.ButtonActive : "" }}
        type="button"
        startIcon={<FilterIcon />}
        dataModal
        callback={(): void => setIsShowModal(!isShowModal)}
      />
      {
        isActiveMultiplyChoice ? (
          <>
            <Button
              title={`Выбрано: ${selectedPassport.length} `}
              classes={{ root: styles.ButtonSearch}}
              type="button"
              startIcon={<MarkIcon />}
              callback={(): void => {
                setIsActiveChoiceMenu(!isActiveChoiceMenu);
                setIsActiveCopy(false);
                setIsActiveMovement(false);
                dispatch(setCoordinates({
                  x: 65,
                  y: 40
                }));
              }}
            />
            {
              isActiveChoiceMenu && (
                <ContextMenu classes={{ root: styles.ChoiceContextMenu}}>
                  <>
                    {
                      !isActiveMovement && !isActiveCopy && (
                        <ul>
                          <li onClick={(): void => {
                            setIsActiveMovement(true);
                            setIsActiveRemovePassports(false);
                            setIsActiveCopy(false);
                          }}>Переместить в</li>
                          <li onClick={(): void => {
                            setIsActiveCopy(true);
                            setIsActiveMovement(false);
                            setIsActiveRemovePassports(false);
                          }}>Копировать в</li>
                          {/*<li>Скачать</li>*/}
                          <li className={styles.ContextMenuDeleteElement} onClick={(): void => removePassport(selectedPassport)}>Удалить</li>
                          <li className={styles.ContextMenuCancel} onClick={(): void => {
                            setIsActiveMultiplyChoice(false);
                            setIsActiveChoiceMenu(false);
                            setSelectedPassport([]);
                          }}>Отменить</li>
                        </ul>
                      )
                    }
                    {
                      (isActiveMovement || isActiveCopy) && (
                        <ul className={styles.ChoiceContextMenuMovementProject}>
                          {
                            renderProjectsForContextMenu
                          }
                          <div>
                            <span onClick={() => {
                              if (isActiveCopy) {
                                setIsActiveCopy(false);
                              }

                              if (isActiveMovement) {
                                setIsActiveMovement(false);
                              }
                             }}>Назад</span>
                          </div>
                        </ul>
                      )
                    }
                  </>
                </ContextMenu>
              )
            }
          </>
        ) : (
          <Button
            title="Выбрать"
            classes={{ root: styles.Button, active: isActiveMultiplyChoice ? styles.ButtonActive: "" }}
            type="button"
            startIcon={<MarkIcon />}
            callback={(): void => {
              setIsActiveMultiplyChoice(true);
              setIsActiveMovement(false);
              setSelectedPassport([]);
            }}
          />
        )
      }
      <FilterPopup
        setPage={setPage}
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
      />
    </div>
  )
};

export default AdditionalFiltration;
