// Libraries
import React, {useState} from "react";
import { useParams } from "react-router-dom";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Interfaces
import {PassportElement} from "interfaces/index";

// Components
import Button from "components/Button/Button";
import MainInfoComponent from "./components/MainInfo/MainInfo";
import KindMaterialComponent from "./components/KindMaterial/KindMaterial";
import PageContent from "../PageContent/PageContent";

// Styles
import styles from "./DesignPage.scss";

enum Tabs {
  MainInfo,
  KindMaterial
}

const DesignPage = (): JSX.Element => {
  const [tab, setTab] = useState<Tabs>(Tabs.MainInfo);
  const [isShowSelector, setShowSelector] = useState(false);
  const { id } = useParams();
  const passport = useAppSelector(state =>
    state.passports.passportsCollection.find((passportElement: PassportElement): boolean =>
    passportElement.id == id));

  return (
    <>
      <PageContent hasCharacteristics element={passport} />

      <div className={styles.DesignPageTabs}>
        <Button
          title="Основная информация"
          classes={{ root: styles.DesignPageTabsTab, active: tab === Tabs.MainInfo ? styles.DesignPageTabsTabActive : "" }}
          type="button"
          callback={() => {setShowSelector(!isShowSelector); setTab(Tabs.MainInfo)}}
          data-checked={isShowSelector}
        />
        {
          passport?.materials?.length > 0 && (
            <Button
              title="Виды (Материалы)"
              classes={{ root: styles.DesignPageTabsTab, active: tab === Tabs.KindMaterial ? styles.DesignPageTabsTabActive : "" }}
              type="button"
              callback={() => {setShowSelector(!isShowSelector); setTab(Tabs.KindMaterial)}}
              data-checked={isShowSelector}
            />
          )
        }
        <div className={styles.DesignPageSelector} data-show={isShowSelector}>
          <div className={styles.DesignPageSelectorContainer}>
            <div
              className={styles.DesignPageSelectorItem}
              onClick={(): void => {setShowSelector(false); setTab(Tabs.MainInfo)}}
              data-selected={tab === Tabs.MainInfo}
            >
              Основная информация
            </div>
            <div
              className={styles.DesignPageSelectorItem}
              onClick={() => {setShowSelector(false); setTab(Tabs.KindMaterial)}}
              data-selected={tab === Tabs.KindMaterial}
            >
              Виды (Материалы)
            </div>
          </div>
        </div>
      </div>

      { tab === Tabs.MainInfo && <MainInfoComponent /> }
      { tab === Tabs.KindMaterial && <KindMaterialComponent /> }
    </>
  )
};

export default DesignPage;
