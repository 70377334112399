// Libraries
import {call, put, takeEvery} from "redux-saga/effects";
import Cookies from "js-cookie";

// Reducers
import {putUser, RequestStatuses, setErrors, setLogoutStatus} from "store/reducers/authReducer";

// Actions
import {logoutUserAction} from "./actions";

// Api
import {logoutUser} from "../../../Api";

export function* logoutUserSagaWatcher(): Generator {
  yield takeEvery(logoutUserAction.type, removeUser);
}

function* removeUser(): Generator {
  yield put(setLogoutStatus(RequestStatuses.Pending));

  try {
    yield call(logoutUser);
    yield Cookies.remove("axp-user");
    yield put(setLogoutStatus(RequestStatuses.Success));
    yield put(setErrors([]));
    yield put(setLogoutStatus(RequestStatuses.Initial));
    yield put(putUser(undefined));
  } catch (error) {
    yield put(setErrors(error.response.data.errors));
    yield put(setLogoutStatus(RequestStatuses.Error));
  }
}
