export default {
  main: "/",
  handbook: "/handbook",
  handbookWithCategoryId: (levelId?: number, categoryId?: number): string => {
    return (levelId || categoryId) ? `/handbook/${levelId}/${categoryId}` : "/handbook/:levelId/:categoryId";
  },
  passport: (passportId?: number): string => {
    return passportId ? `/passport/${passportId}` : "/passport/:id"
  },
  usefuls: "/usefuls",
  usefulsElement: (usefulId?: number): string => {
    return usefulId ? `/useful/${usefulId}` : "/useful/:id";
  },
  privateCabinet: "/private-cabinet",
  projects: "/private-cabinet/projects",
  project: (projectId?: number | string): string => projectId ? `/private-cabinet/projects/${projectId}` : "/private-cabinet/projects/:id",
  personalData: "/private-cabinet/personal",
  notifications: "/private-cabinet/notifications",
  invitations: "/private-cabinet/invitations",
  settings: "/private-cabinet/settings",
  privateCabinetProjects: "/private-cabinet/projects",
  pageDataAgreement: "/personal_data_agreement",
  policy: "/confidentiality_agreement",
  history: (projectId?: number | string): string => projectId ? `/private-cabinet/projects/${projectId}/history` : "/private-cabinet/projects/:id/history",
  direction: "/direction"
};
