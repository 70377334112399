// Libraries
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {Draggable, DraggableStateSnapshot, DraggingStyle, NotDraggingStyle} from "react-beautiful-dnd";
import {downloadPassports as downloadPassportsApi} from "Api";
import {AxiosResponse} from "axios";

// Routes
import routes from "routes";

// Assets
import {ReactComponent as PadlockIcon} from "assets/padlock.svg";

// Enums
import {RequestStatuses} from "enums/index";

// Actions
import {setCoordinates} from "store/reducers/modalsReducer";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Interfaces
import {PassportElement, Project} from "interfaces/index";

// Components
import ApplicationArea, {ApplicationAreaFilterElement,} from "components/Filtration/components/ApplicationArea/ApplicationArea";
import Loader from "components/Loader/Loader";
import ContextMenu from "../ContextMenu/ContextMenu";

// Styles
import styles from "./Card.scss";

enum Variant {
  MyProject,
  AvailableProject
}

interface CardProps {
  readonly item: PassportElement;
  readonly variant: Variant;
  readonly isActiveMultiplyChoice: boolean;
  readonly setSelectedPassport: (values: number[]) => void;
  readonly selectedPassport: number[];
  readonly removePassport: (passportId: number[]) => void;
  readonly index: number;
  readonly copyPassports: (value: number) => void;
  readonly movePassports: any;
}

function getStyle(style: DraggingStyle | NotDraggingStyle | undefined, snapshot: DraggableStateSnapshot) {
  if (snapshot.isDragging) {
    return {
      ...style,
      height: "216px",
      width: "174px",
    };
  }

  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style
  };
}

const Card = (
  {
    item,
    variant,
    isActiveMultiplyChoice,
    setSelectedPassport,
    selectedPassport,
    removePassport,
    index,
    copyPassports,
    movePassports
  }: CardProps): JSX.Element => {
  const [isOpenContextMenu, setOpenContextMenu] = useState(false);
  const refElement = useRef(null);
  const refElementBtn = useRef(null);
  const [isChoicePassport, setIsChoicePassport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [isCopyPassport, setIsCopyPassport] = useState(false);
  const [isMovingPassport, setIsMovingPassport] = useState(false);
  const projects: Project[] = useAppSelector(state => state.projects.projects);
  const requestCopyPassports: RequestStatuses = useAppSelector(state => state.passports.requestCopyPassports);
  const requestMovePassports: RequestStatuses = useAppSelector(state => state.passports.requestMovePassports);

  useEffect(() => {
    if (requestCopyPassports === RequestStatuses.Success) {
      setIsCopyPassport(false);
      setOpenContextMenu(false);
    }

    if (requestMovePassports === RequestStatuses.Success) {
      setIsMovingPassport(false);
      setOpenContextMenu(false);
    }
  }, [requestCopyPassports, requestMovePassports]);

  const manageActionsWithPassports = useCallback((projectId: number): void => {
    if (isCopyPassport) {
      copyPassports(projectId);
    }

    if (isMovingPassport) {
      movePassports(projectId);
    }
  }, [isCopyPassport, copyPassports, isMovingPassport, movePassports]);

  const renderProjectsForContextMenu = useMemo((): JSX.Element[] => {
    return projects?.map((project): JSX.Element => (
      <div key={`project-${project.id}`} onClick={() => manageActionsWithPassports(project.id)}>
        {
          project.is_favorite ? <PadlockIcon /> : <img width={20} height={20} src={project.icon_url} alt=""/>
        }
        <span title={project.title}>{project.title}</span>
      </div>
    ))
  }, [projects, manageActionsWithPassports]);

  useEffect(() => {
    if (!isActiveMultiplyChoice) {
      setIsChoicePassport(false);
    }
  }, [isActiveMultiplyChoice]);

  const closeModal = useCallback((e: MouseEvent): void => {
    e.preventDefault();
    // @ts-ignore
    if ((refElement.current && !refElement.current?.contains(e.target) && e.target !== refElementBtn.current && !e.target.getAttribute("data-context-menu"))) {
      isCopyPassport && setIsCopyPassport(false);
      isMovingPassport && setIsMovingPassport(false);
      setOpenContextMenu(false);
    }
  }, [isCopyPassport, isMovingPassport]);

  useEffect(() => {
    document.addEventListener("click", closeModal, false);

    return () => document.removeEventListener("click", closeModal, false);
  }, [closeModal]);

  const choicePassport  = (e: MouseEvent): void => {
    if (isActiveMultiplyChoice) {
      e.preventDefault();
      const foundIndex = selectedPassport.findIndex(passportId => passportId === item.id);
      if (foundIndex < 0) {
        setSelectedPassport([...selectedPassport, item.id]);
        setIsChoicePassport(!isChoicePassport);
      } else {
        setSelectedPassport(selectedPassport.slice(0, foundIndex).concat(selectedPassport.slice(foundIndex + 1)));
        setIsChoicePassport(false);
      }
    }
  }

  const downloadPassports = (): void => {
    setIsLoading(true);
    downloadPassportsApi({ passport_id: item.id }).then((res: AxiosResponse) => {
      const url = URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${item.title}.pdf`);
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener noreferrer");
      link.click();
      setIsLoading(false);
    });
  };

  const goBackContextMenu = (): void => {
    if (isCopyPassport) {
      setIsCopyPassport(false);
    }

    if (isMovingPassport) {
      setIsMovingPassport(false);
    }
  };

  return (
    <div className={styles.Container}>
      <Draggable isDragDisabled={isActiveMultiplyChoice || isOpenContextMenu} draggableId={JSON.stringify({ passportId: item.id })} index={index}>
        {
          ((provided, snapshot) => {
            return (
              <>
                <Link
                  {...provided.draggableProps}
                  style={getStyle(provided.draggableProps.style, snapshot)}
                  data-context-menu
                  data-is-open-context-menu={isOpenContextMenu}
                  to={routes.passport(item.id)}
                  className={
                    classNames(styles.Root,
                      [{[styles.ActiveRoot]: isChoicePassport && isActiveMultiplyChoice }]
                    )
                  }
                  data-dragging={snapshot.isDragging}
                  onClick={choicePassport}
                  data-is-multiply={isActiveMultiplyChoice}
                  data-variant={variant}
                >
                  <div className={styles.Preview} {...provided.dragHandleProps} ref={provided.innerRef}>
                    <h4>
                      { item.title }
                    </h4>
                    <img
                      src={`${ item.main_image.length > 0 ?  item.main_image[0].url : "" }`}
                      alt=""
                    />
                  </div>
                  <div data-context-menu="true" className={styles.CardIcons}>
                    <ApplicationArea hasTitle={false}>
                      {
                        item?.application_areas?.map((area: any): JSX.Element => (
                          <ApplicationAreaFilterElement title={area.title} key={uuidv4()} category={area.slug} isActive />
                        ))
                      }
                    </ApplicationArea>
                  </div>
                </Link>
                { isOpenContextMenu && variant === Variant.MyProject && (
                  <ContextMenu ref={refElement}>
                    {
                      isCopyPassport || isMovingPassport ? (
                        <ul className={styles.ChoiceContextMenuMovementProject}>
                          {
                            renderProjectsForContextMenu
                          }
                          <div data-context-menu="true">
                            <span
                              data-context-menu="true"
                              onClick={goBackContextMenu}>Назад</span>
                          </div>
                        </ul>
                      ) : (
                        <ul>
                          <li data-context-menu="true" onClick={(e): void => {
                            e.preventDefault();
                            setIsMovingPassport(true);
                            setSelectedPassport([item.id]);
                          }}>
                            Переместить в
                          </li>
                          <li data-context-menu="true" onClick={(e): void => {
                            e.preventDefault();
                            setIsCopyPassport(true);
                            setSelectedPassport([item.id]);
                          }}
                          >
                            Копировать
                          </li>
                          <li data-context-menu="true" onClick={downloadPassports}>
                            Скачать
                            {
                              isLoading && <Loader />
                            }
                          </li>
                          <li
                            data-context-menu="true"
                            className={styles.ContextMenuDeleteElement}
                            onClick={(e): void => {
                              e.preventDefault();
                              removePassport([item.id]);
                            }}
                          >
                            Удалить
                          </li>
                        </ul>
                      )
                    }

                  </ContextMenu>
                ) }
                { isOpenContextMenu && variant === Variant.AvailableProject && (
                  <ContextMenu ref={refElement}>
                    {
                      isCopyPassport || isMovingPassport ? (
                        <ul className={styles.ChoiceContextMenuMovementProject}>
                          {
                            renderProjectsForContextMenu
                          }
                          <div data-context-menu="true">
                            <span
                              data-context-menu="true"
                              onClick={goBackContextMenu}>Назад</span>
                          </div>
                        </ul>
                      ) : (
                        <ul>
                          <li data-context-menu="true" onClick={(e): void => {
                            e.preventDefault();
                            setIsMovingPassport(true);
                            setSelectedPassport([item.id]);
                          }}>
                            Переместить в
                          </li>
                          <li data-context-menu="true" onClick={(e): void => {
                            e.preventDefault();
                            setIsCopyPassport(true);
                            setSelectedPassport([item.id]);
                          }}
                          >
                            Копировать
                          </li>
                          <li
                            onClick={downloadPassports}
                          >Скачать
                            {
                              isLoading && <Loader />
                            }
                          </li>
                          <li
                            className={styles.ContextMenuDeleteElement}
                            onClick={(e): void => {
                              e.preventDefault();
                              removePassport([item.id]);
                            }}
                          >Удалить</li>
                        </ul>
                      )
                    }
                  </ContextMenu>
                ) }
                {
                  !snapshot.isDragging && (
                    <i ref={refElementBtn} className={styles.Carbon} onClick={(e: any): void => {
                      e.preventDefault();
                      setOpenContextMenu(!isOpenContextMenu);
                      dispatch(setCoordinates({
                        x: 0,
                        y: 0
                      }));
                    }} />
                  )
                }
              </>
            )
          })
        }
      </Draggable>
    </div>
  )
}

export default Card;
