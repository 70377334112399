// Libraries
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {Form} from "react-final-form";
import Cookies from "js-cookie";
import {v4 as uuidv4} from "uuid";

// Routes
import routes from "routes";

// Interfaces
import {NotificationItem} from "store/reducers/notificationsReducer";
import {CategoryElement, Notification} from "interfaces/index";

// Actions
import {fetchNotificationsAction} from "store/sagas/notifications/actions";
import {reset as resetFilters} from "store/reducers/filtrationReducer";

// Enums
import {ModalTypes, RequestStatuses, VariantNotifications} from "enums/index";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Actions
import {fetchNavigationLevelsAction} from "store/sagas/navigationLevels/action";
import {searchPassportsAction} from "store/sagas/search/actions";
import {authSocialAction} from "store/sagas/auth/actions";

// Assets
import {ReactComponent as WormIcon} from "assets/worm.svg";

// Components
import {Input} from "components/Form";
import History from "components/History/History";
import UsuallyModal from "components/ModalWindows/UsuallyModal/UsuallyModal";
import Button from "components/Button/Button";
import SectionsMenu from "./components/SectionsMenu";
import ProjectsLink from "./components/ProjectsLink";

// Styles
import styles from "./Header.scss";

let searchTimer: NodeJS.Timeout;
let fetchNotificationsTimer: NodeJS.Timeout;

interface HeaderProps {
  readonly setActiveModalWindow: (flag: boolean) => void;
  readonly isActiveModalWindow: boolean;
  readonly setModalType: (modalType: ModalTypes) => void;
  readonly modalType: ModalTypes;
}

const Header =
  ({
     setActiveModalWindow,
     isActiveModalWindow,
     setModalType,
     modalType
  }: HeaderProps): JSX.Element => {
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const [isNavigationOpen, setNavigationOpen] = useState<boolean>(false);
  const [isActiveSection, setActiveSection] = useState<boolean>(false);
  const [isShowNotificationModal, setShowNotificationModal] = useState<boolean>(false);
  const [isShowSearchContent, setIsShowSearchContent] = useState(false);
  const user = Cookies.getJSON("axp-user");
  const dispatch = useAppDispatch();
  const levels = useAppSelector(state => state.levels.levels);
  const passports = useAppSelector(state => state.search.passports);
  const categories: CategoryElement[] = useAppSelector(state => state.search.categories);
  const requestFetchEntitiesStatus = useAppSelector(state => state.search.requestFetchEntitiesStatus);
  const [isEmptySearchContent, setIsEmptySearchContent] = useState(false);
  const refElement = useRef(null);
  const notifications: NotificationItem[] = useAppSelector(state => state.notifications.notifications);
  const notificationsReadAtList: Notification[] = useAppSelector(state => state.notifications.notificationsList.slice(0, 4)
    .filter((notification: Notification) => notification.read_at === null));
  const getParams = new URLSearchParams(window.location.search);
  const requestAuthSocialStatus = useAppSelector(state => state.auth.requestAuthSocialStatus);

  const authSocial = () => {
    if (getParams.get("social_auth") && getParams.get("social_hash")) {
      dispatch(authSocialAction({
        hash: getParams.get("social_hash")
      }));
    }
  };

  useEffect(() => {
    if (requestAuthSocialStatus === RequestStatuses.Success) {
      window.history.pushState(null, "", window.location.pathname);
    }
  }, [requestAuthSocialStatus]);

  useEffect(authSocial, []);

  useEffect(() => {
    if (isActiveModalWindow) {
      setNavigationOpen(false);
    }
  }, [isActiveModalWindow]);

  const fetchNotificationsWithTimeout = (): void => {
    if (user) {
      clearTimeout(fetchNotificationsTimer);
      fetchNotificationsTimer = setTimeout(() => {
        dispatch(fetchNotificationsAction({ per_page: 4, page: 1 }));
      }, 60000);
    }
  };

  const fetchNotifications = (): void => {
    if (user) {
      dispatch(fetchNotificationsAction({ per_page: 4, page: 1 }));
    }
  };

  const changeModalType = (): void => {
    if (user && isActiveModalWindow && modalType === ModalTypes.Registration) {
      setModalType(ModalTypes.SuccessRegistration);
    }
  };

  useEffect(changeModalType, [user, isActiveModalWindow])

  useEffect(fetchNotificationsWithTimeout, [fetchNotificationsTimer]);

  useEffect(fetchNotifications, []);

  useEffect(() => {
    document.addEventListener("click", handlerClick, false);

    return () => document.removeEventListener("click", handlerClick, false);
  }, []);

  const handlerClick = (e: any): void => {
    // @ts-ignore
    if (!refElement.current?.contains(e.target) && e.target.getAttribute("name") !== "search") {
      setIsShowSearchContent(false);
    }
  }

  useEffect(() => {
    if (requestFetchEntitiesStatus === RequestStatuses.Success && passports.length === 0 && categories.length === 0) {
      setIsEmptySearchContent(true);
    }
  }, [requestFetchEntitiesStatus, passports, categories]);

  useEffect(() => {
    levels?.length === 0 && dispatch(fetchNavigationLevelsAction());
  }, [dispatch, levels]);
  
  useEffect(() => {
    isSearchOpen ? setNavigationOpen(false) : setActiveSection(false);
  }, [isSearchOpen]);
  useEffect(() => {
    isNavigationOpen && setSearchOpen(false) && setActiveSection(false);
  }, [isNavigationOpen]);

  const login = (): void => {
    setActiveModalWindow(!isActiveModalWindow);
    setModalType(ModalTypes.Login);
  };

  const searchEntities = (values: any): void => {
    setIsEmptySearchContent(false);
    if (values.search) {
      setIsShowSearchContent(true);
      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        const params: any = { title: values.search };
        dispatch(searchPassportsAction(params));
      }, 1000);
    } else {
      setIsShowSearchContent(false);
    }
  };

  const renderSearchPassports = useMemo(() => {
    return passports?.map((entity: any) => (
      <li key={uuidv4()}>
        <Link onClick={(): void => setIsShowSearchContent(false)} to={routes.passport(entity.id)}>
          <img src={entity.main_image[0].url} alt=""/>
          { entity.title }
        </Link>
      </li>
    ))
  }, [passports, isShowSearchContent]);

  const renderSearchCategories = useMemo(() => {
    return categories?.map((category) => (
      <li className={styles.CategoriesList} key={uuidv4()}>
        <Link onClick={(): void => setIsShowSearchContent(false)} to={routes.handbookWithCategoryId(category.level_id, category.id)}>
          { category.title }
        </Link>
        <span>{ category?.level?.title }</span>
      </li>
    ));
  }, [categories]);

  return (
    <header className={styles.Root}>
      {isActiveSection && <SectionsMenu isActiveSection={isSearchOpen} setActiveSection={setActiveSection} /> }
      <div className={styles.Container}>
        <Link to="/" className={styles.Logo}>
          <div className={styles.LogoIcon} />
        </Link>
        <Button
          title=""
          classes={{ root: styles.SearchGroupTrigger }}
          type="button"
          data-opened={isSearchOpen}
          callback={() => setSearchOpen(!isSearchOpen)}
          startIcon={<i className={styles.SearchGroupTriggerIcon} />}
        />
        <div className={styles.SearchGroup} data-active={isSearchOpen}>
          <Button
            title="Разделы"
            classes={{ root: styles.Sections }}
            type="button"
            callback={(): void => setActiveSection(!isActiveSection)}
            startIcon={<div className={classNames(styles.Burger, [{ [styles.Active]: isActiveSection }])}><i className={styles.BurgerItem}  /></div>}
          />
          <div className={styles.SearchContainer}>
            { isActiveSection && <i className={styles.Bird} /> }
            <Form onSubmit={searchEntities} render={({ handleSubmit, initialValues }): JSX.Element => (
              <form id="search-header-form" onChange={handleSubmit} onClick={(): void => setIsShowSearchContent(true)}>
                <Input name="search" type="text" placeholder="Поиск" classes={{ root: isShowSearchContent ? styles.SearchInputActive : styles.SearchInput }} />
                {
                  isShowSearchContent && (
                    <div ref={refElement}>
                      {
                        !isEmptySearchContent ? (
                          <div className={styles.SearchContainerResults}>
                            {
                              passports.length > 0 && (
                                <>
                                  <h4>Элементы / материалы</h4>
                                  <ul>
                                    {
                                      renderSearchPassports
                                    }
                                  </ul>
                                </>
                              )
                            }
                            {
                              categories.length > 0 && (
                                <>
                                  <h4>Категории</h4>
                                  <ul>
                                    { renderSearchCategories }
                                  </ul>
                                </>
                              )
                            }
                          </div>
                        ) : (
                          <div className={styles.SearchContainerResultsEmpty}>
                            <span>Ничего не найдено</span>
                            <WormIcon />
                          </div>
                        )
                      }
                    </div>
                  )
                }
              </form>
            )} />
          </div>
        </div>
        <Button
          title="Меню"
          classes={{ root: styles.NavigationTrigger }}
          type="button"
          data-opened={isNavigationOpen}
          callback={() => setNavigationOpen(!isNavigationOpen)}
          startIcon={<i className={styles.NavigationTriggerIcon} />}
        />
        <div className={styles.Navigation} data-opened={isNavigationOpen}>
          <div className={styles.ButtonsGroup}>
            <Link className={styles.Faq} to={routes.handbook}>Справочник</Link>
            <Link className={styles.GoodNess} to={routes.usefuls}>Полезное</Link>
            {
              user && <ProjectsLink />
            }
            {
              user && <Link className={styles.PersonalCabinet} to={routes.personalData}>Личный кабинет</Link>
            }
          </div>

          <div className={styles.Account}>
            { user ? (
              <>
                <i
                  className={styles.Notification}
                  onClick={(): void => setShowNotificationModal(!isShowNotificationModal)}
                  data-modal="true"
                />
                {
                  notificationsReadAtList.length > 0 && <i className={styles.NotificationActive} />
                }
                <Link onClick={() => dispatch(resetFilters({}))} className={styles.User} to={routes.personalData} />
              </>
            ) : (
              <Button
                title="Войти"
                classes={{ root: styles.Enter }}
                type="button"
                callback={login}
                startIcon={<i className={styles.EnterIcon} />}
              />
            ) }
          </div>
        </div>
      </div>
      <UsuallyModal
        isShowModal={isShowNotificationModal}
        setShowModal={setShowNotificationModal}
        maxWidth={451}
        rightPosition={0}
        classes={{ content: styles.ModalContent }}
      >
        <History
          data={notifications}
          variant={VariantNotifications.Notification}
          classes={{ person: styles.Person, personName: styles.PersonName, elementData: styles.ElementData }}
          isShowAllBtn
          setShowNotificationModal={setShowNotificationModal}
        />
      </UsuallyModal>
    </header>
  )
}

export default Header;
