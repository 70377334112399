// Libraries
import React, {useMemo, useState} from "react";
import Skeleton from "react-loading-skeleton";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Assets
import {ReactComponent as BackIcon} from "assets/back.svg";

// Enums
import {VariantSectionModeMobile} from "enums/index";

// Interfaces
import {CategoryElement, LevelElement} from "interfaces/index";

// Components
import Button from "components/Button/Button";
import MobileCategory from "./MobileCategory";

// Styles
import styles from "../Header.scss";

interface SectionsMenuMobileProps {
  readonly isActiveSection: boolean;
}

const SectionsMenuMobile = ({ isActiveSection }: SectionsMenuMobileProps): JSX.Element => {
  const [variantSectionMode, setVariantSectionMode] = useState<VariantSectionModeMobile>(VariantSectionModeMobile.Levels);
  const levels = useAppSelector(state => state.levels.levels);
  const [activeLevel, setActiveLevel] = useState(2);
  const level = levels.find((levelElement: LevelElement): boolean => levelElement.value === activeLevel);

  const choiceLevel = (levelElement: LevelElement): void => {
    setActiveLevel(levelElement.value);
    setVariantSectionMode(VariantSectionModeMobile.Categories);
  };

  const renderLevels = useMemo((): JSX.Element[] => {
    return levels?.map((levelElement: LevelElement) => (
      <li
        onClick={(): void => choiceLevel(levelElement)}
        key={`level-el-${levelElement.id}`}
        className={activeLevel === levelElement.value ? styles.ActiveLevel : ""}
      >
        { levelElement.title }
      </li>
    )).reverse();
  }, [levels, activeLevel]);

  const renderCategories = useMemo((): JSX.Element[] => {
    return level?.categories?.map((category: CategoryElement): JSX.Element | undefined => (
      <MobileCategory key={`category-${category.id}`} level={level} category={category} />
    ));
  }, [level]);

  return (
    <div className={styles.RootSectionsMobile} data-active={isActiveSection}>
      <div className={styles.RootSectionsMobileContainer}>
        {
          variantSectionMode === VariantSectionModeMobile.Levels && (
            <nav key="variant-mode-level" className={styles.RootSectionsMobileContainerLevels}>
              <ul>
                { levels.length > 0 ? renderLevels : <Skeleton className={styles.SkeletonLevelItems} count={4} /> }
              </ul>
            </nav>
          )
        }
        {
          variantSectionMode === VariantSectionModeMobile.Categories && (
            <nav key="variant-mode-categories" className={styles.RootSectionsMobileContainerCategories}>
              <Button
                title={level.title}
                classes={{ root: styles.RootSectionsMobileContainerCategoriesBtn }}
                startIcon={<BackIcon />}
                type="button"
                callback={(): void => setVariantSectionMode(VariantSectionModeMobile.Levels)}
              />
              <ul>
                { levels.length > 0 ?  renderCategories : <Skeleton className={styles.SkeletonCategoryItems} count={60} /> }
              </ul>
            </nav>
          )
        }
      </div>
    </div>
  )
};

export default SectionsMenuMobile;
