// Libraries
import React, {useState, forwardRef, Ref, useEffect, useMemo} from "react";
import classNames from "classnames";
import {Field, Form} from "react-final-form";

// Interfaces
import {Project} from "interfaces/index";

// Enums
import { ProjectRoles } from "enums/index";

// Assets
import {ReactComponent as PlusIcon} from "assets/plus_red_no_fon.svg";
import {ReactComponent as SharingIcon} from "assets/sharing.svg";
import {ReactComponent as MarkIcon} from "assets/check_mark_2.svg";
import {ReactComponent as CloseIcon} from "assets/close.svg";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Hooks
import {createProjectAction, fetchProjectIconsAction, fetchProjectsAction} from "store/sagas/projects/actions";

// Components
import ListElement from "./components/ListElement";

// Styles
import styles from "./ModalWithListProjects.scss";

// Enums
enum WindowVariant {
  MyProject,
  Available
}

interface ModalWithListProjectsProps {
  readonly classes?: {
    readonly root?: string;
    readonly list?: string;
  };
  readonly passportId: number;
}

const ModalWithListProjects = ({ classes = {}, passportId, ...props }: ModalWithListProjectsProps, ref: Ref<HTMLDivElement>): JSX.Element => {
  const [windowVariant, setWindowVariant] = useState<WindowVariant>(WindowVariant.MyProject);
  const [isActiveInput, setActiveInput] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const projects: Project[] = useAppSelector(state => state.projects.projects);
  const icons = useAppSelector(state => state.projects.projectIcons);
  const icon = icons[Math.floor(Math.random() * icons.length)];
  const [isCopyLink, setIsCopyLink] = useState<boolean>(false);

  useEffect(() => {
    !icons.length && dispatch(fetchProjectIconsAction());
  }, [dispatch, icons]);

  useEffect(() => {
    dispatch(fetchProjectsAction({ added_passport_id: passportId }));
  }, [dispatch, passportId]);

  const renderProjects = useMemo((): JSX.Element[] => {
    if (windowVariant === WindowVariant.MyProject) {
      return projects?.filter((element) => element.project_role === ProjectRoles.Owner).map((element): JSX.Element => (
        <ListElement
          key={element.id}
          element={element}
          passportId={passportId}
        />
      ));
    }

    return projects?.filter((element) => element.project_role === ProjectRoles.Invited).map((element): JSX.Element => (
      <ListElement
        key={element.id}
        element={element}
        passportId={passportId}
      />
    ));
  }, [projects, windowVariant, passportId]);

  const createProject = (values: any, form: any): void => {
    const params = {
      title: values.project_title,
      icon_id: icon.id
    }
    dispatch(createProjectAction(params));
    setActiveInput(false);
    form.reset();
  };

  const sharingProject = (): void => {
    navigator.clipboard.writeText(`${window.location.host}/passport/${passportId}`).then(() => {
      setIsCopyLink(true);
    });
  };

  return (
    <div ref={ref} className={classNames(styles.CardModalWindow, classes?.root)} {...props}>
      <div className={styles.CardModalWindowTabs}>
        <div
          className={classNames(
            styles.CardModalWindowTabsElement,
            [
              {[styles.CardModalWindowTabsElementActive]: windowVariant === WindowVariant.MyProject}
            ]
          )}
          onClick={(): void => setWindowVariant(WindowVariant.MyProject)}
        >
          Мои проекты
        </div>
        <div
          className={classNames(
            styles.CardModalWindowTabsElement,
            [
              {[styles.CardModalWindowTabsElementActive]: windowVariant === WindowVariant.Available}
            ]
          )}
          onClick={(): void => {
            setActiveInput(false);
            setWindowVariant(WindowVariant.Available);
          }}
        >
          Доступные мне
        </div>
      </div>
      {
        windowVariant === WindowVariant.MyProject && (
          <div className={styles.CardModalWindowTitle}>
            <div className={styles.CardModalWindowTitleContainer} onClick={(): void => setActiveInput(true)}>
              <PlusIcon />
              <span>Добавить новый проект</span>
            </div>
            <i data-title={isCopyLink && "Ссылка скопирована"} onClick={sharingProject} />
          </div>
        )
      }
      {
        windowVariant === WindowVariant.Available && (
          <div className={styles.CardModalWindowTitleAvailable} data-title={isCopyLink && "Ссылка скопирована"} onClick={sharingProject}>
            <SharingIcon />
            <span>Поделиться</span>
          </div>
        )
      }
      <div className={classNames(styles.CardModalWindowContent, classes?.list)}>
        <div className={styles.CardModalWindowContentAdd} style={{ display: isActiveInput ? "flex" : "none"}}>
          <div className={styles.CardModalWindowContentAddAction}>
            <Form
              onSubmit={createProject}
              render={({ handleSubmit }): JSX.Element => (
                <form>
                  <div className={styles.CreateProjectInput}>
                    <img className={styles.CardModalWindowElementImgIcon} src={icon?.url} alt=""/>
                    <Field
                      name="project_title"
                      component="input"
                      type="text"
                    />
                  </div>
                  <MarkIcon onClick={handleSubmit} />
                  <CloseIcon onClick={(): void => setActiveInput(false)} />
                </form>
              )}
            />
          </div>
        </div>
        { renderProjects }
      </div>
    </div>
  )
};

export default forwardRef<HTMLDivElement, ModalWithListProjectsProps>(ModalWithListProjects);
