// Libraries
import React, {useEffect, useMemo, useState} from "react";
import {FieldArray} from "react-final-form-arrays";
import {Field, Form} from "react-final-form";
import arrayMutators from "final-form-arrays";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Assets
import {ReactComponent as AddInvitedIcon} from "assets/invations_add.svg";
import {ReactComponent as SuccessIcon} from "assets/success.svg";

// Helpers
import {checkValidEmail, requireField} from "helpers/Validator";

// Enums
import {Roles} from "views/PrivateCabinet/components/Invitations/components/FormSelectElement";
import {VariantModal} from "views/PrivateCabinet/components/Invitations/Invitations";
import {RequestStatuses} from "enums/index";

// Actions
import {inviteUserToProjectActions} from "store/sagas/invited/actions";
import {setActiveInvitation} from "store/reducers/modalsReducer";

// Components
import Button from "components/Button/Button";
import {Input} from "components/Form";
import Select from "components/Form/components/Select/Select";
import UsuallyModal from "../UsuallyModal/UsuallyModal";

// Styles
import styles from "./InvitationModal.scss";

const options = [
  { id: 3, value: "editor", label: Roles.Editor },
  { id: 2, value: "observer", label: Roles.Observer }
];

const InvitationModal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [variantModal, setVariantModal] = useState<VariantModal>(VariantModal.FormVariant);
  const requestInviteUserToProjectStatus = useAppSelector(state => state.invited.requestInviteUserToProjectStatus);
  const isActiveInvitationModal: boolean = useAppSelector(state => state.modals.invitationModal.isActive);
  const projectId: number | undefined = useAppSelector(state => state.modals.invitationModal.projectId);
  const coordinates = useAppSelector(state => state.modals.coordinates);

  useEffect(() => {
    if (requestInviteUserToProjectStatus === RequestStatuses.Success) {
      setVariantModal(VariantModal.SuccessVariant);
    }
  }, [requestInviteUserToProjectStatus]);

  useEffect(() => {
    if (!isActiveInvitationModal && variantModal === VariantModal.SuccessVariant) {
      setVariantModal(VariantModal.FormVariant);
    }
  }, [variantModal, isActiveInvitationModal]);

  const onSubmit = (values: any): void => {
    values?.invited?.forEach((value: any): void => {
      if (value?.email && value?.role) {
        dispatch(inviteUserToProjectActions({
          user_email: value.email,
          role: value.role,
          id: projectId
        }));
      }
    });
  };
  
  const renderInputs = useMemo((): JSX.Element => {
    return (
      <FieldArray name="invited">
        {
          ({ fields }): JSX.Element[] => (
            fields.map((name, i): JSX.Element => (
              <div key={name} className={styles.InvitedFormContainerField}>
                <label>Email</label>
                <div>
                  <Input
                    placeholder="Введите Email"
                    type="text"
                    name={`${name}.email`}
                    classes={{ root: styles.InvitedFormInput, error: styles.InvitedFormInputError }}
                    validators={[requireField, checkValidEmail]}
                  />
                  <Field validate={requireField} name={`${name}.role`}>
                    {
                      props => (
                        <Select
                          classes={{ root: styles.InvitedFormSelect }}
                          {...props}
                          label="Роль"
                          placeholder="Роль"
                          options={options}
                          isBorder={false}
                          isBackground={false}
                          colorInputText="#124C9A"
                        />
                      )
                    }
                  </Field>
                </div>
              </div>
            ))
          )
        }
      </FieldArray>
    )
  }, []);

  const setShowModal = (value: boolean): void => {
    dispatch(setActiveInvitation(value));
  };

  return (
    <UsuallyModal
      isShowModal={isActiveInvitationModal}
      setShowModal={setShowModal}
      maxWidth={387}
      classes={{ root: styles.InvitedFormModal }}
      isCloseOnClickOutside={false}
      topPosition={coordinates?.y - 200}
    >
      {
        variantModal === VariantModal.FormVariant ? (
          <Form
            onSubmit={onSubmit}
            mutators={{
              ...arrayMutators
            }}
            initialValues={{ invited: [{}] }}
            render={({ handleSubmit, form: { mutators: { push } }, values }): JSX.Element => (
              <form className={styles.InvitedForm} onSubmit={handleSubmit}>
                <h4 className={styles.InvitedFormTitle}>
                  Введите email пользователя(-ей), которого(-ых) хотите пригласить
                </h4>
                <div className={styles.InvitedFormContainer}>
                  {renderInputs}
                </div>
                <Button
                  title="Пригласить еще"
                  classes={{ root: styles.InvitedFormAddInvited }}
                  type="button"
                  startIcon={<AddInvitedIcon />}
                  callback={(): void => push("invited")}
                />
                <div className={styles.InvitedFormActions}>
                  <Button
                    title="Отправить"
                    classes={{ root: styles.InvitedFormBtn }}
                    type="button"
                    callback={() => onSubmit(values)}
                  />
                </div>
              </form>
            )}
          />
        ) : (
          <div className={styles.SuccessModal}>
            <SuccessIcon />
            <p>Приглашение отправлено!</p>
          </div>
        )
      }
    </UsuallyModal>
  )
};

export default InvitationModal;
