// Libraries
import React, {useEffect, useState} from "react";
import { Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

// Interfaces
import {User} from "interfaces/index";

// Components
import Button from "components/Button/Button";
import UsuallyModal from "components/ModalWindows/UsuallyModal/UsuallyModal";
import { Checkbox } from "components/Form";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Actions
import {changeSettingsUserAction, logoutUserAction, removeUserAccountAction} from "store/sagas/auth/actions";

// Reducers
import { RequestStatuses } from "store/reducers/authReducer";

// Styles
import styles from "./NotificationsForm.scss";

const NotificationsForm = (): JSX.Element => {
  const [isShowRemoveUserAccountModal, setShowRemoveUserAccountModal] = useState(false);
  const [isShowLogoutModal, setIsShowLogoutModal] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const requestLogoutStatus = useAppSelector(state => state.auth.requestLogoutStatus);
  const requestRemoveUserAccountStatus = useAppSelector(state => state.auth.requestRemoveUserAccountStatus);
  const user: User = useAppSelector(state => state.auth.user);

  useEffect(() => {
    if (requestLogoutStatus === RequestStatuses.Success || requestRemoveUserAccountStatus === RequestStatuses.Success) {
      history.push("/");
    }
  }, [requestLogoutStatus, history, requestRemoveUserAccountStatus]);

  const onSubmit = (values: any): void => {
    dispatch(changeSettingsUserAction(values));
  };

  const removeAccount = (): void => {
    dispatch(removeUserAccountAction());
    setShowRemoveUserAccountModal(false);
    Cookies.remove("axp-user");
    history.push("/");
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form }): JSX.Element => (
        <form onChange={() => handleSubmit()} className={styles.NotificationsForm} onSubmit={handleSubmit}>

          <div className={styles.NotificationsFormHeader}>
            <h4>Уведомления</h4>
            <div className={styles.NotificationsFormHeaderField}>
              <Checkbox
                label="Присылать мне на почту уведомления"
                name="email_notice"
                initialValue={user.email_notice}
              />
            </div>
          </div>

          <div className={styles.NotificationsFormContainer}>
            <div className={styles.NotificationsFormContainerField}>
              <Checkbox
                label="Присылать мне на почту уведомления об изменениях в моих проектах"
                name="email_notice_my_project"
                initialValue={user.email_notice_my_project}
              />
            </div>

            <div className={styles.NotificationsFormContainerField}>
              <Checkbox
                label="Присылать мне на почту уведомления об изменениях в проектах, куда я приглашен"
                name="email_notice_invited_project"
                initialValue={user.email_notice_invited_project}
              />
            </div>

            <div className={styles.NotificationsFormRemoveAccount}>
              <h4>Аккаунт</h4>
              <Button
                title="Выйти из аккаунта"
                classes={{ root: styles.Logout }}
                type="button"
                callback={(): void => setIsShowLogoutModal(true)}
                dataModal
              />
              <Button
                title="Удалить аккаунт"
                classes={{ root: styles.NotificationsFormRemoveAccBtn }}
                type="button"
                callback={(): void => setShowRemoveUserAccountModal(!isShowRemoveUserAccountModal)}
                dataModal
              />
            </div>
          </div>

          <UsuallyModal
            isShowModal={isShowRemoveUserAccountModal}
            setShowModal={setShowRemoveUserAccountModal}
            maxWidth={387}
            title="Удаление аккаунта"
          >
            <div className={styles.ModalContent}>
              <p>
                Вы уверены, что хотите удалить аккаунт?
              </p>
              <div className={styles.NotificationsFormActionsModal}>
                <Button
                  title="Удалить"
                  classes={{ root: styles.NotificationsFormActionsModalSureAction }}
                  type="button"
                  callback={removeAccount}
                />
                <Button
                  title="Нет"
                  classes={{ root: styles.NotificationsFormActionsModalNotSureAction }}
                  type="button"
                  callback={(): void => setShowRemoveUserAccountModal(false)}
                />
              </div>
            </div>
          </UsuallyModal>
          <UsuallyModal
            isShowModal={isShowLogoutModal}
            setShowModal={setIsShowLogoutModal}
            maxWidth={387}
            title="Выход из аккаунта"
          >
            <div className={styles.ModalContent}>
              <p>
                Вы уверены, что хотите выйти из аккаунта?
              </p>
              <div className={styles.NotificationsFormActionsModal}>
                <Button
                  title="Выйти"
                  classes={{ root: styles.NotificationsFormActionsModalSureAction }}
                  type="button"
                  callback={() => dispatch(logoutUserAction())}
                />
                <Button
                  title="Нет"
                  classes={{ root: styles.NotificationsFormActionsModalNotSureAction }}
                  type="button"
                  callback={(): void => setIsShowLogoutModal(false)}
                />
              </div>
            </div>
          </UsuallyModal>
        </form>
      )}
    />
  )
};

export default NotificationsForm;
