// Libraries
import React, { memo } from "react";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Interfaces
import {NotificationItem} from "store/reducers/notificationsReducer";

// Enums
import {RequestStatuses, VariantNotifications} from "enums/index";

// Assets
import { ReactComponent as HistoryIcon } from "assets/history.svg";
import { ReactComponent as NotificationIcon } from "assets/no_notification.svg";

// Components
import HistoryElement from "./components/HistoryElement/HistoryElement";

// Styles
import styles from "./History.scss";

export interface HistoryProps {
  readonly variant: VariantNotifications;
  readonly data: NotificationItem[];
  readonly classes?: {
    readonly person?: string;
    readonly personName?: string;
    readonly elementData?: string;
  };
  readonly isShowAllBtn?: boolean;
  readonly setShowNotificationModal?: (value: boolean) => void;
}

const History = ({ data, variant, setShowNotificationModal, classes = {}, isShowAllBtn }: HistoryProps): JSX.Element => {
  const requestFetchHistoryProjectStatus = useAppSelector(state => state.notifications.requestFetchHistoryProjectStatus);
  return (
    <div className={styles.Root}>
      {
        data?.length === 0 && requestFetchHistoryProjectStatus !== RequestStatuses.Pending ? (
          <div className={styles.EmptyContent}>
            {
              variant === VariantNotifications.History && (
                <>
                  <div className={styles.IconContainer}>
                    <HistoryIcon />
                  </div>
                  <p>В этом проекте еще не было изменений!</p>
                </>
              )
            }
            {
              variant === VariantNotifications.Notification && (
                <>
                  <div className={styles.IconContainer}>
                    <NotificationIcon />
                  </div>
                  <p>У Вас еще нет никаких уведомлений!</p>
                </>
              )
            }
          </div>
        ) : (
          <HistoryElement
            setShowNotificationModal={setShowNotificationModal}
            isShowAllBtn={isShowAllBtn}
            classes={classes}
            data={data}
            variant={variant}
          />
        )
      }
    </div>
  )
}

export default memo(History);
