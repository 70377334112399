// Libraries
import React, {useEffect, useMemo, useState} from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { animateScroll } from "react-scroll";

// Hooks
import {useAppDispatch, useAppSelector} from "hooks/hooks";

// Actions
import {fetchBannerAction} from "store/sagas/content/actions";

// Interfaces
import {Banner, LevelElement} from "interfaces/index";

// Components
import Page from "components/Page/Page";

// Routes
import routes from "../../routes";

// Styles
import styles from "./Main.scss";

const LVL_MIN = -1;
const LVL_MAX = 2;

const Main = (): JSX.Element => {
  const [activeLevel, setActiveLevel] = useState(LVL_MIN);
  const levels: LevelElement[] = useAppSelector(state => state.levels.levels);
  const dispatch = useAppDispatch();
  const banner: Banner = useAppSelector(state => state.content.banner);

  const fetchBanner = (): void => {
    !banner && dispatch(fetchBannerAction());
  };

  const handleReadMore = (event: React.MouseEvent) => { 
    const header = document.querySelector("header");
    const headerHeight = header ? (header.getBoundingClientRect())?.height : 0;
    animateScroll.scrollTo(
      window.innerHeight-headerHeight,
      {
        duration: 1000,
        smooth: true,
      }
    );
  };

  useEffect(fetchBanner, []);
  
  const renderLevels = useMemo(() => {
    return levels.map((level, index): JSX.Element => (
      <div
        key={uuidv4()}
        className={styles.StructureHeadlinesColumn}
        data-level={index - 1}
        data-active={activeLevel === index - 1}
      >
        <h4>{level.title}</h4>
        <ul>
          {
            level.categories.map((category): JSX.Element => (
              <li key={uuidv4()}>
                <Link to={routes.handbookWithCategoryId(level.id, category.id)}>{ category.title }</Link>
              </li>
            ))
          }
        </ul>
      </div>
    ));
  }, [activeLevel, levels]);

  return (
    <Page>
      <section
        className={styles.TitleSection}
        style={{ background: banner ? `#124C9A url(${banner?.main_image[0]?.url}) no-repeat center bottom` : "#124C9A" }}
        data-default-img={banner?.is_default}
      >
        <div className={styles.Container}>
          <h1 className={styles.Title}>Атлас хороших <br />решений</h1>
          <h4 className={styles.SubTitle}>Справочник по правильному выбору, реализации и эксплуатации элементов благоустройства в Москве</h4>
          <Link
            to="/handbook"
            className={styles.Button}
          >Изучить справочник</Link>
          <div className={styles.ReadMode} onClick={handleReadMore}>
            <p>Читать далее</p>
            <i />
          </div>
        </div>
      </section>

      <div className={styles.Structure}>
        <div className={styles.Description}>
          <h2 className={styles.DescriptionTitle}>Навигация по уровням</h2>
          <p className={styles.DescriptionText}>
            Издание выделяет четыре уровня общественных пространств, по которым группируются элементы и материалы для благоустройства
          </p>
        </div>
        <div className={styles.StructureHeadlines}>
          <div className={styles.StructureHeadlinesControls}>
            <i
              className={styles.StructureHeadlinesControlsLeft}
              data-active={activeLevel > LVL_MIN}
              onClick={
                () => setActiveLevel(activeLevel - 1)
              }
            />
            <i
              className={styles.StructureHeadlinesControlsRight}
              data-active={activeLevel < LVL_MAX}
              onClick={
                () => setActiveLevel(activeLevel + 1)
              }
            />
          </div>
          {renderLevels}
        </div>
        <div className={styles.Background} />
      </div>

      <section className={styles.Designations}>
        <div className={styles.DesignationsContainer}>
          <div className={styles.DesignationsContainerContent}>
            <h4 className={styles.DesignationsContainerContentTitle}>Навигация по пространствам</h4>
            <p className={styles.DesignationsContainerContentDescription}>
              Атлас выделяет следующие общественные пространства Москвы, для которых рекомендуется применение выбранных
              материалов и элементов: <b>улицы, площади, озелененные территории, набережные, дворы</b>.
              Также представлены облицовочные решения <b>вертикальных плоскостей, зданий и сооружений</b>.
            </p>
          </div>
          <div className={styles.DesignationsContainerIcon} />
        </div>
      </section>

      <section className={styles.DoBetter}>
        <div className={styles.DoBetterContainer}>
          <div className={styles.DoBetterContainerAbout}>
            <h4 className={styles.DoBetterContainerAboutTitle}>Сделать город лучше - проще, чем кажется </h4>
            <p className={styles.DoBetterContainerAboutDescription}>
              Применение практических знаний позволит улучшить финальное качество объекта благоустройства и эффективность его обслуживания.
            </p>
            <Link
              to="/handbook"
              className={styles.DoBetterContainerAboutButton}
            >Изучить справочник</Link>
          </div>
        </div>
      </section>
    </Page>
  )
}

export default Main;
