// Libraries
import React from "react";
// import { Link } from "react-router-dom";

// Routes
// import routes from "routes";

// Components
import Page from "components/Page/Page";

// Styles
import styles from "./Policy.scss";

const DataAgreement = (): JSX.Element => {
  return (
    <Page>
      <section className={styles.Root}>
        <h2>Lorem ipsum dolor sit amet.</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto debitis dolore ea expedita ipsum odio quibusdam quis quos tempora? Laboriosam.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi atque debitis eaque excepturi facilis laudantium officia quas quasi recusandae repellendus!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. At expedita fuga incidunt maxime nemo perferendis provident quod sint suscipit vel?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorem fugit harum illo magnam nobis nostrum quibusdam quisquam reiciendis vitae!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid architecto consectetur debitis, harum hic itaque labore necessitatibus obcaecati quo veniam!
        </p>
        <h2>Lorem ipsum dolor sit amet.</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et necessitatibus nobis optio soluta vero? Dignissimos doloremque dolores eaque eveniet facilis, iusto perferendis quasi sed temporibus.</p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto debitis dolore ea expedita ipsum odio quibusdam quis quos tempora? Laboriosam.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi atque debitis eaque excepturi facilis laudantium officia quas quasi recusandae repellendus!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. At expedita fuga incidunt maxime nemo perferendis provident quod sint suscipit vel?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorem fugit harum illo magnam nobis nostrum quibusdam quisquam reiciendis vitae!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid architecto consectetur debitis, harum hic itaque labore necessitatibus obcaecati quo veniam!
        </p>
        <h2>Lorem ipsum dolor sit amet.</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et necessitatibus nobis optio soluta vero? Dignissimos doloremque dolores eaque eveniet facilis, iusto perferendis quasi sed temporibus.</p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto debitis dolore ea expedita ipsum odio quibusdam quis quos tempora? Laboriosam.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi atque debitis eaque excepturi facilis laudantium officia quas quasi recusandae repellendus!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. At expedita fuga incidunt maxime nemo perferendis provident quod sint suscipit vel?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorem fugit harum illo magnam nobis nostrum quibusdam quisquam reiciendis vitae!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid architecto consectetur debitis, harum hic itaque labore necessitatibus obcaecati quo veniam!
        </p>
        <h2>Lorem ipsum dolor sit amet.</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et necessitatibus nobis optio soluta vero? Dignissimos doloremque dolores eaque eveniet facilis, iusto perferendis quasi sed temporibus.</p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto debitis dolore ea expedita ipsum odio quibusdam quis quos tempora? Laboriosam.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi atque debitis eaque excepturi facilis laudantium officia quas quasi recusandae repellendus!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. At expedita fuga incidunt maxime nemo perferendis provident quod sint suscipit vel?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorem fugit harum illo magnam nobis nostrum quibusdam quisquam reiciendis vitae!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid architecto consectetur debitis, harum hic itaque labore necessitatibus obcaecati quo veniam!
        </p>
        <h2>Lorem ipsum dolor sit amet.</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et necessitatibus nobis optio soluta vero? Dignissimos doloremque dolores eaque eveniet facilis, iusto perferendis quasi sed temporibus.</p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto debitis dolore ea expedita ipsum odio quibusdam quis quos tempora? Laboriosam.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi atque debitis eaque excepturi facilis laudantium officia quas quasi recusandae repellendus!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. At expedita fuga incidunt maxime nemo perferendis provident quod sint suscipit vel?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorem fugit harum illo magnam nobis nostrum quibusdam quisquam reiciendis vitae!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid architecto consectetur debitis, harum hic itaque labore necessitatibus obcaecati quo veniam!
        </p>
        <h2>Lorem ipsum dolor sit amet.</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et necessitatibus nobis optio soluta vero? Dignissimos doloremque dolores eaque eveniet facilis, iusto perferendis quasi sed temporibus.</p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto debitis dolore ea expedita ipsum odio quibusdam quis quos tempora? Laboriosam.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi atque debitis eaque excepturi facilis laudantium officia quas quasi recusandae repellendus!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. At expedita fuga incidunt maxime nemo perferendis provident quod sint suscipit vel?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorem fugit harum illo magnam nobis nostrum quibusdam quisquam reiciendis vitae!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid architecto consectetur debitis, harum hic itaque labore necessitatibus obcaecati quo veniam!
        </p>
        <h2>Lorem ipsum dolor sit amet.</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et necessitatibus nobis optio soluta vero? Dignissimos doloremque dolores eaque eveniet facilis, iusto perferendis quasi sed temporibus.</p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto debitis dolore ea expedita ipsum odio quibusdam quis quos tempora? Laboriosam.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi atque debitis eaque excepturi facilis laudantium officia quas quasi recusandae repellendus!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. At expedita fuga incidunt maxime nemo perferendis provident quod sint suscipit vel?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorem fugit harum illo magnam nobis nostrum quibusdam quisquam reiciendis vitae!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid architecto consectetur debitis, harum hic itaque labore necessitatibus obcaecati quo veniam!
        </p>
        <h2>Lorem ipsum dolor sit amet.</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et necessitatibus nobis optio soluta vero? Dignissimos doloremque dolores eaque eveniet facilis, iusto perferendis quasi sed temporibus.</p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto debitis dolore ea expedita ipsum odio quibusdam quis quos tempora? Laboriosam.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi atque debitis eaque excepturi facilis laudantium officia quas quasi recusandae repellendus!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. At expedita fuga incidunt maxime nemo perferendis provident quod sint suscipit vel?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorem fugit harum illo magnam nobis nostrum quibusdam quisquam reiciendis vitae!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid architecto consectetur debitis, harum hic itaque labore necessitatibus obcaecati quo veniam!
        </p>
      </section>
    </Page>
  )
};

export default DataAgreement;
