// Libraries
import React from "react";
import {useParams} from "react-router-dom";
import parse from "html-react-parser";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";

// Hooks
import {useAppSelector} from "hooks/hooks";

// Interfaces
import {PassportCollectionElement, PassportElement} from "interfaces/index";

// Components
import Button from "components/Button/Button";
import PageContent from "../PageContent/PageContent";

// Styles
import styles from "./ImplementationPage.scss";

const ImplementationPage = (): JSX.Element => {
  const { id } = useParams();
  const passport: PassportCollectionElement = useAppSelector(state =>
    state.passports.passportsCollection.find((passportElement: PassportElement): boolean =>
      passportElement.id == id));

  return (
    <>
      <PageContent hasCharacteristics element={passport} />

      <div className={styles.ImplementationTabs}>
        <Button
          title="Основная информация"
          classes={{ root: styles.ImplementationPageTabsTab }}
          type="button"
        />
      </div>

      <div className={styles.MainInfo}>
        <div className={styles.MainInfoContent}>
          <div className={styles.MainInfoDescription}>
            { passport && passport.realization?.main_info ? parse(passport.realization.main_info) : <Skeleton/>  }
          </div>
          <div className={styles.MainInfoImgsContent}>
            {
              passport?.realization.photos.map((photo): JSX.Element => (
                <img key={uuidv4()} src={photo.url} alt=""/>
              ))
            }
          </div>
        </div>
        {
          passport?.realization.quote && (
            <div className={styles.MainInfoFaq}>
              <div className={styles.MainInfoFaqContent}>
                { passport?.realization.quote }
              </div>
            </div>
          )
        }
      </div>
    </>
  )
};

export default ImplementationPage;
