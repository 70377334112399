// Libraries
import React from "react";

// Components
import PrivateCabinet from "../../PrivateCabinet";
import MainInfoForm from "./components/MainInfoForm/MainInfoForm";
import AdditionalInfoForm from "./components/AdditionalInfoForm/AdditionalInfoForm";

const PersonalData = (): JSX.Element => (
  <PrivateCabinet>
    <section>
      <MainInfoForm />
      <AdditionalInfoForm />
    </section>
  </PrivateCabinet>
)

export default PersonalData;
