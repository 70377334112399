// Libraries
import React from "react";

// Assets
import { ReactComponent as SuccessIcon } from "assets/success.svg";

// Styles
import styles from "./SuccessRegistration.scss";

interface SuccessRegistrationProps {
  readonly setActiveModalWindow: (flag: boolean) => void;
}

const SuccessRegistration = ({ setActiveModalWindow }: SuccessRegistrationProps): JSX.Element => {

  const closeModalWindow = (): void => {
    setActiveModalWindow(false);
  };

  return (
    <div className={styles.Root}>
      <div className={styles.RootContainer}>
        <h2 className={styles.RootContainerTitle}>Регистрация</h2>
        <SuccessIcon />
        <p className={styles.RootContainerDescription}>
          Вы успешно зарегистрировались!
        </p>
        <i
          className={styles.RootClose}
          onClick={closeModalWindow}
        />
      </div>
    </div>
  )
};

export default SuccessRegistration;
