// Libraries
import React, {useEffect, useRef, useState} from "react";
import {v4 as uuidv4} from "uuid";
import Skeleton from "react-loading-skeleton";
import Cookies from "js-cookie";

// Interfaces
import {PassportCollectionElement} from "interfaces/index";

// Components
import ComplexityInstallation from "components/Filtration/components/ComplexityInstallation/ComplexityInstallation";
import Cost from "components/Filtration/components/Cost/Cost";
import ModalWithListProjects from "components/ModalWindows/ModalWithListProjects/ModalWithListProjects";
import TableEqualsModal from "components/ModalWindows/TableEqualsModal/TableEqualsModal";
import ApplicationArea, {ApplicationAreaFilterElement} from "components/Filtration/components/ApplicationArea/ApplicationArea";

// Styles
import styles from "./PageContent.scss";


interface PageContentProps {
  readonly element: PassportCollectionElement;
  readonly hasCharacteristics?: boolean;
  readonly hasTableEquals?: boolean;
}

const PageContent = ({ element, hasCharacteristics = false, hasTableEquals = false }: PageContentProps): JSX.Element => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [isOpenProjects, setOpenProjects] = useState(false);
  const refElement = useRef(null);
  const refElementBtn = useRef(null);
  const user = Cookies.getJSON("axp-user");

  useEffect(() => {
    document.addEventListener("click", closeModal, false);

    return () => document.removeEventListener("click", closeModal, false);
  }, []);

  const closeModal = (e: MouseEvent): void => {
    // @ts-ignore
    if ((refElement.current && !refElement.current?.contains(e.target) && e.target !== refElementBtn.current)) {
      setOpenModal(false);
    }
  };

  const toogleOpenProjects = () => setOpenProjects(!isOpenProjects);

  return (
    <div className={styles.DesignPageContent}>
      <div className={styles.DesignPageContentImg}>
        {
          user && <div data-active={isOpenProjects} className={styles.DesignPageContentAdd} onClick={toogleOpenProjects} />
        }
        {
          element
            ? <img className={styles.DesignPageContentImg} src={element.design?.main_image?.length > 0 ? element.design.main_image[0].url : ""} alt=""/>
            : <Skeleton width={376} height={376} />
        }
      </div>
      <div className={styles.DesignPageContentDescription}>
        <h5 className={styles.DesignPageContentDescriptionTitle}>{ element?.title || <Skeleton /> }</h5>
        <p className={styles.DesignPageContentDescriptionSubTitle}>{ element?.design?.description }</p>

        { hasTableEquals && (
          <div className={styles.DesignPageContentTable} ref={refElementBtn} onClick={(): void => setOpenModal(true)}>
            Таблица сравнения особенностей
          </div>
        )}

        <div className={styles.DesignPageContentDescriptionCharacteristicsContainer}>
          { hasCharacteristics && (
            <div>
              <div className={styles.FilterComplexityInstallation}>
                {
                  element ? (
                    <ComplexityInstallation
                      classes={{ root: styles.FilterComplexityInstallationRoot, title: styles.FilterComplexityInstallationTitle }}
                      complexity={element?.complexity}
                    />
                  ) : <Skeleton width={150} height={44} />
                }
              </div>

              <div className={styles.FilterApplicationArea}>
                {
                  element ? (
                    <ApplicationArea classes={
                      {
                        title: styles.FilterApplicationAreaTitle,
                        root: styles.FilterApplicationAreaRoot
                      }
                    }>
                      {
                        element.application_areas.map((area: any): JSX.Element => (
                          <ApplicationAreaFilterElement title={area.title} key={uuidv4()} category={area.slug} isActive />
                        ))
                      }
                    </ApplicationArea>
                  ) : <Skeleton width={150} height={44} />
                }
              </div>

              <div className={styles.FilterCost}>
                {
                  element ? (
                    <Cost
                      classes={{ root: styles.FilterCostRoot, title: styles.FilterCostTitle }}
                      cost={element?.price}
                      isActiveFilter={false}
                    />
                  ) : <Skeleton width={150} height={44} />
                }
              </div>
            </div>
          )}

          {
            element?.exploitation_years_start_at && element?.exploitation_years_end_at ? (
              <div className={styles.LifeTime}>
                <span className={styles.LifeTimeTitle}>Срок эксплуатации</span>
                {
                  element.exploitation_years_desc.map((item): JSX.Element => (
                    <p key={uuidv4()} className={styles.LifeTimeContent}>{ item }</p>
                  ))
                }
              </div>
            ) : null
          }
        </div>
      </div>
      { isOpenModal && <TableEqualsModal ref={refElement} setOpenModal={setOpenModal} /> }
      {
        user && <ModalWithListProjects data-opened={isOpenProjects} passportId={element?.id} classes={{ root: styles.ModalWithListProjectsRoot, list: styles.ModalWithListProjectsList }} />
      }
    </div>
  )
}

export default PageContent;
